import { createReducer, on } from '@ngrx/store';
import * as RelatedPartiesActions from '../actions/funeral-quote.action';
import { RelatedParty, Benefit } from '../actions/funeral-quote.action';
import { v4 as uuidv4 } from 'uuid';

export interface RelatedPartiesState {
    relatedParties: RelatedParty[];
}

export const initialState: RelatedPartiesState = {
    relatedParties: [],
};

export const relatedPartiesReducer = createReducer(
    initialState,
    on(RelatedPartiesActions.addRelatedParty, (state, { relatedParty }) => {
        const relatedParties = [...state.relatedParties, relatedParty];

        var first = relatedParties.find(x => x.relation == "Main member");
        relatedParties?.sort(function(x,y){ return x == first ? -1 : y == first ? 1 : 0; });

        return { ...state, relatedParties };
    }),

    on(RelatedPartiesActions.updateRelatedPartyBenefits, (state, { id, benefits }) => {
        const relatedParties = state.relatedParties.map(relatedParty => {
            if (relatedParty.id === id) {
                return { ...relatedParty, benefits: [...benefits].reverse() };
            } else {
                return relatedParty;
            }
        });

        return { ...state, relatedParties };
    }),

    on(RelatedPartiesActions.deleteRelatedParty, (state, { id }) => {
        const relatedParties = state.relatedParties.filter(
            relatedParty => relatedParty.id !== id && relatedParty.idNumber !== id &&  relatedParty.dependantId !==  parseInt(id) 
        );

        return { ...state, relatedParties };
    }),

    on(RelatedPartiesActions.deleteAllRelatedParties, state => {
        return { ...state, relatedParties: [] };
    })
);
