import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-field-error-checkbox-display',
  templateUrl: './field-error-checkbox-display.component.html',
  styleUrls: ['./field-error-checkbox-display.component.scss']
})
export class FieldErrorCheckboxDisplayComponent {

  @Input() errorMsg: string;
  @Input() displayCheckboxError: boolean;
}
