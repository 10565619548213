import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { isValidBenefitValue } from 'src/app/core/_helpers/quote.utility';
import { AppService } from 'src/app/core/services/app.service';

@Component({
    selector: 'app-more-quote-option',
    templateUrl: './more-quote-option.component.html',
    styleUrls: ['./more-quote-option.component.scss'],
})
export class MoreQuoteOptionComponent implements OnInit {
    public quotes: any;
    public insurerLogo: any;
    public mainMember: any;
    public children: any;
    public spouses: any;
    public parents: any;
    public others: any;
    public solutionType: string = 'Prime';

    mobileView = false;
    mobileViewSub: Subscription;

    public constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private store: Store,
        private readonly _dialog: MatDialog,
        private readonly _appService: AppService
    ) {
        this.mobileViewSub = this._appService.mobileView$.subscribe(result => {
            this.mobileView = result;
        });
    }

    _isValidBenefitValue = isValidBenefitValue;

    onDoneClick(): void {
        this._dialog.closeAll();
    }

    public getInsurerLogo(insurer: string): string {
        const defaultLogo = '../../../assets/logos/default-insurer-logo.png';
        const insurerIndex = this.insurerLogo.findIndex((x: any) => x.insurer === insurer);
        if (insurerIndex !== -1) {
            return this.insurerLogo[insurerIndex]?.url;
        }

        return defaultLogo;
    }

    public notRequested(item: any): number {
        return item.filter((x: { mandatory: boolean }) => x.mandatory === false).length;
    }

    public requested(item: any): number {
        return item.filter((x: { mandatory: boolean }) => x.mandatory === true).length;
    }

    public notRequestedMobile(item: any): [] {
        return item.filter((x: { mandatory: boolean }) => x.mandatory === false);
    }

    public requestedMobile(item: any): any {
        return item.filter((x: { mandatory: boolean }) => x.mandatory === true);
    }

    public isCoverAmountChildren(item: any): boolean {
        return item.some((x: { magisId: string; covered: boolean }) => x.magisId === 'F000000001' && x.covered === false);
    }

    

    public ngOnInit(): void {
        this.quotes = this.data.quotes;
        this.solutionType = localStorage.getItem('MAGIS-FUNERAL-SOLUTION-TYPE');
        const insurerLogo = JSON.parse(localStorage.getItem('insurerLogo'));

        this.insurerLogo = insurerLogo?.map((insurer: any) => {
            return {
                ...insurer,
                //insurer: insurer.insurer.charAt(0).toUpperCase() + insurer.insurer.slice(1).toLowerCase(),
            };
        });
        this.store
            .select((state: any) => state?.funeralRelatedParties)
            .subscribe(data => {
                const benefits = data.relatedParties;

                if (benefits?.length !== 0) {
                    this.mainMember = benefits?.find((x: { partyType: string }) => x.partyType == 'MainMember');
                    this.children = benefits.filter((x: { partyType: string }) => x.partyType == 'Child');
                    this.spouses = benefits.filter((x: { partyType: string }) => x.partyType == 'Spouse');
                    this.parents = benefits.filter((x: { partyType: string }) => x.partyType == 'Parent');
                    this.others = benefits?.filter((x: { partyType: string }) => x.partyType == 'ExtendedFamily');
                }
            });
    }
}
