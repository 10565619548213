import { IDropdown } from '../models/drop-down';

export class ConvertEnum {
    public static convertEnumArray(enumObj: any): Array<IDropdown> {
        const arrayObjects = [];
        for (const [propertyKey, propertyValue] of Object.entries(enumObj)) {
            if (!Number.isNaN(Number(propertyKey))) {
                continue;
            }
            const model: IDropdown = {
                viewValue: propertyKey,
                value: propertyValue,
            };
            arrayObjects.push(model);
        }

        return arrayObjects;
    }
}
