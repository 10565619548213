<div >
    <h3>{{ data.title }}</h3>
    <div mat-dialog-content>
        <p class="dialog-message">{{ data.message }}</p>
    </div>

    <div class="center">
      <button  (click)="dialogRef.close(true)" mat-raised-button class="btn btn-primary btn-round">
        {{ data.confirmText }}
    </button>
    </div>

</div>
