export enum UserActivityTypeEnum
{
    FUNERAL_CLAIM_FINALISED 	= 'FUNERAL_CLAIM_FINALISED',
    FUNERAL_CLAIM_INITIATED 	= 'FUNERAL_CLAIM_INITIATED',
    FUNERAL_CLAIM_UPDATED 	= 'FUNERAL_CLAIM_UPDATED',
    FUNERAL_POLICY_CREATED 	= 'FUNERAL_POLICY_CREATED',
    FUNERAL_QUOTE_REQUEST 	= 'FUNERAL_QUOTE_REQUEST',
    KYC_OTHER_DOC_UPLOAD 	= 'KYC_OTHER_DOC_UPLOAD',
    MOTOR_CLAIM_FINALISED 	= 'MOTOR_CLAIM_FINALISED',
    MOTOR_CLAIM_INITIATED 	= 'MOTOR_CLAIM_INITIATED',
    MOTOR_CLAIM_UPDATED 	= 'MOTOR_CLAIM_UPDATED',
    MOTOR_POLICY_CREATED 	= 'MOTOR_POLICY_CREATED',
    MOTOR_QUOTE_REQUEST 	= 'MOTOR_QUOTE_REQUEST',
    PROFILE_DETAILS_UPDATE 	= 'PROFILE_DETAILS_UPDATE',
    SIGN_IN 	= 'SIGN_IN',
    SIGN_OUT	= 'SIGN_OUT',
    SIGN_UP 	= 'SIGN_UP',
    SUPPORT_REQUEST_COMPLETED 	= 'SUPPORT_REQUEST_COMPLETED',
    SUPPORT_REQUEST_FOLLOWUP 	= 'SUPPORT_REQUEST_FOLLOWUP',
    SUPPORT_REQUEST_LOGGED 	= 'SUPPORT_REQUEST_LOGGED',
    BENEFICIARY_DETAIL_UPDATE = 'BENEFICIARY_DETAIL_UPDATE',
    FICA = 'FICA',
    FUNERAL_CLAIM = 'FUNERAL_CLAIM',
    FUNERAL_QUOTE = 'FUNERAL_QUOTE',
    FUNERAL_QUOTE_UPDATE = 'FUNERAL_QUOTE_UPDATE',
    KYC = 'KYC',
    MOTOR_CLAIM = 'MOTOR_CLAIM',
    MOTOR_QUOTE = 'MOTOR_QUOTE',
    MOTOR_QUOTE_UPDATE = 'MOTOR_QUOTE_UPDATE',
    VEHICLE_ASSESSMENT = 'VEHICLE_ASSESSMENT',
}