// format-main-member.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatMainMember' })
export class FormatMainMemberPipe implements PipeTransform {
  transform(value: string): string {
    
    // Check if the string is exactly "MainMember" and insert a space
    return value === 'MainMember' ? 'Main Member' : value;
  }
}
