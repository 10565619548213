import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-single-action-message',
  templateUrl: './single-action-message.component.html',
  styleUrls: ['./single-action-message.component.scss']
})
export class SingleActionMessageComponent implements OnInit {
  public constructor(
      @Inject(MAT_DIALOG_DATA)
      public data: {
          confirmText: string;
          message: string;
          title: string;
      },
      public dialogRef: MatDialogRef<SingleActionMessageComponent>
  ) {}

  public ngOnInit(): void {}
}
