<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-modal">
                    <div class="card-header card-header-primary card-header-icon">
                        <h4 class="card-title">{{ controlState }} Related Party</h4>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="form" *ngIf="form" (ngSubmit)="doneFormMember()">
                            <div class="row">
                                <div class="col-md-4">
                                    <div>
                                        <mat-form-field>
                                            <mat-select formControlName="relation" placeholder="Relationship" required="true">
                                                <mat-option *ngFor="let relation of relations" [value]="relation.value">
                                                    {{ relation.name }}</mat-option>
                                            </mat-select>
                                            <app-field-error-display
                                                [displayError]="fieldIsNotValid(form, 'relation')"
                                                errorMsg="Relationship is required"
                                            ></app-field-error-display>
                                            <app-field-error-display
                                                [displayError]="isValidGender(form, 'relation')"
                                                errorMsg="The relation type selected does not match the selected gender"
                                            ></app-field-error-display>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <p></p>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div>
                                        <mat-form-field>
                                            <mat-select formControlName="title" placeholder="Title">
                                                <mat-option *ngFor="let aTitle of titles" [value]="aTitle.value">
                                                    {{ aTitle.name }}</mat-option>
                                            </mat-select>
                                            <app-field-error-display
                                                [displayError]="isValidGender(form, 'title')"
                                                errorMsg="The title selected does not match the selected gender"
                                            ></app-field-error-display>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="col-md-4 ">
                                    <div>
                                        <mat-form-field class="example-full-width">
                                            <input
                                                formControlName="firstName"
                                                matInput
                                                type="text"
                                                placeholder="First name"
                                                required="true"
                                            />
                                            <app-field-error-display
                                                [displayError]="fieldIsNotValid(form, 'firstName')"
                                                errorMsg="First name is required"
                                            ></app-field-error-display>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="col-md-4 ">
                                    <div>
                                        <mat-form-field class="example-full-width">
                                            <input
                                                formControlName="lastName"
                                                matInput
                                                type="text"
                                                placeholder="Surname"
                                                required="true"
                                            />
                                            <app-field-error-display
                                                [displayError]="fieldIsNotValid(form, 'lastName')"
                                                errorMsg="Surname is required"
                                            ></app-field-error-display>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <p></p>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div>
                                        <mat-form-field class="example-full-width">
                                            <input
                                                formControlName="nationalIdNumber"
                                                matInput
                                                type="text"
                                                placeholder="SA identity number"
                                                (keyup)="onIdNumberCapture()"
                                                (blur)="onBlur('nationalIdNumber')"
                                                minlength="13"
                                                maxlength="13"
                                            />
                                            <app-field-error-display
                                                [displayError]="isIdNumberTouched && !isValidSAID"
                                                errorMsg="This is an invalid South African ID"
                                            ></app-field-error-display>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-4 ">
                                    <div>
                                        <mat-form-field class="example-full-width">
                                            <input
                                                formControlName="passportNumber"
                                                matInput
                                                type="text"
                                                placeholder="Passport number"
                                            />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-4 ">
                                    <div>
                                        <mat-form-field>
                                            <input
                                                formControlName="dateOfBirth"
                                                matInput
                                                [matDatepicker]="birth"
                                                required="true"
                                                placeholder="Date of birth"
                                                (click)="birth.open()"
                                                [min]="minDate"
                                                [max]="maxDate"
                                            />
                                            <mat-datepicker-toggle matSuffix [for]="birth"></mat-datepicker-toggle>
                                            <mat-datepicker [startAt]="date" #birth></mat-datepicker>

                                            <app-field-error-display
                                                [displayError]="fieldIsNotValid(form, 'dateOfBirth')"
                                                errorMsg="Valid date of birth is required. Date cannot be in the future!"
                                            ></app-field-error-display>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <p></p>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div>
                                        <mat-form-field>
                                            <mat-select formControlName="gender" placeholder="Gender" required="true">
                                                <mat-option *ngFor="let gender of genders" [value]="gender.value">
                                                    {{ gender.name }}</mat-option>
                                            </mat-select>
                                            <app-field-error-display
                                                [displayError]="fieldIsNotValid(form, 'gender')"
                                                errorMsg="Gender is required"
                                            ></app-field-error-display>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="col-md-4 ">
                                    <div>
                                        <mat-form-field class="example-full-width">
                                            <input formControlName="email" matInput type="email" placeholder="Email address" />
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="col-md-4 ">
                                    <div>
                                        <mat-form-field class="example-full-width">
                                            <input
                                                formControlName="phoneNumber"
                                                matInput
                                                placeholder="Mobile number"
                                                required="true"
                                                maxlength="10"
                                                minlength="10"
                                                type="number"
                                                min="0"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                            />

                                            <app-field-error-display
                                                [displayError]="fieldIsNotValid(form, 'phoneNumber')"
                                                errorMsg="SA Phone number is required (10 digits only)"
                                            ></app-field-error-display>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer">
                                <div class="ml-auto">
                                    <button
                                        (click)="dialogRef.close(false)"
                                        mat-raised-button
                                        type="submit"
                                        [disabled]="!form.valid"
                                        class="btn btn-primary btn-round"
                                    >
                                        Done &nbsp;
                                        <mat-icon *ngIf="loading">
                                            <mat-spinner diameter="20"> </mat-spinner>
                                        </mat-icon>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
