import { Injectable } from '@angular/core';
import { GenderEnum } from '../enums/gender.enum';

@Injectable({ providedIn: 'root' })
export class IdNumberValidator {
    constructor(
    ) { }

    public IdNumberRegex = /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/;
    
    public validate(value:any) :{valid:boolean, dateOfBirth: Date, gender:any} {
        //let value = this.form.value.nationalIdNumber;
        let isValid = false, dobVal = null, genderVal = null;

        if (value != undefined) {
            isValid = value.length == 13 && !isNaN(Number(value)) && this.IdNumberRegex.test(value);
            

            if (isValid) {
                //Gender Index = 6; i.e. char @ 7
                var theGender = Number.parseInt(value.charAt(6));
                if (theGender != NaN) {
                    if (theGender >= 0 && theGender < 5) genderVal = GenderEnum.Female;
                    else genderVal = GenderEnum.Male;
                } 

                let yy = Number(value.substring(0, 2));
                let mm = Number(value.substring(2, 4));
                let dd = value.substring(4, 6);

                yy = yy <= new Date().getFullYear() - 2000 ? 2000 + yy : 1900 + yy; //chances of getting a 100 years old person is slim.
                mm = mm - 1; //calendar adjustment. 0 = Jan, 1 = Feb ...

                dobVal = new Date(yy, mm, dd);
            }
        }

        return {valid: isValid, dateOfBirth: dobVal, gender:genderVal};
    }
}