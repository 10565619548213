<div *ngIf="loadingComplete">
    <ul class="cards" #scrollmenu>
        <ng-container *ngIf="quoteResult.length === 0">
            <li class="rounded">
                <div class="card-recommended">
                    <div class="card-body">
                        <h3 style="color: #aa80d7; text-align: center; font-weight: 600">There is no quote available.</h3>
                    </div>
                </div>
            </li>
        </ng-container>

        <ng-container>
            <div
                *ngIf="allPremiumsZero() && coverAmount > 100000"
                style="text-align: center; color: #aa80d7; font-weight: 600; font-size: 18px"
            >
                The coverage selected for the {{ getPartyType() }} exceeds the maximum limit of {{ coverAmount }}. Please revise
                your selection and resubmit.
            </div>
        </ng-container>

        <ng-container>
            <div
                *ngIf="allPremiumsZero() && coverAmount < 100000"
                style="text-align: center; color: #aa80d7; font-weight: 600; font-size: 18px"
            >
                {{ reason }}. Please revise your selection and resubmit.
            </div>
        </ng-container>

        <ng-container *ngFor="let item of quoteResult; let i = index">
            <li *ngIf="item?.finalQuote.premium !== 0" [class]="'card rounded card-' + item?.recommended">
                <div class="card-recommended card-pricing card-plain">
                    <div class="card-body recommented">
                        <div class="ribbon" *ngIf="item?.recommended">
                            <span class="recommended-text">Recommended</span>
                        </div>
                        <div class="card-icon">
                            <img src="{{ getInsurerLogo(item.insuranceProvider) }}" alt="" />
                        </div>

                        <strong style="font-weight: bold; font-weight: 200; font-size: 18px"> {{ item?.planName }}</strong>

                        <div
                            class="benefit-margin-bottom"
                            style="color: #aa80d7; text-align: center; font-weight: 200; font-size: 22px"
                        >
                            <!-- <span>{{ item.insuranceProvider }}</span> -->
                        </div>

                        <h1 class="benefit-margin-bottom" *ngIf="item?.finalQuote.premium !== 0">
                            {{ item?.finalQuote.premium | zarCurrency }} per month
                        </h1>

                        <h1 class="benefit-margin-bottom" *ngIf="item?.finalQuote.premium == 0">
                            {{ item?.finalQuote.premium | zarCurrency }}
                        </h1>

                        <div style="color: #aa80d7; text-align: left; font-weight: 600; margin-bottom: 2px" class="col-header">
                            Benefits
                        </div>

                        <ng-container *ngFor="let y of item.finalQuote.benefits; let x = index">
                            <ng-container
                                *ngIf="
                                    solutionType === 'Prime' && y.name !== 'Memorial benefit' && y.name !== 'Grocery benefit';
                                    else customBenefit
                                "
                            >
                            <ng-container *ngIf="y.covered || y.mandatory">
                                <div class="row benefit-margin-bottom" *ngIf="x < 5">
                                    <div style="text-align: left" class="col-sm-7 col-6 d-flex col-header">{{ y.name }}</div>
                                    <div class="col-sm-5 col-6 d-flex col-para">
                                        <div *ngIf="y.covered">
                                            <ng-container
                                                *ngIf="
                                                    y.magisId === 'F000000001' ||
                                                    y.magisId === 'F000000006' ||
                                                    y.magisId === 'F000000012';
                                                    else notCovered
                                                "
                                            >
                                                <ng-container *ngIf="y.value !== '0'; else notCovered">
                                                    {{ y.value | zarCurrency }}
                                                </ng-container>

                                                <ng-template #notCovered>
                                                    <mat-icon style="color: red" *ngIf="!y.covered">close</mat-icon>
                                                    <mat-icon style="margin-top: -7px" class="custom-icon" *ngIf="y.covered"
                                                        >done</mat-icon
                                                    >
                                                </ng-template>
                                            </ng-container>
                                            <ng-template #notCovered>
                                                <mat-icon style="color: red" *ngIf="!y.covered">close</mat-icon>
                                                <mat-icon style="margin-top: -7px" class="custom-icon" *ngIf="y.covered"
                                                    >done</mat-icon
                                                >
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                             
                            </ng-container>
                            <ng-template #customBenefit>
                                <ng-container *ngIf="y.covered || y.mandatory">
                                    <div class="row benefit-margin-bottom" *ngIf="solutionType === 'Custom' && x < 5">
                                        <div style="text-align: left" class="col-sm-7 col-6 d-flex col-header">{{ y.name }}</div>
                                        <div class="col-sm-5 col-6 d-flex col-para">
                                            <div >
                                                <ng-container
                                                    *ngIf="
                                                        y.magisId === 'F000000001' ||
                                                        y.magisId === 'F000000006' ||
                                                        y.magisId === 'F000000012';
                                                        else notCovered
                                                    "
                                                >
                                                    <ng-container *ngIf="y.value !== '0'; else notCovered">
                                                        {{ y.value | zarCurrency }}
                                                    </ng-container>
    
                                                    <ng-template #notCovered>
                                                        <mat-icon style="color: red" *ngIf="!y.covered">close</mat-icon>
                                                        <mat-icon style="margin-top: -7px" class="custom-icon" *ngIf="y.covered"
                                                            >done</mat-icon
                                                        >
                                                    </ng-template>
                                                </ng-container>
                                                <ng-template #notCovered>
                                                    <mat-icon style="color: red" *ngIf="!y.covered">close</mat-icon>
                                                    <mat-icon style="margin-top: -7px" class="custom-icon" *ngIf="y.covered"
                                                        >done</mat-icon
                                                    >
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>

                                </ng-container>
                            
                            </ng-template>
                        </ng-container>

                        <div style="margin-top: 18px; text-align: initial" class="row">
                            <div style="font-weight: bold">Condition of cover</div>
                            <div style="font-weight: 200">{{ item?.finalQuote.termsAndConditions }}</div>
                        </div>

                        <div class="row" style="text-align: initial">
                            <div class="col-sm-6">
                                <div style="font-weight: bold">Waiting period</div>
                                <div style="font-weight: 200">
                                    <!-- {{ item?.finalQuote.waitingPeriod }} -->
                                    {{ item?.finalQuote.waitingPeriod }}
                                </div>
                            </div>

                            <div class="col-sm-6"></div>
                        </div>
                    </div>

                    <!-- <div class="row">
                        <div class="col-sm-6"></div>

                        <div class="col-sm-6">
                         
                        </div>
                    </div> -->

                    <div class="inline-fix">
                        <div class="read-more">
                            <input
                                type="button"
                                class="btn btn-fill btn-round btn-more"
                                name="finish"
                                (click)="more(item)"
                                value="More details >"
                            />
                        </div>
                    </div>

                    <div class="inline-fix">
                        <div class="card-footer justify-content-center">
                            <mat-radio-button
                                [disabled]="item?.finalQuote.premium === 0"
                                name="parent-{{ i }}"
                                id="parent-radio-{{ i }}"
                                (click)="item?.finalQuote.premium !== 0 && question($event, item)"
                                [checked]="item?.insuranceProvider + item?.planName === selectedQuote"
                            >
                                <span
                                    [ngClass]="{
                                        'gray-label': item?.finalQuote.premium === 0,
                                        'radio-label': item?.finalQuote.premium !== 0
                                    }"
                                    >Select option</span
                                >
                            </mat-radio-button>
                        </div>
                    </div>
                </div>
            </li>
        </ng-container>
    </ul>
    <div class="row justify-content-center" *ngIf="quoteResult.length !== 0 && !allPremiumsZero()">
        <div class="col-md-6">
            <div style="text-align: center" class="justify-content-between">
                <button (click)="scrollLeft()" class="btn btn-primary mx-2" [disabled]="scrollLeftDisabled">
                    <span class="material-symbols-outlined"> navigate_before </span>
                </button>
                <button (click)="scrollRight()" class="btn btn-primary mx-2" [disabled]="scrollRightDisabled">
                    <span class="material-symbols-outlined"> navigate_next </span>
                </button>
            </div>
        </div>
    </div>
</div>
