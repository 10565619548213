import { createReducer, on } from '@ngrx/store';
import { setTicketSupport } from '../actions/ticket-support.action';


export interface TicketState {
    ticketSupport: {};
}

export const initialState: TicketState = {
    ticketSupport: {},
};

export const ticketReducer = createReducer(
    initialState,
    on(setTicketSupport, (state, { ticketSupport }) => ({
        ...state,
        ticketSupport,
    }))
);
