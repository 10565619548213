import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SiteLayoutComponent } from './site-layout/site-layout.component';
import { RouterModule } from '@angular/router';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { TimeoutPopupComponent } from './components/timeout-popup/timeout-popup.component';


@NgModule({
  declarations: [HeaderComponent, FooterComponent, SiteLayoutComponent, ImageUploadComponent, TimeoutPopupComponent, ],
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [HeaderComponent, FooterComponent]
})
export class LayoutsModule { }
