import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { IUserDetails } from "../core/models/user-Details";
import { addBankReducer } from "../reducers/bank.reduce";
import { addDriverReducer } from "../reducers/driver.reduce";
import { addFuneralQuoteteReducer } from "../reducers/funeral-quote.reducer";
import { addMotoQuoteReducer } from "../reducers/moto-quote.reduce";
import { addVehicleReducer } from "../reducers/vehicle.reduce";

import { hydrationMetaReducer } from "./hydration/hydration.reducer";
import { relatedPartiesReducer, RelatedPartiesState } from "./reducers/funeral-quote.reducer";
import { MotorState, rootReducer } from "./reducers/motor-vehicle.reducer";

import { ticketReducer,  TicketState } from "./reducers/ticket-support.reducer";
import { userReducer, UserState } from "./reducers/user-detail.reducer";

export interface RootState {
    user: UserState;
    vehicle: null;
    ticketSupport: TicketState;
    funeralRelatedParties: RelatedPartiesState;
    quoteMotor: null;
    driver: null;
    bank: null;
    appState: MotorState; 
    quoteFuneral: null;
}

export const reducers: ActionReducerMap<RootState> = {
    user: userReducer,
    vehicle: addVehicleReducer,
    ticketSupport: ticketReducer,
    funeralRelatedParties: relatedPartiesReducer,
    quoteMotor: addMotoQuoteReducer,
    driver: addDriverReducer,
    bank: addBankReducer,
    appState: rootReducer,  //// need to change this name
    quoteFuneral: addFuneralQuoteteReducer, //// need to change this name
}

export const metaReducers: MetaReducer[] = [
  hydrationMetaReducer
]


           