import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AmbitionCoveredComponent } from '../ambition-covered/ambition-covered.component';
import { DialogModalComponent } from 'src/app/shared/dialog-modal/dialog-modal.component';
import { getCapturePopupWidthM } from 'src/app/core/_helpers/responsive-helper';
import { DialogEvents } from 'src/app/core/enums/dialog-events.enum';
import { Router } from '@angular/router';
import { AmbitionService } from 'src/app/core/services/ambition.service';
import { HttpStatusCode } from '@angular/common/http';
import { IAmbition } from 'src/app/core/models/ambition';
import { AmbitionTypeEnum } from 'src/app/core/enums/ambition-type.enum';

@Component({
    selector: 'app-ambition',
    templateUrl: './ambition.component.html',
    styleUrls: ['./ambition.component.scss'],
})
export class AmbitionComponent implements OnInit {
    public column1Selection: string;
    selectedOption: Array<any> = new Array<any>();
    priority: string;
    AmbitionTypeEnum = AmbitionTypeEnum;
    public column2Selection: string;
    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _router: Router,
        private readonly _ambitionService: AmbitionService
    ) { }

    public ngOnInit(): void { }

    radioButtonClicked(event: any) {

        if (event.checked) {
            this.selectedOption.push(event.source.value as string);

            if (this.selectedOption.indexOf(event.source.value) == 0) {
                this.priority = event.source.value;
            }

        }
        else {
            const index = this.selectedOption.indexOf(event.source.value);
            const x = this.selectedOption.splice(index, 1);

            if (index == 0) {
                this.priority = this.selectedOption[0];
            }
        }
    }

    public async goToNextStep() {

        let ambitions: Array<IAmbition> = new Array<IAmbition>();

        await Promise.all(this.selectedOption.map(async (option, index) => {
            const model: IAmbition = {
                name: option as AmbitionTypeEnum,
                description: 'Default ambition',
                type: option as AmbitionTypeEnum,
                priority: index + 1
            };

            ambitions.push(model);
        }));



        const response = await this._ambitionService.addUserAmbitions(ambitions);
        if (response.respCode === HttpStatusCode.Accepted || response.respCode === HttpStatusCode.Ok) {
        }
        this._dialog.closeAll();

        if (this.selectedOption[0] == AmbitionTypeEnum.BuryWithDignity) {
            this._router.navigate(['/ambition/ambition-cover']);
        }

        if (this.selectedOption[0] == AmbitionTypeEnum.ReplaceBelongings) {
            this._router.navigate(['/advice/motor-advice']);
        }

    }
}
