export enum ParkingEnum {
    'ACCESS CONTROLLED AREA' = 'ACCESS_CONTROLLED_AREA',
    'BASEMENT ELECTRONIC ACCESS' = 'BASEMENT_ELECTRONIC_ACCESS',
    'BASEMENT NO ELECTRONIC ACCESS' = 'BASEMENT_NO_ELECTRONIC_ACCESS',
    'LOCKED GARAGE' = 'LOCKED_GARAGE',
    'OPEN PARKING LOT' = 'OPEN_PARKING_LOT',
    'YARD NO LOCKED GATES' = 'YARD_NO_LOCKED_GATES',
    'YARD WITH LOCKED GATES' = 'YARD_WITH_LOCKED_GATES',
    'PAVEMENT OR IN STREET' = 'PAVEMENT_OR_IN_STREET',
    'ROAMING' = 'ROAMING',
}
