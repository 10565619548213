import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router, ROUTES, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { INotificationRequest } from 'src/app/core/models/notificationRequest';
import { INotification } from 'src/app/core/models/notificationResponse';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/core/services/user.service';

const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};
declare var $: any;
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    notificationCount: any = 0;
    notifications: Array<INotification> = new Array<INotification>();
    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private nativeElement: Node;
    private toggleButton: any;
    private sidebarVisible: boolean;
    user_fullname: any = '';
    private _router: Subscription;

    pageTitle: string;

    constructor(
        private _userService: UserService,
        private route: Router,
        private router: ActivatedRoute,
        private renderer: Renderer2,
        private element: ElementRef,
        private _notificationService: NotificationService,
        private store: Store
    ) {
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }
    public logout() {
        localStorage.clear();
        this.route.navigate(['/sign-in']);
    }

    ngOnInit(): void {
        this.GetNotification();
        setTimeout(async () => {
            this.store
                .select((state: any) => state?.user)
                .subscribe(data => {
                    const userDetail = data?.userDetails;
                    this.user_fullname = userDetail?.profileDetails?.firstName; // + " " + res.profileDetails.lastName;
                });
            // this._userService.GetUserProfile().then((res) => {

            // })
        });

        if (JSON.stringify(this.router.snapshot.data) !== '{}') {
            this.pageTitle = this.router.snapshot.data?.breadcrumb?.alias;
        }

        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        if (body.classList.contains('hide-sidebar')) {
            misc.hide_sidebar_active = true;
        }
        this._router = this.route.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
            this.sidebarClose();
        });
    }

    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];

        const username = body.getElementsByClassName('magis-user-info')[0];

        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;
            username.classList.remove('hidden');
        } else {
            setTimeout(function () {
                body.classList.add('sidebar-mini');
                misc.sidebar_mini_active = true;
                username.classList.add('hidden');
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    hideSidebar() {
        const body = document.getElementsByTagName('body')[0];
        const sidebar = document.getElementsByClassName('sidebar')[0];

        if (misc.hide_sidebar_active === true) {
            setTimeout(function () {
                body.classList.remove('hide-sidebar');
                misc.hide_sidebar_active = false;
            }, 300);
            setTimeout(function () {
                sidebar.classList.remove('animation');
            }, 500);
            sidebar.classList.add('animation');
        } else {
            setTimeout(function () {
                body.classList.add('hide-sidebar');
                // $('.sidebar').addClass('animation');
                misc.hide_sidebar_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    sidebarOpen() {
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');
        setTimeout(function () {
            $toggle.classList.add('toggled');
        }, 430);

        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');

        if (body.querySelectorAll('.main-panel')) {
            document.getElementsByClassName('main-panel')[0].appendChild($layer);
        } else if (body.classList.contains('off-canvas-sidebar')) {
            document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
        }

        setTimeout(function () {
            $layer.classList.add('visible');
        }, 100);

        $layer.onclick = () => {
            body.classList.remove('nav-open');
            this.mobile_menu_visible = 0;
            this.sidebarVisible = false;

            $layer.classList.remove('visible');
            setTimeout(function () {
                $layer.remove();
                $toggle.classList.remove('toggled');
            }, 400);
        };

        body.classList.add('nav-open');
        this.mobile_menu_visible = 1;
        this.sidebarVisible = true;
    }
    sidebarClose() {
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');

        this.sidebarVisible = false;
        body.classList.remove('nav-open');
        // $('html').removeClass('nav-open');
        body.classList.remove('nav-open');
        if ($layer) {
            $layer.remove();
        }

        setTimeout(function () {
            $toggle.classList.remove('toggled');
        }, 400);

        this.mobile_menu_visible = 0;
    }

    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    GetNotification() {
        const model: INotificationRequest = {
            noOfRecords: 5,
            pageIndex: 1,
            fromDate: new Date(),
            toDate: new Date(),
            notificationType: 'KYC',
        };

        this._notificationService.getAllUserNotifications(model).then(results => {
            if (results.respCode != HttpStatusCode.NoContent) {
                this.notificationCount = results.notifications.filter(x => x.readStatus != 'READ').length;
                this.notifications = results.notifications;
            }
        });
    }

    UpdateNotification(notification: INotification) {
        notification.readStatus = 'READ';

        this._notificationService.updateUserNotification(notification).then(response => {
            this.GetNotification();
        });
    }

    DeleteNotification(notification: INotification) {
        notification.status = 'DELETED';
        this._notificationService.updateUserNotification(notification).then(response => {
            this.GetNotification();
        });
    }
}
