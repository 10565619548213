<div class="card-icon form-icon">
    <mat-icon><i class="material-icons">image</i></mat-icon>
</div>
<!-- <button mat-icon-button aria-label="close dialog" class="close-icon mat-icon-no-color" mat-dialog-close>
    <mat-icon>close</mat-icon>
</button> -->

<button mat-icon-button aria-label="close dialog"
    class="close-icon"
    mat-dialog-close>
    <mat-icon>close</mat-icon>
</button>

<h4 class="header-dialog-margin-M">Profile Picture</h4>

<form>
    <div class="card-body">
        <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
                <div class="fileinput fileinput-new text-center" data-provides="fileinput">
                    <div class="fileinput-new thumbnail img-square">
                        <img src="../assets/img/placeholder-file.png" alt="...">
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail img-square"></div>
                    <div>
                        <span class="btn btn-round btn-primary btn-file btn-add">
                            <span class="fileinput-new">Add Profile Picture</span>
                            <span class="fileinput-exists">Change</span>
                            <input type="file" name="..." accept="image/*" formControlName="file"
                                (change)="UpdateDocument($event)" required="true"/>
                        </span>
                        <br />
                        <a href="#pablo" class="btn btn-danger btn-round fileinput-exists" data-dismiss="fileinput"><i
                                class="fa fa-times"></i> Remove</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="ml-auto d-flex justify-content-center">
    <button mat-raised-button class="btn btn-primary btn-round" name="next" [disabled]="documentFile === undefined" (click)="DocumentSubmit()">Add Profile Picture
        <mat-icon *ngIf="loading">
            <mat-spinner diameter="20">
            </mat-spinner>
        </mat-icon>
    </button>
</div>