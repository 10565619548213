export function getConfirmPopupWidth(): any {
    if (window.innerWidth <= 1024) return '620px';
    else return '624px';
}

export function getConfirmPopupWidthXL(): any {
    if (window.innerWidth <= 1024) return '620px';
    else return '624px';
}

export function getConfirmPopupWidthM(): any {
    if (window.innerWidth <= 1024) return '100%';
    else return '40%';
}

export function getCapturePopupWidth(): any {
    if (window.innerWidth <= 1024) return '100%';
    else return '50%';
}

export function getCapturePopupWidthL(): any {
    if (window.innerWidth <= 1024) return '100%';
    else return '70%';
}

export function getCapturePopupWidthSM(): any {
    if (window.innerWidth <= 1024) return '100%';
    else return '35%';
}

export function getCapturePopupWidthM(): any {
    if (window.innerWidth <= 1024) return '100%';
    else return '55%';
}
