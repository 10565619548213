<!-- app.component.html -->
<div class="container mt-4">
    <div class="card-title">
        <h4>I would like to</h4>
    </div>
    <div class="row">
        <!-- First Column -->
        <div class="col-md-6">
            <mat-radio-group>
                <div>
                    <mat-checkbox
                        value="{{AmbitionTypeEnum.BuryWithDignity}}"
                        (change)="radioButtonClicked($event)" >
                        <span class="radio-label">{{AmbitionTypeEnum.BuryWithDignity}}
                            <span class="badge bg-danger" *ngIf="selectedOption.indexOf(AmbitionTypeEnum.BuryWithDignity) > -1"><b>priority #{{selectedOption.indexOf(AmbitionTypeEnum.BuryWithDignity)+1}}</b></span>
                        </span>
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox disabled  value="{{AmbitionTypeEnum.CreateWealthForSelf}}" (change)="radioButtonClicked($event)">
                        <span class="radio-label">{{AmbitionTypeEnum.CreateWealthForSelf}} - 
                            <span class="magis-coming-soon"><b>Coming soon!</b></span>
                        </span>
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox  disabled value="{{AmbitionTypeEnum.SaveForSomethingSpecial}}" (change)="radioButtonClicked($event)">
                        <span class="radio-label">{{AmbitionTypeEnum.SaveForSomethingSpecial}} - 
                            <span class="magis-coming-soon"><b>Coming soon!</b></span>
                        </span>
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox disabled
                        
                        value="{{AmbitionTypeEnum.MaintainLifestyleForLovedOnes}}"
                        (change)="radioButtonClicked($event)"
                    >
                        <span class="radio-label">{{AmbitionTypeEnum.MaintainLifestyleForLovedOnes}} - 
                            <span class="magis-coming-soon"><b>Coming soon!</b></span>
                        </span>
                    </mat-checkbox>
                </div>
            </mat-radio-group>
        </div>

        <!-- Second Column -->
        <div class="col-md-6">
            <mat-radio-group>
                <div>
                    <mat-checkbox value="{{AmbitionTypeEnum.ReplaceBelongings}}"
                        (change)="radioButtonClicked($event)" >
                        <span class="radio-label">{{AmbitionTypeEnum.ReplaceBelongings}}
                            <span class="badge bg-danger" *ngIf="selectedOption.indexOf(AmbitionTypeEnum.ReplaceBelongings) > -1"><b>priority #{{selectedOption.indexOf(AmbitionTypeEnum.ReplaceBelongings)+1}}</b></span>
                        </span>
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox  disabled value="{{AmbitionTypeEnum.TransferWealthToMyLovedOnes}}" (change)="radioButtonClicked($event)">
                        <span class="radio-label">{{AmbitionTypeEnum.TransferWealthToMyLovedOnes}} - 
                            <span class="magis-coming-soon"><b>Coming soon!</b></span>
                        </span>
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox disabled
                        
                        value="{{AmbitionTypeEnum.BorrowMoney}}"
                        (change)="radioButtonClicked($event)"
                    >
                        <span class="radio-label">{{AmbitionTypeEnum.BorrowMoney}} - 
                            <span class="magis-coming-soon"><b>Coming soon!</b></span>
                        </span>
                    </mat-checkbox>
                </div>
            </mat-radio-group>
        </div>
    </div>

    <div>
        <button mat-raised-button class="btn btn-next btn-primary btn-round" (click)="goToNextStep()" color="primary">
            Next
        </button>
    </div>
</div>
