<div>
    <h4>You are one step closer to unlocking great value!</h4>
    <mat-radio-group (change)="Onchange($event)">
        <div nz-row>
            <div nz-col nzSpan="12">Is this a replacement policy?</div>

            <div nz-col nzSpan="4" nzOffset="2">
                <mat-radio-button (change)="OnSelectedYesNo($event)" value="1">Yes</mat-radio-button>
            </div>
            <div nz-col nzSpan="4" nzOffset="1">
                <mat-radio-button (change)="OnSelectedYesNo($event)" checked value="2">No</mat-radio-button>
            </div>
        </div>
    </mat-radio-group>
    <div style="margin: 0; margin-top: 52px" *ngIf="isPolicy">
        <div style="margin-top: -39px">
            <strong style="color: #8d55ca; font-weight: 600">Hint:</strong> do not cancel any policy until this new policy is in
            effect
        </div>

        <div class="select-group">
            <textarea
                class="text-input"
                matInput
                placeholder="Please provide us with the details of your current policy : insurer, policy number etc."
            ></textarea>
        </div>
        <!-- <mat-select (selectionChange)="OnSelected($event)" placeholder="Select policy to replace" floatlLabel="never">
            <mat-option [value]="">Select policy to replace</mat-option>
            <mat-option *ngFor="let item of policies" [value]="item.policyProviderId"
                >{{ item.policyIssuer }} - {{ item.policyProviderId }}</mat-option
            >
        </mat-select> -->
    </div>
    <mat-radio-group (change)="OnchangeQuote($event)">
        <div nz-row *ngIf="isQuote">
            <div nz-col nzSpan="12">Is at least one of these quotes better than your current cover?</div>

            <div nz-col nzSpan="4" nzOffset="2">
                <mat-radio-button value="1">Yes</mat-radio-button>
            </div>
            <div nz-col nzSpan="4" nzOffset="1">
                <mat-radio-button value="2">No</mat-radio-button>
            </div>
        </div>
    </mat-radio-group>
    <div class="select-group" *ngIf="isQuoteText">
        <textarea class="text-input" matInput placeholder="Tell us more"></textarea>
    </div>
    <!-- <div nz-row  *ngIf="isBudge" disabled>
        <div nz-col nzSpan="12">Are these quotes within your budget?</div>
        <div nz-col nzSpan="4" nzOffset="2">
            <mat-radio-button (change)="OnchangeBudget($event)" id="radio2" name="radioGroup" value="1">Yes</mat-radio-button>
        </div>
        <div nz-col nzSpan="4" nzOffset="1">
            <mat-radio-button (change)="OnchangeBudget($event)" id="radio1" name="radioGroup" value="2">No</mat-radio-button>
        </div>
    </div> -->
    <div class="continue-group">
        <button mat-raised-button (click)="continue()" class="btn btn-primary btn-round">Continue</button>
    </div>
</div>
