import { AlertReducerAction } from '../actions';
import { IAlertState } from '../models';
import { alertInitialState } from '../models';
import { AlertActionType } from '../actions';
import { AddAlertAction } from '../actions';
import { UpsertAlertAction } from '../actions';
import { AddAlertsAction } from '../actions';
import { UpsertAlertsAction } from '../actions';
import { UpdateAlertAction } from '../actions';
import { UpdateAlertsAction } from '../actions';
import { DeleteAlertAction } from '../actions';
import { DeleteAlertsAction } from '../actions';
import { alertAdapter } from '../adapters';
import { IAlert } from '../models';

export const alertReducer = (state: IAlertState = alertInitialState, action: AlertReducerAction): IAlertState => {
    switch (action.type) {
        case AlertActionType.AddAlert: {
            return alertAdapter.addOne(action instanceof AddAlertAction ? action.payload.alert : null, state);
        }
        case AlertActionType.UpsertAlert: {
            return alertAdapter.upsertOne(action instanceof UpsertAlertAction ? action.payload.alert : null, state);
        }
        case AlertActionType.AddAlerts: {
            return alertAdapter.addMany(action instanceof AddAlertsAction ? action.payload.alerts : null, state);
        }
        case AlertActionType.UpsertAlerts: {
            return alertAdapter.upsertMany(action instanceof UpsertAlertsAction ? action.payload.alerts : null, state);
        }
        case AlertActionType.UpdateAlert: {
            return alertAdapter.updateOne(action instanceof UpdateAlertAction ? action.payload.alert : null, state);
        }
        case AlertActionType.UpdateAlerts: {
            return alertAdapter.updateMany(action instanceof UpdateAlertsAction ? action.payload.alerts : null, state);
        }
        case AlertActionType.DeleteAlert: {
            return alertAdapter.removeOne(action instanceof DeleteAlertAction ? action.payload.id : null, state);
        }
        case AlertActionType.DeleteAlerts: {
            return alertAdapter.removeMany(action instanceof DeleteAlertsAction ? action.payload.ids : null, state);
        }
        // case AlertActionType.LoadAlerts: {
        //     return alertAdapter.addAll(action instanceof LoadAlertsAction ? action.payload.alerts : null, state);
        // }
        case AlertActionType.ClearAlerts: {
            return alertAdapter.removeAll(state);
        }
        default: {
            return state;
        }
    }
};
