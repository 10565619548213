import { Action } from '@ngrx/store';
import { BankActionType } from '../core/models/bank-detail.action.type';
import { DriveActionType } from '../core/models/driver.action.type';

export function addBankReducer(state: any, action: any) {
    switch (action.type) {
        case BankActionType.ADD_BANK:
            return action.payload;
        default:
            return state;
    }
}
