<div class="card col-sm-12 card-modal">
    <div class="card-header card-header-rose card-header-icon">
        <!-- <div class="card-icon">
            <i class="material-icons">payments</i>
        </div> -->
        <h4 class="card-title">Selected Account</h4>
    </div>

    <form [formGroup]="form" style="margin-bottom: -20px">
        <div class="card-body">
            <div class="row" style="margin-left: 25px">
                <div class="col-md-4">
                    <mat-form-field class="example-full-width">
                        <input
                            tabindex="1"
                            [value]="banksInfo?.accountHolderName"
                            matInput
                            formControlName="accountHolderName"
                            placeholder="Account holder's name"
                            type="text"
                        />
                        <app-field-error-display
                            [displayError]="isFieldValid(form, 'accountHolderName')"
                            errorMsg="Account name is required."
                        >
                        </app-field-error-display>
                    </mat-form-field>

                    <br />

                    <mat-form-field class="example-full-width">
                        <input
                            tabindex="2"
                            [value]="banksInfo?.accountNumber"
                            matInput
                            formControlName="accountNumber"
                            placeholder="Account number"
                            type="number"
                            min="0"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        />
                        <app-field-error-display
                            [displayError]="isFieldValid(form, 'accountNumber')"
                            errorMsg="Account number is required."
                        >
                        </app-field-error-display>
                    </mat-form-field>
              
                </div>

                <div class="col-md-4">


                    <mat-form-field>
                        <mat-select
                            (selectionChange)="onchangeBankName($event)"
                            tabindex="3"
                            [value]="banksInfo?.bankName"
                            class="example-full-width"
                            formControlName="bankName"
                            placeholder="Bank name"
                            readonly
                        >
                            <mat-option *ngFor="let bank of banks" [value]="bank.name">
                                {{ bank.name }}
                            </mat-option>
                        </mat-select>
                        <app-field-error-display
                            [displayError]="isFieldValid(form, 'bankName')"
                            errorMsg="Bank name is required."
                        >
                        </app-field-error-display>
                    </mat-form-field>
                    <br />
                    <mat-form-field class="example-full-width">
                        <input
                            tabindex="4"
                            [value]="banksInfo?.bankName"
                            matInput
                            formControlName="branchName"
                            placeholder="Branch name"
                            type="text"
                        />
                        <app-field-error-display
                            [displayError]="isFieldValid(form, 'branchName')"
                            errorMsg="Branch name is required."
                        >
                        </app-field-error-display>
                    </mat-form-field>
                  

              
                 
                </div>

                <div class="col-md-4">
                    <mat-form-field class="example-full-width">
                        <input
                            readonly
                            tabindex="5"
                            [value]="banksInfo?.branchCode"
                            matInput
                            disabled="true"
                            formControlName="branchCode"
                            placeholder="Branch code"
                            type="text"
                        />
                        <app-field-error-display
                            [displayError]="isFieldValid(form, 'branchCode')"
                            errorMsg="Branch code is required."
                        >
                        </app-field-error-display>
                    </mat-form-field>
                    <br />
                </div>
            </div>
        </div>

        <div class="card-footer btn-group" style="margin-right: -20px">
            <button mat-raised-button class="btn add-new" type="submit" [disabled]="!canSave" (click)="saveBankDetails()">
                Save &nbsp;
                <mat-icon *ngIf="loading">
                    <mat-spinner diameter="20"> </mat-spinner>
                </mat-icon>
            </button>
        </div>
    </form>
</div>
