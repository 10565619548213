import { Component, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { HttpStatusCode } from 'src/app/core/models/http-status-code.model';
import { IResponseStatus } from 'src/app/core/models/response-status';
import { DriverService } from 'src/app/core/services/driver.service';
import { VehicleService } from 'src/app/core/services/vehicle.service';
import { Notifications } from 'src/app/shared/notifications.component';

declare const $: any;
@Component({
    selector: 'app-add-vehicle',
    templateUrl: './add-vehicle.component.html',
    styleUrls: ['./add-vehicle.component.scss'],
})
export class AddVehicleComponent implements OnInit {
    formsValidity: boolean[] = [false, false, false];
    currentStep: number = 0;
    public formValid: boolean = false;
    public form: FormGroup;
    public isStepThree: boolean = false;
    public loading: boolean = false;
    public isUpdate: boolean = false;
    public isCancelClicked: boolean = false;
    public data: any;
    public stepTwoForm: any;
    public stepThreeForm: any;
    response: IResponseStatus;

    constructor(
        public _dialog: MatDialog,
        private formBuilder: FormBuilder,
        private readonly _vehicleService: VehicleService,
        private readonly _driverService: DriverService,
        private readonly _notification: Notifications
    ) {}

    // public vehicleFormStepOne(form: any): void {
    

    //     this.isUpdate = form?.isUpdate;
    //     this.data = form.form;
    //     this.formValid = form?.isvalid;
    // }

    // public vehicleFormStepTwo(form: any): void {
    
    //     this.formValid = form.isvalid;
    //     this.stepTwoForm = form.form;
    // }

    // public vehicleFormStepThree(form: any): void {
    
    //     this.formValid = form.isvalid;
    //     this.stepThreeForm = form.form;
    //     this.formDataVehicle();
    // }

    public myVehicleForm(form: any, index: number) {
        this.formsValidity[index] = form?.isvalid;
        if (index === 0) {
            this.isUpdate = form?.isUpdate;
            this.data = form.form;
        } else if (index === 1) {
            this.stepTwoForm = form.form;
        } else if (index === 2) {
            this.stepThreeForm = form.form;

            this.formDataVehicle();
        }
    }

    public async done(): Promise<void> {
        try {
            this.loading = true;
            if (!this.isCancelClicked && (this.formsValidity)) {
                this.formDataVehicle();
                const model = this.form.value;

                if (model.id == null) {
                    this.response = await this._vehicleService.addVehicle(model);
                } else {
                    this.response = await this._driverService.updateVehicles(model);
                }

                // if (this.response.respCode === HttpStatusCode.INTERNALSERVERERROR) {
                //     this.loading = false;
                //     this._dialog.closeAll();
                //     return;
                // }

                if (this.response.respCode === HttpStatusCode.ACCEPTED || this.response.respCode === HttpStatusCode.OK) {
                    this._notification.showNotification(this.response.respMessage, 'success');

                    localStorage.setItem('SAVED_VEHICLE', JSON.stringify(model));
                } else {
                    this._notification.showNotification(this.response.respMessage, 'danger');
                }

                this.loading = false;
                this._dialog.closeAll();
            } else {
                this.loading = false;
            }
        } catch (error) {
            this.loading = false;
        }
    }

    public formDataVehicle() {
        if (this.data !== undefined) {

            const description = this.data?.descriptions?.find((x: { value: any; }) => x.value ==  parseInt(this.data.vehicleKey))
            this.form.patchValue({
                id: this.data.id,
                trackingDeviceInstalled: this.stepTwoForm.trackingDeviceInstalled,
                automaticImpactDetection: this.stepTwoForm.automaticImpactDetection,
                movementMonitored: this.stepTwoForm.movementMonitored,
                vehicleMakeDescription: this.data.vehicleMakeDescription,
                vehicleModelDescription: this.data.vehicleModelDescription,
                paintType: this.data.paintType,
                vehicleColour: this.data.vehicleColour,
                vehicleMake: this.data.vehicleMake,
                vehicleDescription: !description.viewValue ? null : description.viewValue,
                vehicleKey: !this.data.vehicleKey ? null : this.data.vehicleKey,
                vehicleModel: this.data.vehicleModel,
                vehicleName: this.data.vehicleName,

                vehicleRegNo: this.data.vehicleRegNo,
                engineModified: this.stepTwoForm.engineModified,
                vehicleYear: this.data.vehicleYear,
                vehicleFinanced: this.data.vehicleFinanced,
                newOne: this.data.newOne,
                coverTypeRequested: this.data.coverTypeRequested,
                ncb: this.data.ncb,
                financedAmount: this.stepThreeForm.financedAmount,
                kmTravelled: this.stepThreeForm.kmTravelled,
                identities: this.stepThreeForm.identities,

                dayParking: this.stepThreeForm.dayParking,
                approvedSecSystemInstalled: this.stepThreeForm.approvedSecSystemInstalled?.toString(),
                dayAreaType: this.stepThreeForm.dayAreaType,
                dayAddress: this.stepThreeForm.dayAddress,

                nightParking: this.stepThreeForm.nightParking,
                vehicleType: this.stepThreeForm.vehicleType,
                address: this.stepThreeForm.address,

                address1: this.stepThreeForm.address1,
                nightAreaType: this.stepThreeForm.nightAreaType,
                nightAddress: this.stepThreeForm.nightAddress,
            });
        }
    }

    public async ngOnInit(): Promise<void> {
        const vm = this;
        this.formInitialization();

        // Code for the Validator
        const $validator = $('.card-wizard form').validate({
            rules: {
                firstname: {
                    required: true,
                    minlength: 3,
                },
                lastname: {
                    required: true,
                    minlength: 3,
                },
                email: {
                    required: true,
                    minlength: 3,
                },
            },

            highlight(element: any) {
                $(element).closest('.form-group').removeClass('has-success').addClass('has-danger');
            },
            success(element: any) {
                $(element).closest('.form-group').removeClass('has-danger').addClass('has-success');
            },
            errorPlacement(error: any, element: any) {
                $(element).append(error);
            },
        });

        // Wizard Initialization
        $('.card-wizard').bootstrapWizard({
            tabClass: 'nav nav-pills',
            nextSelector: '.btn-next',
            previousSelector: '.btn-previous',

            onPrevious(tab: any, navigation: any, index: any) {
                if (index === 2) {
                    vm.isStepThree = true;
                } else {
                    vm.isStepThree = false;
                }
                document.querySelectorAll('.progress-bar-line-vehicle')[index + 1].classList.remove('progress-bar-line-active');
                document
                    .querySelectorAll('.progress-bar-line-vehicle')
                    [index].classList.remove('progress-bar-line-previous-active');
            },

            onNext(tab: any, navigation: any, index: any): any {
                if (vm.isUpdate !== true) {
                    vm.formValid = false;
                }

                vm.currentStep = index

                if (index === 2) {
                    vm.isStepThree = true;
                } else {
                    vm.isStepThree = false;
                }

                document.querySelectorAll('.progress-bar-line-vehicle')[index].classList.add('progress-bar-line-active');
                document
                    .querySelectorAll('.progress-bar-line-vehicle')
                    [index - 1].classList.add('progress-bar-line-previous-active');
            },

            async onInit(tab: any, navigation: any, index: any) {
                // check number of tabs and fill the entire row

                const stepID = navigation[0].childNodes[index].id;

                document.querySelectorAll('.progress-bar-line-vehicle')[index].classList.add('progress-bar-line-active');
                let $total = navigation.find('li').length;

                const $wizard = navigation.closest('.card-wizard');

                const $first_li = navigation.find('li:first-child a').html();
                const $moving_div = $('<div class="moving-tab">' + $first_li + '</div>');
                $('.card-wizard .wizard-navigation').append($moving_div);

                $total = $wizard.find('.nav li').length;
                let $li_width = 100 / $total;

                const total_steps = $wizard.find('.nav li').length;
                let move_distance = $wizard.width() / total_steps;
                let index_temp = index;
                let vertical_level = 0;

                const mobile_device = $(document).width() < 600 && $total > 3;

                if (mobile_device) {
                    move_distance = $wizard.width() / 2;
                    index_temp = index % 2;
                    $li_width = 50;
                }

                $wizard.find('.nav li').css('width', $li_width + '%');

                const step_width = move_distance;
                move_distance = move_distance * index_temp;

                const $current = index + 1;

                if ($current == 1 || (mobile_device == true && index % 2 == 0)) {
                    move_distance -= 8;
                } else if ($current == total_steps || (mobile_device == true && index % 2 == 1)) {
                    move_distance += 8;
                }

                if (mobile_device) {
                    const x: any = index / 2;
                    vertical_level = parseInt(x);
                    vertical_level = vertical_level * 38;
                }

                $wizard.find('.moving-tab').css('width', step_width);
                $('.moving-tab').css({
                    transform: 'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
                    transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)',
                });
                $('.moving-tab').css('transition', 'transform 0s');
            },

            onTabClick(tab: any, navigation: any, index: any) {
                const $valid = $('.card-wizard form').valid();

                if (!$valid) {
                    return false;
                } else {
                    return false;
                }
            },

            async onTabShow(tab: any, navigation: any, index: any) {
                const $wizard = navigation.closest('.card-wizard');
                let $total = navigation.find('li').length;
                let $current = index + 1;
                vm.currentStep = index

                if (index !== 0) {
                    document.querySelectorAll('.previous-item')[index - 1].classList.add('previous-wizard');
                }

                if (index === 0) {
                    document.querySelectorAll('.previous-item')?.forEach(item => {
                        item.classList.remove('previous-wizard');
                    });
                }

                if (index >= 1) {
                    document.querySelectorAll('.previous-item')[index].classList.remove('previous-wizard');
                }

                // If it's the last tab then hide the last button and show the finish instead
                if ($current >= $total) {
                    $($wizard).find('.btn-next').hide();
                    $($wizard).find('.btn-finish').show();
                } else {
                    $($wizard).find('.btn-next').show();
                    $($wizard).find('.btn-finish').hide();
                }

                // if (index >= 1) {
                //     $($wizard).find('.add-new-btn').hide();
                //     $($wizard).find('.add-new-btn').hide();
                // } else if (index === 0) {
                //     $($wizard).find('.add-new-btn').show();
                // }

                // if (index >= 2) {
                //     $($wizard).find('.add-new-btn-vehicle').hide();
                //     $($wizard).find('.add-new-btn').hide();
                //     $($wizard).find('.add-new-beneficiary').hide();

                // } else if (index === 1) {
                //     $($wizard).find('.add-new-btn-vehicle').show();
                //     $($wizard).find('.add-new-btn').hide();
                //     $($wizard).find('.add-new-beneficiary').hide();

                // }
                // else if (index === 0) {
                //     $($wizard).find('.add-new-btn').show();
                //     $($wizard).find('.add-new-btn-vehicle').hide();
                //     $($wizard).find('.add-new-beneficiary').hide();

                // }

                // else if (index === 6) {
                //     $($wizard).find('.add-new-btn').hide();
                //     $($wizard).find('.add-new-btn-vehicle').hide();
                //     $($wizard).find('.add-new-beneficiary').show();

                // }

                const button_text = navigation.find('li:nth-child(' + $current + ') a').html();

                setTimeout(function () {
                    $('.moving-tab').text(button_text);
                }, 150);

                const checkbox = $('.footer-checkbox');

                if (index !== 0) {
                    $(checkbox).css({
                        opacity: '0',
                        visibility: 'hidden',
                        position: 'absolute',
                    });
                } else {
                    $(checkbox).css({
                        opacity: '1',
                        visibility: 'visible',
                    });
                }
                $total = $wizard.find('.nav li').length;
                let $li_width = 100 / $total;

                const total_steps = $wizard.find('.nav li').length;
                let move_distance = $wizard.width() / total_steps;
                let index_temp = index;
                let vertical_level = 0;

                const mobile_device = $(document).width() < 600 && $total > 3;

                if (mobile_device) {
                    move_distance = $wizard.width() / 2;
                    index_temp = index % 2;
                    $li_width = 50;
                }

                $wizard.find('.nav li').css('width', $li_width + '%');

                const step_width = move_distance;
                move_distance = move_distance * index_temp;

                $current = index + 1;

                if ($current == 1 || (mobile_device == true && index % 2 == 0)) {
                    move_distance -= 8;
                } else if ($current == total_steps || (mobile_device == true && index % 2 == 1)) {
                    move_distance += 8;
                }

                if (mobile_device) {
                    const x: any = index / 2;
                    vertical_level = parseInt(x);
                    vertical_level = vertical_level * 38;
                }

                $wizard.find('.moving-tab').css('width', step_width);
                $('.moving-tab').css({
                    transform: 'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
                    transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)',
                });
            },
        });

        // Prepare the preview for profile picture
        $('#wizard-picture').change(() => {
            const input = $(this);

            if (input[0].files && input[0].files[0]) {
                const reader = new FileReader();

                reader.onload = function (e: any) {
                    $('#wizardPicturePreview').attr('src', e.target.result).fadeIn('slow');
                };
                reader.readAsDataURL(input[0].files[0]);
            }
        });

        $('[data-toggle="wizard-radio"]').click(() => {
            const wizard = $(this).closest('.card-wizard');
            wizard.find('[data-toggle="wizard-radio"]').removeClass('active');
            $(this).addClass('active');
            $(wizard).find('[type="radio"]').removeAttr('checked');
            $(this).find('[type="radio"]').attr('checked', 'true');
        });

        $('[data-toggle="wizard-checkbox"]').click(() => {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).find('[type="checkbox"]').removeAttr('checked');
            } else {
                $(this).addClass('active');
                $(this).find('[type="checkbox"]').attr('checked', 'true');
            }
        });

        $('.set-full-height').css('height', 'auto');
    }

    ngOnChanges(changes: SimpleChanges) {
        const input = $(this);

        if (input[0].files && input[0].files[0]) {
            const reader: any = new FileReader();

            reader.onload = function (e: any) {
                $('#wizardPicturePreview').attr('src', e.target.result).fadeIn('slow');
            };
            reader.readAsDataURL(input[0].files[0]);
        }
    }
    // ngAfterViewInit() {
    //     $(window).resize(() => {
    //         $('.card-wizard').each(() => {
    //             setTimeout(() => {
    //                 const $wizard = $(this);
    //                 const index = $wizard.bootstrapWizard('currentIndex');
    //                 const $total = $wizard.find('.nav li').length;
    //                 let $li_width = 100 / $total;

    //                 const total_steps = $wizard.find('.nav li').length;
    //                 let move_distance = $wizard.width() / total_steps;
    //                 let index_temp = index;
    //                 let vertical_level = 0;

    //                 const mobile_device = $(document).width() < 600 && $total > 3;
    //                 if (mobile_device) {
    //                     move_distance = $wizard.width() / 2;
    //                     index_temp = index % 2;
    //                     $li_width = 50;
    //                 }

    //                 $wizard.find('.nav li').css('width', $li_width + '%');

    //                 const step_width = move_distance;
    //                 move_distance = move_distance * index_temp;

    //                 const $current = index + 1;

    //                 if ($current == 1 || (mobile_device == true && index % 2 == 0)) {
    //                     move_distance -= 8;
    //                 } else if ($current == total_steps || (mobile_device == true && index % 2 == 1)) {
    //                     move_distance += 8;
    //                 }

    //                 if (mobile_device) {
    //                     const x: any = index / 2;
    //                     vertical_level = parseInt(x);
    //                     vertical_level = vertical_level * 38;
    //                 }

    //                 $wizard.find('.moving-tab').css('width', step_width);
    //                 $('.moving-tab').css({
    //                     transform: 'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
    //                     transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)',
    //                 });

    //                 $('.moving-tab').css({
    //                     transition: 'transform 0s',
    //                 });
    //             }, 500);
    //         });
    //     });
    // }

    public formInitialization() {
        this.form = this.formBuilder.group({
            id: null,
            trackingDeviceInstalled: [null],
            automaticImpactDetection: [null],
            movementMonitored: [null],
            // modifiedEngine: [null],
            paintType: [null],
            vehicleKey: [null],
            vehicleColour: [null],
            vehicleDescription: [null],
            vehicleMake: [null],
            vehicleModel: [null],
            vehicleRegNo: [null],
            vehicleYear: [null],
            engineModified: [null],
            vehicleName: null,
            ncb: [null],
            vehicleMakeDescription: [null],
            vehicleModelDescription: [null],

            vehicleFinanced: [null, Validators.required],
            newOne: [null, Validators.required],
            financedAmount: '0',
            identities: '',
            kmTravelled: [null, Validators.required],
            dayParking: [null, Validators.required],
            nightParking: [null, Validators.required],
            approvedSecSystemInstalled: [null, Validators.required],
            dayAreaType: [null, Validators.required],
            dayAddress: [null, Validators.required],
            nightAreaType: [null, Validators.required],
            nightAddress: [null, Validators.required],
        });
    }
}
