<div id="outer">
    <div id="inner">
        <h4><b>You are one step closer to unlocking great solutions!</b></h4>
        <div class="row">
            <div class="col-md-8">Is at least one of these quotes better than your current cover?</div>
            <div class="col-md-4">
                <mat-radio-group>
                    <mat-radio-button #yesQuote value="yes" (change)="questionAnswered($event, 'yesQuote')">Yes</mat-radio-button>
                    <mat-radio-button #noQuote value="no" (change)="questionAnswered($event, 'noQuote')">No</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <br />
        <div class="select-group" *ngIf="noQuote.checked">
            <textarea class="text-input" matInput placeholder="Tell us more"></textarea>
        </div>
        <br />
        <div class="row">
            <div class="col-md-8">Are these within your budgets?</div>
            <div class="col-md-4">
                <mat-radio-group>
                    <mat-radio-button #yesBudget value="yes" (change)="questionAnswered($event, 'yesBudget')">Yes</mat-radio-button>
                    <mat-radio-button #noBudget value="no" (change)="questionAnswered($event, 'noBudget')">No</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <br />
        <p style="text-align: center; font-style: italic; justify-content: center;" *ngIf="noBudget.checked">Do you want
            to
            explore other options or continue with this quote?
        </p>
        <div class="continue-group">
            <button mat-raised-button (click)="dialogRef.close(true)" *ngIf="noBudget.checked"
                class="btn btn-primary btn-round">Explore</button>

            <button mat-raised-button [disabled]="!canContinue" (click)="dialogRef.close(true)"
                class="btn btn-primary btn-round">Continue</button>
        </div>
    </div>
</div>