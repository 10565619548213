import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zarCurrency'
})
export class ZarCurrencyPipe implements PipeTransform {

  transform(value: number): string {
    // Check if value is not a number or is not finite
    if (isNaN(value) || !isFinite(value)) {
      return '';
    }

    // Convert number to string
    let stringValue = value.toString();

    // Split integer and decimal parts
    let integerPart = stringValue;
    let decimalPart = '';

    // Check if there's a decimal part
    const decimalIndex = stringValue.indexOf('.');
    if (decimalIndex !== -1) {
      integerPart = stringValue.substring(0, decimalIndex);
      decimalPart = stringValue.substring(decimalIndex + 1);
    }

    // Format integer part with thousand separators
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    // Concatenate integer and decimal parts with currency symbol
    let formattedValue = `R${integerPart}`;

    // If there's a decimal part, add it to the formatted value
    if (decimalPart) {
      formattedValue += `,${decimalPart.padEnd(2, '0')}`;
    } else {
      formattedValue += ',00';
    }

    return formattedValue;
  }

}
