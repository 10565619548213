<div>
    <h4>You one step closer to unlocking great value!</h4>
    <div nz-row>
        <div nz-col nzSpan="12">Is this a replacement policy?</div>
        <div nz-col nzSpan="4" nzOffset="2">
            <mat-radio-button value="4">Yes</mat-radio-button>
        </div>
        <div nz-col nzSpan="4" nzOffset="1">
            <mat-radio-button value="4">No</mat-radio-button>
        </div>
    </div>
    <div class="continue-group">
        <button mat-raised-button (click)="dialogRef.close(true)" class="btn btn-primary btn-round">Continue</button>
    </div>
</div>