import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { FormControlErrorComponent } from './components/form-control-error';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenHttpInterceptor } from '../Interceptors/token-http.interceptor';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { PaymentDetailComponent } from './payment-detail/payment-detail.component';
import { BankFormComponent } from './bank-form/bank-form.component';
import { RecordOfAdviceComponent } from './record-of-advice/record-of-advice.component';
import { MessageComponent } from './message/message.component';
import { FieldErrorDisplayComponent } from './components/field-error-display/field-error-display.component';
import { QuoteOptionsComponent } from './quote-options/quote-options.component';
import { PolicyDetailComponent } from './policy-detail/policy-detail.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { StepOneComponent } from './questions/step-one/step-one.component';
import { StepTwoComponent } from './questions/step-two/step-two.component';
import { FormBeneficiaryComponent } from './form-beneficiary/form-beneficiary.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { QuoteScheduleComponent } from './quote-schedule/quote-schedule.component';
import { VerifyEmailDialogComponent } from './verify-email-dialog/verify-email-dialog.component';
import { VehicleQuestionsComponent } from './questions/vehicle-questions/vehicle-questions.component';
import { FieldErrorCheckboxDisplayComponent } from './components/field-error-checkbox-display/field-error-checkbox-display.component';
import { FormMemberComponent } from './form-member/form-member.component';
import { DatePipe } from '@angular/common';
import { BeneficiaryComponent } from './beneficiary/beneficiary.component';
import { SingleActionMessageComponent } from './single-action-message/single-action-message.component';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ReplacePipe } from '../core/_helpers/replace.pipe';
import { DialogModalComponent } from './dialog-modal/dialog-modal.component';
import { MoreQuoteOptionComponent } from './more-quote-option/more-quote-option.component';
import { FormatMainMemberPipe } from '../core/_helpers/format-main-member.pipe';
import { GlossaryComponent } from '../glossary/glossary.component';
import { AmbitionComponent } from '../ambition/components/ambition/ambition.component';
import { PhonePipe } from '../core/_helpers/phone';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ZarCurrencyPipe } from '../core/_helpers/zar-currency.pipe';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { CapitalizePipe } from '../core/_helpers/capitalize.pipe';
import { AddVehicleStepthreeComponent } from '../vehicle/components/add-vehicle-stepthree/add-vehicle-stepthree.component';
import { AddVehicleSteptwoComponent } from '../vehicle/components/add-vehicle-steptwo/add-vehicle-steptwo.component';
import { AddVehicleComponent } from '../vehicle/components/add-vehicle/add-vehicle.component';
import { AddVehicleStepOneComponent } from '../vehicle/components/add-vehicle-step-one/add-vehicle-step-one.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    declarations: [
        FormControlErrorComponent,
        BankFormComponent,
        AmbitionComponent,
        PaymentDetailComponent,
        CapitalizePipe,
        RecordOfAdviceComponent,
        ReplacePipe,
        FormatMainMemberPipe,
        AddVehicleSteptwoComponent,
        AddVehicleComponent,
        AddVehicleStepthreeComponent,
        AddVehicleStepOneComponent,
        ZarCurrencyPipe,
        MessageComponent,
        FieldErrorDisplayComponent,
        QuoteOptionsComponent,
        FormMemberComponent,
        PolicyDetailComponent,
        BeneficiaryComponent,
        StepOneComponent,
        StepTwoComponent,
        FormBeneficiaryComponent,
        VerifyEmailComponent,
        QuoteScheduleComponent,
        VerifyEmailDialogComponent,
        VerifyEmailDialogComponent,
        VehicleQuestionsComponent,
        QuoteScheduleComponent,
        FieldErrorCheckboxDisplayComponent,
        SingleActionMessageComponent,
        DialogModalComponent,
        MoreQuoteOptionComponent,
        GlossaryComponent,
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatGridListModule,
        NzCarouselModule,
        NgxExtendedPdfViewerModule,
        MatFormFieldModule,
        MatCheckboxModule,
        Ng2TelInputModule,
        ReactiveFormsModule,
        FormsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatMenuModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        NzGridModule,
        NzButtonModule,
        NzTabsModule,
        NzCardModule,
        NzInputModule,
        NzFormModule,
        MatSelectModule,
        MatRadioModule,
        GooglePlaceModule,
        MatDatepickerModule,
        MatStepperModule,
        MatNativeDateModule,
        NzModalModule,
    ],
    exports: [
        MatTabsModule,
        MatButtonModule,
        AmbitionComponent,
        MatTooltipModule,
        MatIconModule,
        DialogModalComponent,
        GooglePlaceModule,
        MatInputModule,
        MatGridListModule,
        AddVehicleSteptwoComponent,
        AddVehicleComponent,
        AddVehicleStepthreeComponent,
        AddVehicleStepOneComponent,
        CapitalizePipe,
        MatCardModule,
        MatFormFieldModule,
        MatCheckboxModule,
        Ng2TelInputModule,
        FormControlErrorComponent,
        MessageComponent,
        ReactiveFormsModule,
        MatNativeDateModule,
        FormsModule,
        MatToolbarModule,
        MatMenuModule,
        MatDividerModule,
        NgxExtendedPdfViewerModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        NzGridModule,
        NzButtonModule,
        NzTabsModule,
        NzCardModule,
        NzInputModule,
        NzFormModule,
        MatSelectModule,
        MatRadioModule,
        MatDatepickerModule,
        MatStepperModule,
        PaymentDetailComponent,
        BankFormComponent,
        RecordOfAdviceComponent,
        FieldErrorDisplayComponent,
        QuoteOptionsComponent,
        PolicyDetailComponent,
        FormBeneficiaryComponent,
        BeneficiaryComponent,
        ReplacePipe,
        ZarCurrencyPipe,
        FormatMainMemberPipe,
        NzModalModule,
        VerifyEmailComponent,
        QuoteScheduleComponent,
        VerifyEmailDialogComponent,
        FormMemberComponent,
        VehicleQuestionsComponent,
        FieldErrorCheckboxDisplayComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenHttpInterceptor,
            multi: true,
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        DatePipe,
        CurrencyPipe,
        PhonePipe,
    ],
})
export class SharedModule {}
