import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { INotificationRequest } from '../models/notificationRequest';
import { INotification, INotificationResponse } from '../models/notificationResponse';
import { IResponseStatus } from '../models/response-status';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    public constructor(private readonly _http: HttpClient) { }

    public async getAllNotifications(model: INotificationRequest): Promise<INotificationResponse> {
        try {

            const response = await this._http
                .post<INotificationResponse>(`${environment.serverApiUrl}auth/v1/notification/get-all`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getAllUserNotifications(model: INotificationRequest): Promise<INotificationResponse> {
        try {

            const response = await this._http
                .post<INotificationResponse>(`${environment.serverApiUrl}auth/v1/notification/get-user-notifications`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getAllUserNotificationsByFilter(model: INotificationRequest): Promise<INotificationResponse> {
        try {

            const response = await this._http
                .post<INotificationResponse>(`${environment.serverApiUrl}auth/v1/notification/get-user-notifications-by-filter`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async updateUserNotification(model: INotification): Promise<IResponseStatus> {
        try {

            const response = await this._http
                .post<IResponseStatus>(`${environment.serverApiUrl}auth/v1/notification/update-user-notification`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }
}
