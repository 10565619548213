import { UserTaskTypeEnum } from "../enums/user-task-type.enum";
import { IUserTask, TaskStatusEnum } from "../models/dashboard";

export class UserTaskMapper {
    public static Map(input: any): IUserTask {
        let response: IUserTask;

        response =
        {
            id: input.id,
            completionDate: new Date(input.completionDate),
            progressPercentage: input.progressPercentage,
            dueDate: new Date(input.dueDate),
            expiryDate: new Date(input.expiryDate),
            taskDescription: input.taskDescription,
            created: new Date(input.created),
            status: input.status,
            taskType: input.taskType,
            icon: this.GetIcon(input.taskType),
            statusColorLabel: this.GetStatusLabelColor(input.progressPercentage),
            generatedBy: input.generatedBy,
            pathUrl: this.GetUrl(input.taskType)
        };

        return response;
    }

    public static OutMap(input: IUserTask): any {

        const response =
        {
            id: input.id,
            completionDate: input.completionDate,
            progressPercentage: input.progressPercentage,
            dueDate: input.dueDate,
            expiryDate: input.expiryDate,
            taskDescription: input.taskDescription,
            created: input.created,
            status: input.status,
            taskType: input.taskType,
            generatedBy: input.generatedBy,
        };

        return response;
    }


    public static GetIcon(taskType: any): any {
        let response = 'task_alt';

        switch (taskType) {
            case UserTaskTypeEnum.BENEFICIARY_DETAIL_UPDATE:
                response = 'group_add';
                break;
            case UserTaskTypeEnum.FICA:
                response = 'inventory';
                break;
            case UserTaskTypeEnum.KYC:
                response = 'contact_page';
                break;
            case UserTaskTypeEnum.FUNERAL_CLAIM:
                response = 'volunteer_activism';
                break;
            case UserTaskTypeEnum.FUNERAL_QUOTE:
                response = 'monitor_heart';
                break;
            case UserTaskTypeEnum.FUNERAL_QUOTE_UPDATE:
                response = 'monitor_heart';
                break;
            case UserTaskTypeEnum.MOTOR_CLAIM:
                response = 'directions_car';
                break;
            case UserTaskTypeEnum.MOTOR_QUOTE:
                response = 'directions_car';
                break;
            case UserTaskTypeEnum.MOTOR_QUOTE_UPDATE:
                response = 'directions_car';
                break;
            case UserTaskTypeEnum.VEHICLE_ASSESSMENT:
                response = 'calendar_month';
                break;
            case UserTaskTypeEnum.AMBITIONS:
                response = 'inventory'
                break;

            default:
                response = 'task_alt';
        }

        return response;
    }

    public static GetUrl(taskType: any): any {
        let response = 'task_alt';

        switch (taskType) {
            case UserTaskTypeEnum:
                response = 'group_add';
                break;
            case UserTaskTypeEnum.BENEFICIARY_DETAIL_UPDATE:
                response = 'group_add';
                break;
            case UserTaskTypeEnum.FICA:
                response = '/profile/documents';
                break;
            case UserTaskTypeEnum.KYC:
                response = '/profile';
                break;
            case UserTaskTypeEnum.FUNERAL_CLAIM:
                response = '/claims';
                break;
            case UserTaskTypeEnum.FUNERAL_QUOTE:
                response = '/dashboard/funeral-insurance/members';
                break;
            case UserTaskTypeEnum.FUNERAL_QUOTE_UPDATE:
                response = '/dashboard/funeral-insurance/members';
                break;
            case UserTaskTypeEnum.MOTOR_CLAIM:
                response = '/claims';
                break;
            case UserTaskTypeEnum.MOTOR_QUOTE:
                response = '/dashboard/vehicle-insurance/vehicles?solution=prime';
                break;
            case UserTaskTypeEnum.MOTOR_QUOTE_UPDATE:
                response = '/dashboard/vehicle-insurance/vehicles?solution=prime';
                break;
            case UserTaskTypeEnum.VEHICLE_ASSESSMENT:
                response = 'calendar_month';
                break;
            case UserTaskTypeEnum.AMBITIONS:
                response = '/profile/Ambitions';
                break;

            default:
                response = 'task_alt';
        }

        return response;
    }


    public static GetUserTasks(input: any): IUserTask[] | PromiseLike<IUserTask[]> {
        let response = new Array<IUserTask>();

        input.tasks?.forEach((element: any) => {
            response.push(this.Map(element));
        });

        return response;
    }

    public static GetStatusLabelColor(value: number): any {
        var status = value >= 80 ? "success" :
            (value >= 50 && value < 80 ? "info" :
                (value >= 25 && value < 50 ? "warning" :
                    "danger"));

        return status;
    }
}