export enum AlertActionType {
    LoadAlerts = '[Alert] Load Alerts',
    AddAlert = '[Alert] Add Alert',
    UpsertAlert = '[Alert] Upsert Alert',
    AddAlerts = '[Alert] Add Alerts',
    UpsertAlerts = '[Alert] Upsert Alerts',
    UpdateAlert = '[Alert] Update Alert',
    UpdateAlerts = '[Alert] Update Alerts',
    DeleteAlert = '[Alert] Delete Alert',
    DeleteAlerts = '[Alert] Delete Alerts',
    ClearAlerts = '[Alert] Clear Alerts',
}
