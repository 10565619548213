import { IUserDashboard } from "../models/dashboard";

export class UserDashboardMapper
{
//this class should be a template type class to take advantage of the inherent reflection in Angular
// for now we'll do it manually

    public static GetUserDashboard(input : any) : IUserDashboard
    {
        let response : IUserDashboard = 
        {
            responseStatus : 
            {
                responseCode : input.respCode,
                responseMessage : input.respMessage
            },
            ambitions : input.ambitions ? input.ambitions : 0,
            funeralQuotes : input.funeralQuotes ? input.funeralQuotes : 0,
            motorQuotes : input.motorQuotes ? input.motorQuotes : 0,
            funeralSolutions : input.funeralPolicies ? input.funeralPolicies : 0,
            motorSolutions : input.motorPolicies ? input.motorPolicies : 0,
            funeralClaims : input.funeralClaims ? input.funeralClaims : 0,
            motorClaims : input.motorClaims ? input.motorClaims : 0,
        } 

        response.totalClaims = response.funeralClaims + response.motorClaims;
        response.totalQuotes = response.funeralQuotes + response.motorQuotes;
        response.totalSolutions = response.funeralSolutions + response.motorSolutions;
 
        return response; 
    }
}