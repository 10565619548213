<!-- <div class="main-content main-content-margin-0">
    <div class="container-fluid">
        <div class="row">
            
            </div>
        </div>
    </div>
</div> -->

<div class="wizard-container">
    <h4 class="card-title">{{this.isUpdate ? 'Edit' :  'Add'}} Vehicle</h4>
    <div class="card card-wizard card-modal" data-color="rose" id="wizardProfile">
        <form method="">
            <!-- <div>
                <button class="btn_save_exit">Save and exit</button>
            </div> -->
            <!-- <div>
        
            </div> -->
            <!-- <div class="card-header text-center">
       
            </div> -->
            <div class="wizard-navigation">
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a class="nav-link active previous-item" href="#step_one" data-toggle="tab" role="tab"> </a>
                        <div class="progress-bar-line-vehicle"></div>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link previous-item" href="#step_two" data-toggle="tab" role="tab"> </a>
                        <div class="progress-bar-line-vehicle"></div>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link previous-item" href="#step_three" data-toggle="tab" role="tab"> </a>
                        <div class="progress-bar-line-vehicle"></div>
                    </li>
                </ul>
            </div>

            <div class="tab-content">
                <div class="tab-pane active" id="step_one">
                    <app-add-vehicle-step-one (vehicleForm)="myVehicleForm($event, 0)"></app-add-vehicle-step-one>
                </div>
                <div class="tab-pane" style="margin-bottom: -60px" id="step_two">
                    <app-add-vehicle-steptwo (vehicleFormSetpTwo)="myVehicleForm($event, 1)"></app-add-vehicle-steptwo>
                </div>
                <div class="tab-pane" style="margin-bottom: -60px" id="step_three">
                    <app-add-vehicle-stepthree (vehicleFormSetpThree)="myVehicleForm($event, 2)"></app-add-vehicle-stepthree>
                </div>
            </div>

            <div class="card-footer">
                <div>
                    <input type="button" class="btn-previous btn-fill btn-default btn-wd disabled" name="previous" value="Back" />
                </div>
                <input
                [disabled]="!formsValidity[currentStep]"
                    type="button"
                    class="btn btn-next btn-fill btn-rose btn-wd next"
                    name="next"
                    value="Next"
                />

                <button
                    *ngIf="isStepThree"
                    mat-raised-button
                    type="submit"
                    [disabled]="!formsValidity[currentStep]"
                    (click)="done()"
                    class="btn btn-done btn-primary btn-round"
                >
                    Done &nbsp;
                    <mat-icon *ngIf="loading">
                        <mat-spinner diameter="20"> </mat-spinner>
                    </mat-icon>
                </button>
            </div>
        </form>
    </div>
</div>
