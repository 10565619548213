// user.reducer.ts
import { Action, createReducer, on } from '@ngrx/store';
import * as userActions from '../actions/user-detail.action';
import { IUserDetails } from 'src/app/core/models/user-Details';

export interface UserState {
    userDetails: IUserDetails | null;
}

export const initialState: UserState = {
    userDetails: null,
};

export const userReducer = createReducer(
    initialState,
    on(userActions.setUserDetails, (state, { userDetails }) => ({
        ...state,
        userDetails,
    })),
    on(userActions.clearPersonalDetail, state => ({
        ...state,
        userDetails: null, 
    }))
);
