import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment) {
  enableProdMode();
  if(!window.console) {
    var console = {
     log : function(message:string){},
     warn : function(){},
     error : function(){},
     time : function(){},
     timeEnd : function(){}
    }
   }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error());
