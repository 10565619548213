import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class InsurerLogoService {

  public constructor(private readonly _http: HttpClient) {}

  public async getInsurerLogo(): Promise<Array<any>> {
      try {
          const response = await this._http
              .post<Array<any>>(`${environment.serverApiUrl}documents/v1/document/get-insurer-logos`, null)
              .toPromise();

          return response;
      } catch (error) {
          if (error && error instanceof ProgressEvent) {
              throw new Error('A connection could not be established. Please contact an administrator.');
          }
          throw Error();
      }
  }
}
