import { IResponseStatus } from "./response-status";


export enum AddressStatusEnum
{
    ACTIVE = "ACTIVE",
    DELETED = "DELETED",
}


export interface IAddressDetailsData extends IResponseStatus{
    contacts:Array<IAddressDetails>;
}


export interface IAddressDetails {
    id?: number;
    status?: string;
    street: string;
    city: string;
    subUrb: string;
    subUrnSeqNo?: string;
    province: string;
    postalCode: string;
    addressType: string;
    addressCategory?: string;
}