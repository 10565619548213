import { Action } from '@ngrx/store';
import { MotoQuoteActionType } from '../core/models/moto-quote.action.type';

export function addMotoQuoteReducer(state: any, action: any) {
    switch (action.type) {
        case MotoQuoteActionType.ADD_QUOTE:
            return action.payload;
        default:
            return state;
    }
}
