import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IOtpVehicle } from '../models/otp-vehicle';
import { IResponseStatus } from '../models/response-status';
import { IVehicleDetails } from '../models/vehicle';
import { IVehicles } from '../models/vehicles-retrieval';

@Injectable({
    providedIn: 'root',
})
export class VehicleService {
    public constructor(private readonly _http: HttpClient) {}

    relatePath = 'policy/v1/assets';

    public async getVehicles(): Promise<Array<IVehicles>> {
        try {
            const response = await this._http
                .post<Array<IVehicles>>(`${environment.serverApiUrl}` + this.relatePath + `/get-vehicles`, null)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async otpConfirm(model: any, url: string): Promise<IResponseStatus> {
        try {
            const response = await this._http.post<IResponseStatus>(`${environment.serverApiUrl}/${url}`, model).toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async updateVehicle(model: IVehicles): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(`${environment.serverApiUrl}` + this.relatePath + `/update-vehicles`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }


    public async updateVehicleRequest(model: IVehicles): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(`${environment.serverApiUrl}` + this.relatePath + `/update-vehicle-request`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async addVehicle(model: IVehicles): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(`${environment.serverApiUrl}` + this.relatePath + `/add-vehicle`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }


    public async getVehicleMake(model: any): Promise<any> {
        try {
            const response = await this._http
                .post<any>(`${environment.serverApiUrl}quote/v1/motor/get-vehicle-make`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getVehicleModel(model: any): Promise<any> {
        try {
            const response = await this._http
                .post<any>(`${environment.serverApiUrl}quote/v1/motor/get-vehicle-model`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getVehicleDescription(model: any): Promise<any> {
        try {
            const response = await this._http
                .post<any>(`${environment.serverApiUrl}quote/v1/motor/get-vehicle-description`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }
}
