import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPolicyDetails } from 'src/app/core/models/policy';

@Component({
    selector: 'app-step-two',
    templateUrl: './step-two.component.html',
    styleUrls: ['./step-two.component.scss'],
})
export class StepTwoComponent implements OnInit {
    public isQuote: boolean = false;
    public currentCover: boolean = false;
    public isQuoteText: boolean = false;
    public isBudge: boolean = false;
    public withBudgetValue: boolean = false;
    public isPolicy: boolean = false;
    public policies: Array<IPolicyDetails> = [];
    public constructor(public dialogRef: MatDialogRef<StepTwoComponent>) {}

    public async ngOnInit(): Promise<void> {
        // this.policies = await this.getPolicies();
    }

    public Onchange(event: any): void {
        if (event.value === '1') {
            this.isPolicy = true;
        } else {
            this.isPolicy = false;
            this.isQuote = false;
            this.isQuoteText = false;
            this.isBudge = false;
        }
    }
    public OnSelected(event: any): void {
        if (event.value !== '') {
            this.isQuote = true;
        } else {
            this.isQuote = false;
        }
    }

    public continue(): void {
        const model = {
            withInBudget: this.withBudgetValue,
            replacementPolicy: this.isQuote,

            betterThanCurrentInsurer: this.currentCover,
        };
        this.dialogRef.close(model);
    }

    public OnSelectedYesNo(event: any): void {
        if (event.value === '1') {
            this.isQuote = true;
        } else {
            this.isQuote = false;
        }
    }

    public OnchangeBudget(event: any): void {
        if (event.value === '1') {
            this.withBudgetValue = true;
        } else {
            this.withBudgetValue = false;
        }
    }

    public OnchangeQuote(event: any): void {
        if (event.value === '1') {
            this.currentCover = true;
        } else {
            this.currentCover = false;
        }

        this.isQuoteText = true;
        this.isBudge = true;
    }

    // public async getPolicies(): Promise<Array<IPolicyDetails>> {
    //     return this._policyService.GetFuneralPolicies();
    // }
}
