import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';


export function validateSAMobileNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        if (control.value) {
            const valid = MobileNumberValidator.isValidSAMobileNumber(control.value);
            return valid ? null : { numberNotValid: true };
        }

        return null; 
    }
}
 

export class MobileNumberValidator {

    public static isValidSAMobileNumber(number:any) : boolean
    {
        number = String(number);
        let value = MobileNumberValidator.getSAMobileNumber(number);        
        return !isNaN(Number(value)) && 
        ((value.length === 10 && value.startsWith("0")) || (value.length ===9 && !value.startsWith("0")));
    }

    public static getSAMobileNumber(number: any): string {

        number = String(number);
        if (number !== null && number !== undefined) {
            let value = number?.replace(/\s/g, "").replace(/\)/g, "").replace(/\(/g, "").replace(/\+27/g, "");
            value = value.startsWith("0") ? value.substring(1) : value;
            return value;
        }
        return null;
    }

    /**
     * fix the number to start with '0'
     * @param number 
     * 
     */
    public static fixSAMobileNumber(number : string) : string
    {
        if (number.trim().length == 9 && !number.startsWith("0")) 
        {
            return "0"+number.trim();
        }

        return number;
    }
}
