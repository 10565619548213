import { HttpStatusCode } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDocument } from 'src/app/core/models/document';
import { IUserDocumentResponse } from 'src/app/core/models/userDocument';
import { DocumentService } from 'src/app/core/services/document.service';
import { Notifications } from 'src/app/shared/notifications.component';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

  documentFile: File;
  loading: boolean = false;
  constructor(private readonly _documentService: DocumentService,
    private readonly _notification: Notifications,
    public dialogRef: MatDialogRef<ImageUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IUserDocumentResponse) { }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
  }

  UpdateDocument(event: any) {
    this.documentFile = event.target.files[0];
  }

  DocumentSubmit() {

    this.loading = true;
    if (this.data !== undefined) {
      this.DeleteCurrentProfilePicture();
    }

    if (this.documentFile === undefined || this.documentFile === null)
    {
        this.loading = false;
        this.dialogRef.close();
        return;
    }

    const document: IDocument = {
      file: this.documentFile,
      fileName: "Profile-Picture",
      docName: "PROFILE_PICTURE",
      docType: "PROFILE_PICTURE"
    }

    this._documentService.updateDocument(document).then((res) => {
      if (res.respCode === HttpStatusCode.InternalServerError) {
        this._notification.showNotification(res.respMessage, 'danger');
        this.loading = false;
        this.dialogRef.close();
      }
      if (res.respCode === HttpStatusCode.Accepted) {
        this._notification.showNotification(res.respMessage, 'success');
        this.loading = false;
        localStorage.removeItem('PROFILE_PICTURE');
        this.dialogRef.close();
      }

      if (res.respCode === HttpStatusCode.BadRequest) {
        this._notification.showNotification(res.respMessage, 'success');
        this.loading = false;
        this.dialogRef.close();
      }
    })

  }

  DeleteCurrentProfilePicture() {
    this._documentService.deleteDocument(this.data.fileUrlToken).then(() => {
    })
  }
}
