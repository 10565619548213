<div class="wrapper">
    <div class="sidebar" data-color="rose" data-background-color="black" data-image="../assets/img/sidebar-1.jpg">
        <div class="sidebar-wrapper">
            <div class="logo magis-logo center">
                <img src="../../../assets/logos/magis-logo3.png" width="30%" />
            </div>
            <div class="user" (click)="AddImage()">
                <div *ngIf="currentImage === undefined" class="photo" title="change profile picture">
                    <img src="../../../assets/img/default-avatar.svg" width="40%" />
                </div>
                <div *ngIf="currentImage !== undefined" class="photo" title="change profile picture">
                    <img src="{{ profilePicUrl }}" width="40%" />
                </div>
            </div>
            <div class="user-info text-center">
                <a data-toggle="collapse" href="#collapseExample" class="nav-link" style="margin-top: 50%">
                    <span class="magis-user-info text-center">
                        {{ user }}
                    </span>
                </a>
            </div>
            <div class="user"></div>
            <div *ngIf="isMobileMenu()">
                <ul class="nav navbar-nav nav-mobile-menu">
                    <li class="nav-item dropdown magis-header-icon">
                        <a
                            class="nav-link"
                            title="Notifications"
                            href="#pablo"
                            id="navbarDropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i class="material-icons magis-header-icon">notifications</i>
                            <p>Notifications</p>
                            <span class="notification">{{ notificationCount }}</span>
                        </a>
                        <div
                            *ngIf="notifications.length == 0"
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="navbarDropdownMenuLink"
                        >
                            <span class="dropdown-item"><i>No notification</i></span>
                        </div>
                        <div
                            *ngIf="notifications.length > 0"
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="navbarDropdownMenuLink"
                        >
                            <div
                                *ngFor="let notification of notifications"
                                class="toast"
                                role="alert"
                                aria-live="assertive"
                                aria-atomic="true"
                                (click)="UpdateNotification(notification)"
                            >
                                <div class="toast-header">
                                    <strong class="mr-auto"
                                        >{{ notification.notificationType }}
                                        <span *ngIf="notification.readStatus != 'READ'" class="badge badge-secondary">{{
                                            notification.readStatus
                                        }}</span></strong
                                    >
                                    <button class="btn btn-danger btn-link" (click)="DeleteNotification(notification)">
                                        <i class="material-icons">delete</i>
                                    </button>
                                </div>
                                <div class="toast-body">
                                    {{ notification.notificationDescription }}
                                    <br />
                                    <small class="text-muted">{{ notification.created | date: 'dd-MMM-YYYY' }}</small>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="user"></div>
            <ul class="nav">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/profile">
                        <span class="sidebar-normal magis-nav-icon">
                            <i class="material-icons magis-nav-icon">manage_accounts</i>
                            <!-- badge </i> -->
                            <p>Profile</p>
                        </span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" routerLink="/dashboard">
                        <i class="material-icons magis-nav-icon">dashboard</i>
                        <p>Dashboard</p>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="collapse" href="#componentsExamples" (click)="findSolutionMenu()">
                        <i class="material-icons magis-nav-icon">receipt_long</i>
                        <p>
                            Find Solution
                            <b class="caret"></b>
                        </p>
                    </a>
                    <div id="componentsExamples" *ngIf="isFindSolutionMenuOpen === true">
                        <ul class="nav" style="margin-top: 0px">
                            <li>
                                <a class="nav-link" routerLinkActive="active" routerLink="/ambition/ambition-cover">
                                    <span class="sidebar-mini"
                                        ><i class="material-icons magis-nav-sub-icon" style="padding-left: 10px"
                                            >monitor_heart</i
                                        ></span
                                    >
                                    <span class="sidebar-normal">Funeral</span>
                                </a>
                            </li>
                            <li>
                                <a class="nav-link" routerLinkActive="active" routerLink="/advice/motor-advice">
                                    <span class="sidebar-mini"
                                        ><i class="material-icons magis-nav-sub-icon" style="padding-left: 10px"
                                            >directions_car</i
                                        ></span
                                    >
                                    <span class="sidebar-normal">Motor</span>
                                </a>
                            </li>
                            <!-- <li matTooltip="Coming soon!" matTooltipClass="tooltip3" [matTooltipPosition]="'below'">
                                <a
                                    disabled
                                    class="nav-link magis-nav-icon-disabled"
                                    routerLinkActive="active"
                                    routerLink="/dashboard/vehicle-insurance"
                                >
                                    <span class="sidebar-mini"
                                        ><i class="material-icons magis-nav-icon-disabled" style="padding-left: 10px"
                                            >directions_car</i
                                        ></span
                                    >
                                    <span class="sidebar-normal">Motor</span>
                                    &nbsp;<span class="sidebar-mini"
                                        ><i class="material-icons magis-nav-icon-disabled" style="font-size: 13px"
                                            >info_outlined</i
                                        ></span
                                    >
                                </a>
                            </li> -->
                        </ul>
                    </div>

                    <!-- <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'" (click)="updatePS()" class="nav-link">
                        <i class="material-icons">{{menuitem.icontype}}</i>
                        <p>{{menuitem.title}}<b class="caret"></b></p>
                    </a> -->

                    <!--Display the submenu items-->
                    <!-- <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
                        <ul class="nav">
                            <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
                                <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link">
                                    <span class="sidebar-mini">{{childitem.ab}}</span>
                                    <span class="sidebar-normal">{{childitem.title}}</span>
                                </a>
                            </li>
                        </ul>
                    </div> -->
                </li>

                <li class="nav-item">
                    <a routerLinkActive="active" routerLink="/portfolio" class="nav-link">
                        <i class="material-icons magis-nav-icon">business_center</i>
                        <p>Portfolio</p>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" routerLink="/profile/documents">
                        <i class="material-icons magis-nav-icon">description</i>
                        <p>Documents</p>
                    </a>
                </li>

                <li matTooltip="Coming soon!" matTooltipClass="tooltip3" [matTooltipPosition]="'below'" class="nav-item">
                    <a class="nav-link magis-nav-icon-disabled">
                        <i class="material-icons magis-nav-icon-disabled">volunteer_activism</i>
                        <p>Claims</p>
                        &nbsp;<span class="sidebar-mini"
                            ><i class="material-icons magis-nav-icon-disabled" style="font-size: 15px">info_outlined</i></span
                        >
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/glossary">
                        <i class="material-icons magis-nav-icon">newspaper</i>
                        <p>Glossary of Terms</p>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link magis-nav-icon" routerLink="/faq">
                        <i class="material-icons magis-nav-icon">help</i>
                        <p>FAQ</p>
                    </a>
                </li>
                <li class="nav-item">
                    <a routerLinkActive="active" routerLink="/dashboard/ticket-support" class="nav-link">
                        <i class="material-icons magis-nav-icon">support_agent</i>
                        <p>Ticket Support</p>
                    </a>
                </li>
                <div class="user"></div>
                <li class="nav-item">
                    <a class="nav-link" (click)="logout()" title="sign out">
                        <i class="material-icons magis-nav-icon">logout</i>
                        <p>Sign Out</p>
                    </a>
                </li>
            </ul>
        </div>

        <div class="sidebar-background"></div>
    </div>
    <div class="main-panel">
        <!-- Navbar -->
        <app-header></app-header>
        <!-- End Navbar -->
        <div>
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-footer></app-footer>
</div>
