import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    mobileView$ = this.bp.observe([`(max-width: 1024px)`]).pipe(map(result => result.matches));

    constructor(private bp: BreakpointObserver) {}
}
