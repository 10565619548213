import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { getCapturePopupWidth, getCapturePopupWidthM, getConfirmPopupWidthXL } from 'src/app/core/_helpers/responsive-helper';
import { StepOneComponent } from '../questions/step-one/step-one.component';
import { StepTwoComponent } from '../questions/step-two/step-two.component';
import { DialogEvents } from 'src/app/core/enums/dialog-events.enum';
import { DialogModalComponent } from '../dialog-modal/dialog-modal.component';
import { MoreQuoteOptionComponent } from '../more-quote-option/more-quote-option.component';

declare const $: any;
@Component({
    selector: 'app-quote-options',
    templateUrl: './quote-options.component.html',
    styleUrls: ['./quote-options.component.scss'],
})
export class QuoteOptionsComponent implements OnInit, OnDestroy {
    test: Date = new Date();
    coverAmount: number;
    reason: string;
    insurerLogo: any;
    livescoveredNumber: number = 0;
    @ViewChild('scrollmenu') scrollMenu!: ElementRef;
    scrolling: boolean = false;
    scrollLeftDisabled: boolean = true;
    isquestion: boolean = false;
    scrollRightDisabled: boolean = false;
    maxScrollLeft: number = 0;
    effect = 'scrollx';
    public selectedQuote: string = '';

    @Input()
    public quoteOptionMoto: any;

    @Input()
    public quoteResult: any;
    @Input()
    public membersBenefits: any;
    @Input()
    public loadingComplete: any = false;

    @Output() quoteOptionSelected = new EventEmitter();
    @Output() replacementPolicy = new EventEmitter();

    @Input()
    public myQuotes: any;

    @Input()
    public livesCovered: any = 1;

    @Input()
    public mainCoverBenefitValue: any; //AA
    @Input()
    public coffinBenefitValue: any; //AA
    @Input()
    public tombstoneBenefitValue: any; //AA
    @Input()
    public flowerBenefitValue: any; //AA

    @Input()
    public airtimeBenefitIncluded: any; //AA

    @Input()
    public repartriationBenefitIncluded: any; //AA

    @Input()
    public waitingPeriod: any; //AA
    public solutionType: string = 'Prime';

    public constructor(private readonly _dialog: MatDialog) {}
    // checkButtonState(): void {
    //     if (!this.scrollMenu) {
    //         const scrollMenu = this.scrollMenu?.nativeElement;
    //         const scrollWidth = scrollMenu?.scrollWidth;
    //         const clientWidth = scrollMenu?.clientWidth;
    //         const scrollLeft = scrollMenu?.scrollLeft;

    //         this.leftButtonEnabled = scrollLeft > 0;
    //         this.rightButtonEnabled = scrollWidth > clientWidth && scrollLeft < scrollWidth - clientWidth;
    //     }
    // }
    // ngAfterViewInit(): void {
    //     this.scrollLeftDisabled = true;
    // }

    ngOnChanges(changes: SimpleChanges): void {
        // Check if quoteResult has change
        if (changes['quoteResult']) {
            this.livescoveredNumber = 0;
            const newQuoteResult = changes['quoteResult'].currentValue;
            this.quoteResult?.forEach((_element: any) => {
                this.livescoveredNumber = _element?.breakUpQuote?.length;
                // this.selectedQuote = _element.insuranceProvider
            });
        }
    }

    scrollLeft() {
        this.scrollLeftDisabled = true;
        this.handleScroll(-200);
    }

    scrollRight() {
        this.scrollLeftDisabled = true;
        this.handleScroll(200);
    }

    private handleScroll(scrollAmount: number): void {
        const scrollContainer = this.scrollMenu?.nativeElement;
        if (scrollContainer) {
            this.scrolling = true;
            scrollContainer.scrollLeft += scrollAmount;
            this.updateMaxScroll();
            this.setScrollButtonDisabled();
        }
    }

    updateMaxScroll() {
        const scrollContainer = this.scrollMenu?.nativeElement;
        this.maxScrollLeft = scrollContainer?.scrollWidth - scrollContainer?.clientWidth || 0;
    }

    setScrollButtonDisabled() {
        const scrollContainer = this.scrollMenu?.nativeElement;
        if (scrollContainer) {
            this.scrollLeftDisabled = scrollContainer.scrollLeft <= 32;
            this.scrollRightDisabled =
                scrollContainer.scrollLeft + scrollContainer.clientWidth >= scrollContainer.scrollWidth - 1;

            // Reset scrollLeftDisabled if at the leftmost end
            if (scrollContainer.scrollLeft + 1 >= this.maxScrollLeft) {
                this.scrollLeftDisabled = false;
            }
        }
    }
    allPremiumsZero(): boolean {
        this.getPartyType();
        return this.quoteResult?.every((item: { finalQuote?: { premium?: number } }) => item?.finalQuote?.premium === 0);
    }
    getPartyType(): string {
        const quote = this.quoteResult.map((item: { breakUpQuote: { partyType: string }[] }) => item.breakUpQuote[0]?.partyType);
        if (quote && quote?.length > 0) {
            let firstNonNullValue: { toString: () => string } = null; // Initialize variable to store first non-null value
                this.quoteResult
                .map((item: { breakUpQuote: { benefits: any[] }[] }) => {
                    if (item.breakUpQuote[0]?.benefits && item.breakUpQuote[0]?.benefits.length > 0) {
                        const value = item.breakUpQuote[0].benefits[0].value;
                        if (value !== null && firstNonNullValue === null) {
                            firstNonNullValue = value; // Store the first non-null value
                        }
                        this.coverAmount = value
                    }
                })
                .filter((amount: null) => amount !== null); // Filter out null values 

      

            this.reason = this.quoteResult?.map((item: { finalQuote?: { reason?: string } }) => item?.finalQuote?.reason)[0];
            this.reason = this.reason === "" ? "Member is not covered for the requested cover amount" : this.reason

            const partyType = quote[0] === 'Main member' || quote[0] === 'Individual' ? 'principal life' : quote[0];
            return partyType;
        } else {
            return 'unknown';
        }
    }

    // onScroll(): void {
    //     this.checkButtonState();
    // }

    public more(item: any): void {
        const dialogRef = this._dialog.open(DialogModalComponent, {
            width: getCapturePopupWidthM(),
            height: '800px',
            data: {
                component: MoreQuoteOptionComponent,
                icon: 'person_add',
                quotes: item,
            },
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result !== DialogEvents.CLOSE) {
            }
        });
    }

    public getInsurerLogo(insurer: string): string {
        const defaultLogo = '../../../assets/logos/default-insurer-logo.png';
        const insurerIndex = this.insurerLogo?.findIndex((x: any) => x.insurer === insurer);

        if (insurerIndex !== -1 && insurerIndex !== undefined) {
            return this.insurerLogo[insurerIndex]?.url;
        }

        return defaultLogo;
    }

    public ngOnInit() {
   
        this.updateMaxScroll();
        
        this.setScrollButtonDisabled();
        this.solutionType = localStorage.getItem('MAGIS-FUNERAL-SOLUTION-TYPE');
        const insurerLogo = JSON.parse(localStorage.getItem('insurerLogo'));

        this.insurerLogo = insurerLogo?.map((insurer: any) => {
            return {
                ...insurer,
                //insurer: insurer, //.insurer.charAt(0).toUpperCase() + insurer.insurer.slice(1).toLowerCase(),
            };
        });

        if (!this.solutionType || (this.solutionType !== 'Prime' && this.solutionType !== 'Custom')) this.solutionType = 'Custom';

        // this.checkButtonState();
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('pricing-page');
        body.classList.add('off-canvas-sidebar');
    }

    public ngOnDestroy() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('pricing-page');
        body.classList.remove('off-canvas-sidebar');
    }

    public question(event: Event, quoteItem: any): void {
        event.preventDefault();
        this.isquestion = true;
        if (quoteItem?.finalQuote.premium !== 0) {
            this.selectedQuote = quoteItem?.insuranceProvider + quoteItem?.planName;
            localStorage.setItem('selectedInsurer', ('' + quoteItem?.insuranceProvider).toLowerCase());

            const dialogRef = this._dialog.open(StepTwoComponent, {
                width: getConfirmPopupWidthXL(),
                disableClose: true,
                data: '',
            });

            const model = {
                value: quoteItem,
            };

            this.quoteOptionSelected.emit(model);

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    //do-something-after dialog close!
                    this.replacementPolicy.emit(result);
                }
            });
        }
    }
}
