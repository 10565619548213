import { HttpStatusCode } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IDocument } from 'src/app/core/models/document';
import { IUserDocument, IUserDocumentResponse } from 'src/app/core/models/userDocument';
import { AccountService } from 'src/app/core/services/account.service';
import { DocumentService } from 'src/app/core/services/document.service';
import { UserService } from 'src/app/core/services/user.service';
import { getCapturePopupWidth, getConfirmPopupWidth } from 'src/app/core/_helpers/responsive-helper';
import { ImageUploadComponent } from '../components/image-upload/image-upload.component';
import { InsurerLogoService } from 'src/app/core/services/insurer-logo.service';
import { Store } from '@ngrx/store';
import { INotificationRequest } from 'src/app/core/models/notificationRequest';
import { NotificationService } from 'src/app/core/services/notification.service';
import { INotification } from 'src/app/core/models/notificationResponse';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { DatePipe } from '@angular/common';
import { TimeoutPopupComponent } from '../components/timeout-popup/timeout-popup.component';
import { clearPersonalDetail } from 'src/app/ngrx/actions/user-detail.action';

@Component({
    selector: 'app-site-layout',
    templateUrl: './site-layout.component.html',
    styleUrls: ['./site-layout.component.scss'],
})
export class SiteLayoutComponent implements OnInit {
    
    public user: string;
    isFindSolutionMenuOpen: boolean;
    profilePicUrl: string;
    currentImage: IUserDocumentResponse;
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    countdown: number | null = null;
    notificationCount: any = 0;
    notifications: Array<INotification> = new Array<INotification>();
    dialogRef: any;

    IDLE_TIME_SECONDS : number = 15 * 60; 
    TIMEOUT_SECONDS : number = 0.5 * 60;

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    constructor(
        private _userService: UserService,
        private route: Router,
        public _dialog: MatDialog,
        private store: Store,
        private readonly _documentService: DocumentService,
        private readonly _nsurerLogoService: InsurerLogoService,
        private _notificationService: NotificationService,
        private idle: Idle,
        private keepalive: Keepalive,
        private changeDetector: ChangeDetectorRef,
        private datepipe: DatePipe
    ) {



        if (!idle.isRunning()) {
            idle.setIdle(this.IDLE_TIME_SECONDS); // 5 minutes of inactivity
            idle.setTimeout(this.TIMEOUT_SECONDS); // 60 seconds timeout warning

            idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);


            idle.onIdleStart.subscribe(() => {

                if (this.route.url !== '/sign-in') {

                    console.log( datepipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss"), ":: You've gone idle for ", this.IDLE_TIME_SECONDS, " seconds. ");

                    this.idle.clearInterrupts();

                    const dialogRef = this._dialog.open(TimeoutPopupComponent, {
                        width: getConfirmPopupWidth(),
                        disableClose: true,
                        data: { body: null }
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        console.log('The dialog is closed. Result=' + result);
                        if (result)
                            this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
                        this.idle.watch();
                    });

                    this.dialogRef = dialogRef;
                    return dialogRef.afterClosed();
                }
                else
                {
                  this.idle.stop();
                  localStorage.clear();
                  return 0;
                }
                
            });


            idle.onIdleEnd.subscribe(() => {
                console.log(datepipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss"), ":: No longer idle.");
                changeDetector.detectChanges();
            });

            idle.onTimeout.subscribe(() => {
                this.timedOut = true;

                console.log(datepipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss"), ":: You are logout");

                localStorage.clear();
                this.route.navigate(['/sign-in']);
                this.store.dispatch(clearPersonalDetail());
                window.location.reload();
            });



            idle.onTimeoutWarning.subscribe((countdown) => {

                if (this.route.url !== '/sign-in') {

                this.dialogRef.componentInstance.body = countdown;
                console.log(datepipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss"), ":: ",`You will be logged out in ${countdown} seconds due to inactivity!`);
                this.countdown = countdown;
                }
            });

            // Keepalive settings
            keepalive.interval(15);
            keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

            this.reset();
        }

    }

    reset() {
        this.idle.watch();
        console.log(this.datepipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss"), ":: Is running : ", this.idle.isRunning() + " ");
        this.timedOut = false;
    }

    ngOnInit(): void {

        const lastRoute = localStorage.getItem('lastRoute');

        if (lastRoute) {

            if (lastRoute.indexOf("sign") == -1) {
                this.route.navigateByUrl(lastRoute);
            }
            localStorage.removeItem('lastRoute');
        }

        setTimeout(async () => {
            this.store
                .select((state: any) => state?.user)
                .subscribe(data => {
                    const userDetail = data?.userDetails;
                    this.user = userDetail?.profileDetails?.firstName.trim() + ' ' + userDetail?.profileDetails?.lastName;
                });

            // this._userService.GetUserProfile().then((res) => {
            //     this.user = res.profileDetails.firstName.trim() + ' ' + res.profileDetails.lastName;
            // })
        }, 100);

        this.isFindSolutionMenuOpen = localStorage.getItem('isFindSolutionMenuOpen') === 'true';

        this.GetProfilePicture();


        this.GetNotification();


        setTimeout(async () => {
            this.loadInsurerLogos();
        }, 1000);
    }

    async loadInsurerLogos() {
        const insurerLogo = await this._nsurerLogoService.getInsurerLogo();
        localStorage.setItem('insurerLogo', JSON.stringify(insurerLogo));
    }

    findSolutionMenu() {
        this.isFindSolutionMenuOpen = !this.isFindSolutionMenuOpen;
        localStorage.setItem('isFindSolutionMenuOpen', '' + this.isFindSolutionMenuOpen);
    }

    GetProfilePicture() {
        const docTypes = { docTypes: ['PROFILE_PICTURE'] };

        this._documentService.getUserProfilePicture(docTypes).then(result => {
            if (result.respCode != HttpStatusCode.NoContent) {
                this.currentImage = result.document[0];
                this._documentService.downloadDocument(result.document[0].fileUrlToken).then(res => {
                    this.profilePicUrl = res.url;
                });
            }
        });
    }

    GetNotification() {
        const model: INotificationRequest = {
            noOfRecords: 5,
            pageIndex: 1,
            fromDate: new Date(),
            toDate: new Date(),
            notificationType: 'KYC',
        };

        this._notificationService.getAllUserNotifications(model).then(results => {
            if (results.respCode != HttpStatusCode.NoContent) {
                this.notificationCount = results.notifications.filter(x => x.readStatus != 'READ').length;
                this.notifications = results.notifications;
            }
        });
    }

    public AddImage() {
        const dialogRef = this._dialog.open(ImageUploadComponent, {
            width: getConfirmPopupWidth(),
            data: this.currentImage,
        });
        dialogRef.afterClosed().subscribe(() => {
            this.GetProfilePicture();
        });
    }

    public logout() {
        localStorage.clear();
        this.store.dispatch(clearPersonalDetail());
        this.route.navigate(['/sign-in']);
    }

    UpdateNotification(notification: INotification) {
        notification.readStatus = 'READ';

        this._notificationService.updateUserNotification(notification).then(response => {
            this.GetNotification();
        });
    }
}
