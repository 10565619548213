import { CurrencyPipe, formatCurrency } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { AlarmEnum } from 'src/app/core/enums/alarm.enum';
import { AreaTypeEnum } from 'src/app/core/enums/area-type.enum';
import { ConvertEnum } from 'src/app/core/enums/convert.enum';

import { ParkingEnum } from 'src/app/core/enums/parking.enum';
import { TravelEnum } from 'src/app/core/enums/travel.enum';
import { IDropdown } from 'src/app/core/models/drop-down';
import { HttpStatusCode } from 'src/app/core/models/http-status-code.model';
import { DriverService } from 'src/app/core/services/driver.service';
import { getCapturePopupWidth } from 'src/app/core/_helpers/responsive-helper';
import { Notifications } from 'src/app/shared/notifications.component';
import { AddVehicleSteptwoComponent } from '../add-vehicle-steptwo/add-vehicle-steptwo.component';

@Component({
    selector: 'app-add-vehicle-stepthree',
    templateUrl: './add-vehicle-stepthree.component.html',
    styleUrls: ['./add-vehicle-stepthree.component.scss'],
})
export class AddVehicleStepthreeComponent implements OnInit {
    public travel: Array<IDropdown>;
    public loading: boolean = false;
    public isCancelClicked = false;
    public formIsValid: boolean;
    public form: FormGroup;
    public parking: Array<IDropdown>;
    public alarm: Array<IDropdown>;
    public isVehicleFinanced: boolean = true;
    public isNew: boolean = false;
    @ViewChild('placesRef') placesRef: GooglePlaceDirective;
    @Output() vehicleFormSetpThree = new EventEmitter();

    public options = {
        componentRestrictions: {
            country: ['ZA'],
        },
    };

    constructor(
        public _dialog: MatDialog,
        private formBuilder: FormBuilder,
        private readonly _driverService: DriverService,
        private readonly _notification: Notifications,
        public dialogRef: MatDialogRef<AddVehicleStepthreeComponent>,
        private currencyPipe: CurrencyPipe,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    public handleAddressChangedayAddress(address: any): void {
        this.form.patchValue({
            dayAddress: address.formatted_address,
        });
    }

    public handleAddressChangenightAddress(address: any): void {
        this.form.patchValue({
            nightAddress: address.formatted_address,
        });
    }

    public back(): void {
        this._dialog.closeAll();
        const dialogRef = this._dialog.open(AddVehicleSteptwoComponent, {
            width: getCapturePopupWidth(),
            data: this.form.value,
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(async result => {

        });
    }

    public close() {
        this.isCancelClicked = true;
        this.dialogRef.close();
    }

    onInput(event: any) {

        let formattedAmount = this.currencyPipe.transform(
            event.target.value,
            'R'
          );

          this.form.patchValue({
                     financedAmount: formattedAmount.replaceAll(',', ' '),
            });
    }

    public ngOnInit(): void {
   
        this.formInitialization();

        this.isNew = this.data.data?.vehicleYear === new Date().getFullYear();


        this.form.patchValue({
            id:this.data.data?.id,
            trackingDeviceInstalled: this.data.data?.trackingDeviceInstalled,
            automaticImpactDetection: this.data.data?.automaticImpactDetection,
            movementMonitored: this.data.data?.movementMonitored,
            vehicleMakeDescription: this.data.data?.vehicleMakeDescription,
            vehicleModelDescription: this.data.data?.vehicleModelDescription,
            paintType: this.data.data?.paintType,
            vehicleColour: this.data.data?.vehicleColour,
            vehicleMake: this.data.data?.vehicleMake,
            vehicleDescription: !this.data.data?.vehicleDescription ? null : this.data.data?.vehicleDescription,
            vehicleKey: !this.data.data?.vehicleKey ? null : this.data.data?.vehicleKey,
            vehicleModel: this.data.data?.vehicleModel,
            vehicleName: this.data.data?.vehicleName,

            vehicleRegNo: this.data.data?.vehicleRegNo,
            engineModified: this.data.data?.engineModified,
            vehicleYear: this.data.data?.vehicleYear,
            vehicleFinanced: this.data.data?.vehicleFinanced,
            newOne: this.data.data?.newOne,
            coverTypeRequested: this.data.data?.coverTypeRequested,
            ncb: this.data.data?.ncb,
            financedAmount: this.data.data?.financedAmount,
            kmTravelled: this.data.data?.kmTravelled,
            identities: this.data.data?.identities,

            dayParking: this.data.data?.dayParking,
            approvedSecSystemInstalled: this.data.data?.approvedSecSystemInstalled?.toString(),
            dayAreaType: this.data.data?.dayAreaType,
            dayAddress: this.data.data?.dayAddress,

            nightParking: this.data.data?.nightParking,
            vehicleType: this.data.data?.vehicleType,
            address: this.data.data?.address,

            address1: this.data.data?.address1,
            nightAreaType: this.data.data?.nightAreaType,
            nightAddress: this.data.data?.nightAddress,
        });
        this.isThisFormValid();
        const form = {
            isvalid: this.formIsValid,
            form: this.form.value
        }
        this.vehicleFormSetpThree.emit(form)

        this.travel = ConvertEnum.convertEnumArray(TravelEnum);

        this.alarm = ConvertEnum.convertEnumArray(AlarmEnum);

        this.parking = ConvertEnum.convertEnumArray(ParkingEnum);

        this.form.valueChanges.subscribe((selectedValue: any) => {
            this.isThisFormValid();
            const form = {
                isvalid: this.formIsValid,
                form: this.form.value
            }
            this.vehicleFormSetpThree.emit(form);
        });
    }

    areaTypes = ConvertEnum.convertEnumArray(AreaTypeEnum);

    public async done(): Promise<void> {
        try {
            this.loading = true;
            this.isThisFormValid();
            if (!this.isCancelClicked && (this.form.valid || this.formIsValid)) {
                const model = this.form.value;
                const response = await this._driverService.updateVehicles(model);

                if (response.respCode === HttpStatusCode.INTERNALSERVERERROR) {
                    this.loading = false;
                    this._dialog.closeAll();
                    return;
                }

                if (response.respCode === HttpStatusCode.ACCEPTED || response.respCode === HttpStatusCode.OK) {
                    this._notification.showNotification('Vehicle has been created successfully.', 'success');
                } else {
                    this._notification.showNotification(response.respMessage, 'danger');
                }
                this.loading = false;
                this._dialog.closeAll();
            } else {
                this.loading = false;
            }
        } catch (error) {
            this.loading = false;
        }
    }

    public onVehicleFinancedSelected(event: any) {
        this.isVehicleFinanced = event.value;
    }

    isFieldValid(form: FormGroup, field: string) {
        let control = form.get(field);
        return control !== null && control !== undefined ? !form.get(field).valid && form.get(field).touched : false;
    }

    isFieldNotValid(form: FormGroup, field: string) {
        try {
            if (form !== null || form.get(field) !== null) {
                var isFieldNotValid;
                if (form.get(field).touched) {
                    isFieldNotValid =
                        !form.get(field).valid || form.get(field).value === undefined || form.get(field).value === null;
                } else {
                    isFieldNotValid = false;
                }
                return isFieldNotValid;
            }
        } catch (error) {
        }

        return false;
    }

    isThisFormValid(): boolean {
        let isValid = true;

        const fields = [
            'kmTravelled',
            'dayParking',
            'nightParking',
            'approvedSecSystemInstalled',
            'dayAreaType',
            'dayAddress',
            'nightAreaType',
            'nightAddress',
        ];
        this.formIsValid = true;
        fields?.forEach(x => {
            const fieldValid = !this.isFieldNotValid(this.form, x);
            const value = this.form.get(x).value;
            isValid = isValid && fieldValid;
            this.formIsValid = this.formIsValid && value !== null && value !== undefined && value !== '';
        });

        return isValid;
    }

    public formInitialization() {
        this.form = this.formBuilder.group({
            id:null,
            trackingDeviceInstalled: [null],
            automaticImpactDetection: [null],
            movementMonitored: [null],
            // modifiedEngine: [null],
            paintType: [null],
            vehicleKey: [null],
            vehicleColour: [null],
            vehicleDescription: [null],
            vehicleMake: [null],
            vehicleModel: [null],
            vehicleRegNo: [null],
            vehicleYear: [null],
            engineModified: [null],
            vehicleName: null,
            ncb: 'Zero year',
            vehicleMakeDescription: [null],
            vehicleModelDescription: [null],

            vehicleFinanced: [null, Validators.required],
            newOne: [null, Validators.required],
            financedAmount: '0',
            identities: '',
            kmTravelled: [null, Validators.required],
            dayParking: [null, Validators.required],
            nightParking: [null, Validators.required],
            approvedSecSystemInstalled: [null, Validators.required],
            dayAreaType: [null, Validators.required],
            dayAddress: [null, Validators.required],
            nightAreaType: [null, Validators.required],
            nightAddress: [null, Validators.required],
        });
    }
}
