<!-- <div class="card-body">
    <form [formGroup]="menu" class="form-horizontal">
        <div class="row">
            <div class="col-sm-12 col-sm-offset-1 checkbox-radios">
                <div class="row">
                    <div class="col-1">
                        <mat-checkbox
                            [checked]="selectAllCheck"
                            (change)="OnSelectAllChange($event)"
                            formControlName="selectAll"
                            [indeterminate]="partiallySelected | async"
                            class="select-all example-margin mb-0"
                        >
                        </mat-checkbox>
                    </div>
                    <div class="col-11 select-all">Select All</div>
                </div>
                <mat-divider></mat-divider>
                <br />
                <div formArrayName="options">
                    <div class="row records-box" *ngFor="let item of menuOptions.controls; let i = index">
                        <div class="col-1">
                            <mat-checkbox
                                [checked]="selectAllCheck"
                                (change)="OnChangeCheck($event, i)"
                                [formControl]="item"
                                [value]="optionLabels[i]"
                                class="example-margin mb-0"
                            >
                            </mat-checkbox>
                        </div>
                        <div class="col-6">
                            <span class="text-label">{{ optionLabels[i] }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div> -->

<form [formGroup]="menu" class="form-horizontal">
    <div class="form-group row align-items-start">
        <div class="col-sm-12">
            <mat-checkbox
                style="margin-left: 12px"
                (change)="OnSelectAllChange($event)"
                formControlName="selectAll"
                [checked]="selectAllCheck"
            >
                <span class="select-all">Select All</span>
            </mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="col-sm-12 records-box" *ngFor="let item of menuOptions.controls; let i = index">
            <mat-checkbox
                [checked]="selectAllCheck"
                (change)="OnChangeCheck($event, i)"
                [formControl]="item"
                [value]="optionLabels[i]"
                class="example-margin mb-0"
            >
                {{ optionLabels[i] }}
            </mat-checkbox>
        </div>
    </div>
</form>
