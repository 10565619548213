<nav #navbar class="navbar navbar-expand-lg navbar-transparent  navbar-absolute">
    <div class="container-fluid">
        
        <div class="navbar-wrapper">
            <div class="navbar-minimize">
                <button mat-raised-button class="btn btn-just-icon btn-white btn-fab btn-round" (click)="minimizeSidebar()">
                    <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
                    <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
                </button>
            </div>
            <h2 class="card-title-header header" >{{pageTitle}}</h2>
        </div>
        
        <button mat-button class="navbar-toggler btn-no-ripple mtm-1" type="button" (click)="sidebarToggle()">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" style="padding-right: 8px;padding-top: 12px;" id="navigation">
            <ul class="navbar-nav">
                <span class="magis-header-icon" style="margin-right: 10px;">Welcome, <b>{{user_fullname}}</b>!</span>
                <a class="nav-link magis-header-icon" routerLink="/dashboard" title="Dashboard" style="margin-right: -10px;">
                    <i class="material-icons magis-header-icon">dashboard</i>
                </a>
                <li class="nav-item dropdown magis-header-icon">
                    <a class="nav-link" title="Notifications" href="#pablo" id="navbarDropdownMenuLink"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons magis-header-icon" style="margin-left: -10px;">notifications</i>
                        <span class="notification">{{notificationCount}}</span>
                        <p>
                            <span class="d-lg-none d-md-block">Some Actions</span>
                        </p>
                    </a>
                        <div *ngIf="notifications.length == 0" class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                          <span class="dropdown-item"><i>No notification</i></span>
                        </div>
                        <div *ngIf="notifications.length > 0" class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                            <div *ngFor="let notification of notifications" class="toast" role="alert" aria-live="assertive" aria-atomic="true" (click)="UpdateNotification(notification)">
                                <div class="toast-header">
                                  <strong class="mr-auto">{{notification.notificationType}}
                                  <span *ngIf="notification.readStatus != 'READ'" class="badge badge-secondary">{{notification.readStatus}}</span></strong>
                                  <button class="btn btn-danger btn-link" (click)="DeleteNotification(notification)"><i class="material-icons">delete</i></button>
                                </div>
                                <div class="toast-body">
                                 {{notification.notificationDescription}}
                                 <br>
                                 <small class="text-muted">{{notification.created | date:'dd-MMM-YYYY'}}</small>
                                </div>
                              </div>
                          </div>
                </li>
                <a class="nav-link magis-header-icon-disabled" aria-disabled="true" style="cursor:not-allowed;" routerLink="/dashboard" 
                title="Chat & Instant Messaging">
                    <i class="material-icons magis-header-icon-disabled" style="margin-left: -15px;">forum</i> 
                </a>
                <li>
                    <a class="nav-link magis-header-icon" (click)="logout()" title="Sign Out">
                        <i class="material-icons magis-header-icon" 
                        style="margin-left: -6px;">logout</i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>