
<form [formGroup]="form">
    <div class="row">
        <div class="col-md-6">
            <mat-form-field class="input-full-width">
                <input matInput formControlName="vehicleName" placeholder="Name" type="text" tabindex="1" />
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field class="input-full-width">
                <input
                    formControlName="vehicleRegNo"
                    matInput
                    placeholder="Registration number"
                    type="text"
                    tabindex="2"
                    required="true"
                />
                <app-field-error-display
                    [displayError]="isFieldNotValid(form, 'vehicleRegNo')"
                    errorMsg="Vehicle RegNo  is required."
                >
                </app-field-error-display>
            </mat-form-field>
        </div>

        <div class="col-md-6">
            <mat-form-field class="input-full-width">
                <mat-select
                    formControlName="vehicleYear"
                    required="true"
                    class="input-full-width"
                    placeholder="Year"
                    name="vehicleYear"
                    tabindex="3"
                >
                    <mat-option *ngFor="let year of vehicleYears" [value]="year">
                        {{ year }}
                    </mat-option>
                </mat-select>
                <app-field-error-display
                    [displayError]="isFieldNotValid(form, 'vehicleYear')"
                    errorMsg="Vehicle Year is required."
                >
                </app-field-error-display>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field class="input-full-width">
                <mat-select
                    (selectionChange)="OnchangeMake($event)"
                    formControlName="vehicleMake"
                    class="input-full-width"
                    placeholder="{{ placeholderTextMake }}Vehicle Make"
                    name="vehicleMake"
                    tabindex="4"
                    required="true"
                >
                    <mat-option *ngFor="let a of vehicleMakes" [value]="a.value">
                        {{ a.viewValue | titlecase }}
                    </mat-option>
                </mat-select>
                <mat-icon *ngIf="loading_vehicleMakes">
                    <mat-spinner diameter="20"> </mat-spinner>
                </mat-icon>
                <app-field-error-display
                    [displayError]="isFieldNotValid(form, 'vehicleMake')"
                    errorMsg="Vehicle Make  is required."
                >
                </app-field-error-display>
            </mat-form-field>
        </div>

        <div class="col-md-6">
            <mat-form-field class="input-full-width">
                <mat-select
                    (selectionChange)="OnchangeModel($event)"
                    formControlName="vehicleModel"
                    class="input-full-width"
                    placeholder="{{ placeholderTextModel }}Vehicle Model"
                    name="vehicleModel"
                    tabindex="5"
                    required="true"
                >
                    <mat-option *ngFor="let a of vehicleModels" [value]="a.value">
                        {{ a.viewValue | titlecase }}
                    </mat-option>
                </mat-select>
                <mat-icon *ngIf="loading_vehicleModels">
                    <mat-spinner diameter="20"> </mat-spinner>
                </mat-icon>
                <app-field-error-display
                    [displayError]="isFieldNotValid(form, 'vehicleModel')"
                    errorMsg="Vehicle Model is required."
                >
                </app-field-error-display>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field class="input-full-width">
                <mat-select
                    formControlName="vehicleKey"
                    class="input-full-width"
                    placeholder="{{ placeholderTextDescription }}vehicle description"
                    name="vehicleKey"
                    tabindex="6"
                    required="true"
                >
                    <mat-option *ngFor="let a of vehicleDescriptions" [value]="a.value">
                        {{ a.viewValue | titlecase }}
                    </mat-option>
                </mat-select>
                <mat-icon *ngIf="loading_vehicleDescriptions">
                    <mat-spinner diameter="20"> </mat-spinner>
                </mat-icon>
                <app-field-error-display
                    [displayError]="isFieldNotValid(form, 'vehicleKey')"
                    errorMsg="Vehicle Description  is required."
                >
                </app-field-error-display>
            </mat-form-field>
        </div>

        <div class="col-md-6">
            <mat-form-field class="input-full-width">
                <mat-select
                    formControlName="vehicleColour"
                    class="input-full-width"
                    placeholder="Colour"
                    name="vehicleColour"
                    tabindex="7"
                    required="true"
                >
                    <mat-option *ngFor="let a of vehicleColors" [value]="a.value">
                        {{ a.name | titlecase }}
                    </mat-option>
                </mat-select>
                <app-field-error-display
                    [displayError]="isFieldNotValid(form, 'vehicleColour')"
                    errorMsg="Vehicle Colour is required."
                >
                </app-field-error-display>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field class="input-full-width">
                <mat-select
                    formControlName="paintType"
                    class="input-full-width"
                    placeholder="Paint type"
                    name="paintType"
                    tabindex="8"
                    required="true"
                >
                    <mat-option *ngFor="let a of vehiclePaints" [value]="a.value" required="true">
                        {{ a.name | titlecase }}
                    </mat-option>
                </mat-select>
                <app-field-error-display [displayError]="isFieldNotValid(form, 'paintType')" errorMsg="Paint  is required.">
                </app-field-error-display>
            </mat-form-field>
        </div>
    </div>
</form>
