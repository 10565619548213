// masked-email.ts

export class MaskedEmail {
    private _email: string;
  
    constructor(email: string) {
      this._email = email;
    }
  
    get maskedEmail(): string {
      // const [username, domain] = this._email.split('@');
      // const maskedUsername = `${username.substring(0, 3)}...${username.substring(username.length - 3)}`;
      // return `${maskedUsername}@${domain}`;



      var arr = this._email.split("@");
      // process text before @
       var letter1 = arr[0][0] + "*".repeat(arr[0].length - 2) + arr[0].slice(-1);
      // process text after @
      var letter2 = arr[1][0] + "*".repeat(arr[1].length - 6) + arr[1].slice(-5);
      return letter1 + "@" + letter2;

    }
  }
  