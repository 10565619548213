import { createReducer, on } from '@ngrx/store';
import {
    setPersonalDetail,
    clearPersonalDetail,
    addRelatedParty,
    removeRelatedParty,
    addVehicleDetail,
    removeVehicleDetail,
    addBenefitDetail,
    removeBenefitDetail,
    setBenefitsObject,
    clearBenefitsObject,
    removeAllRelatedParty,
    clearAllState,
    setRecordOfAdvice,
    MotorInsuranceParams,
    updatedrivervehicle,
    deleteAllDrivers,
    updatedBenefitsDrivers,
    updateMotorAddonsDrivers,
    updateMotorBenefits,
    deleteAllVehicles,
} from '../actions/motor-vehicle.action';

export interface MotorState {
    personalDetail: {};
    relatedParty: object[];
    vehicleDetail: object[];
    benefitDetail: object[];
    benefitsObject: {};
    recordOfAdvice: boolean;
    motorInsuranceParams: MotorInsuranceParams[];
}

export const initialState: MotorState = {
    personalDetail: {},
    relatedParty: [] as object[],
    vehicleDetail: [] as object[],
    benefitDetail: [] as object[],
    benefitsObject: {},
    motorInsuranceParams: [],
    recordOfAdvice: false,
};


export const rootReducer = createReducer(
    initialState,
    on(setPersonalDetail, (state, { personalDetail }) => ({
        ...state,
        personalDetail,
    })),
    on(clearPersonalDetail, state => ({ ...state, personalDetail: {} })),
    on(addRelatedParty, (state, { party }) => ({
        ...state,
        relatedParty: [...state.relatedParty, party],
    })),

    on(removeRelatedParty, (state, party: any) => ({
        ...state,
        relatedParty: state.relatedParty.filter((item: any) => item.dependantId !== party.party.dependantId),
    })),
    on(removeAllRelatedParty, state => ({
        ...state,
        relatedParty: [],
    })),

    on(addVehicleDetail, (state, { detail }) => ({
        ...state,
        motorInsuranceParams: [...state.motorInsuranceParams, detail],
    })),
    on(updatedrivervehicle, (state, { id, driverDetails }) => {
        const index = state.motorInsuranceParams.findIndex(x => x.motorDetails.id === id);

        // Proceed only if the index is valid
        if (index !== -1) {
            const updatedParams = state.motorInsuranceParams.map((param, i) =>
                i === index ? { ...param, driverDetails } : param
            );

            return {
                ...state,
                motorInsuranceParams: updatedParams,
            };
        }

        return state; // Return the unchanged state if the index is not found
    }),

    on(updatedBenefitsDrivers, (state, { id, motorDetails }) => {
        const index = state.motorInsuranceParams.findIndex(x => x.motorDetails.id === id);

        if (index !== -1) {
            const updatedParams = state.motorInsuranceParams.map((param, i) =>
                i === index ? { ...param, motorDetails } : param
            );

            return {
                ...state,
                motorInsuranceParams: updatedParams,
            };
        }

        return state;
    }),

    on(updateMotorAddonsDrivers, (state, { id, motorAddons }) => {
        const index = state.motorInsuranceParams.findIndex(x => x.motorDetails.id === id);

        if (index !== -1) {
            const updatedParams = state.motorInsuranceParams.map((param, i) => (i === index ? { ...param, motorAddons } : param));

            return {
                ...state,
                motorInsuranceParams: updatedParams,
            };
        }

        return state;
    }),

    on(updateMotorBenefits, (state, { id, benefits }) => {
        const index = state.motorInsuranceParams.findIndex(x => x.motorDetails.id === id);

        if (index !== -1) {
            const updatedParams = state.motorInsuranceParams.map((param, i) => (i === index ? { ...param, benefits } : param));

            return {
                ...state,
                motorInsuranceParams: updatedParams,
            };
        }

        return state;
    }),

    on(deleteAllDrivers, state => {
        return { ...state, motorInsuranceParams: [] };
    }),

    on(removeVehicleDetail, (state, { id }) => ({
        ...state,
        motorInsuranceParams: state.motorInsuranceParams.filter((vehicle: any) => vehicle?.motorDetails?.id !== id),
    })),

    on(addBenefitDetail, (state, { detail }) => ({
        ...state,
        benefitDetail: detail,
    })),
    on(removeBenefitDetail, (state, { detail }) => ({
        ...state,
        benefitDetail: state.benefitDetail.filter((item: any) => item !== detail),
    })),
    on(setBenefitsObject, (state, { benefitsObject }) => ({
        ...state,
        benefitsObject,
    })),
    on(setRecordOfAdvice, (state, { recordOfAdvice }) => ({
        ...state,
        recordOfAdvice,
    })),

    on(clearBenefitsObject, state => ({ ...state, benefitsObject: {} })),
    on(clearAllState, () => initialState) // Clear all state action
);
