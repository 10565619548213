import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

export interface RelatedParty {
    id: string;
    partyId: number;
    age: string;
    cellphone: string;
    dateOfBirth: string;
    email: string;
    firstName: string;
    dependantId: number;
    gender: string;
    idNumber: string;
    initials: string;
    lastName: string;
    maritalStatus: string;
    relation: string;
    partyType: string;
    benefits: Array<Benefit>;
}

export interface Benefit {
    name: string;
    value: string;
    magisId: string;
    mandatory: boolean;
}

export interface RelatedPartiesState extends EntityState<RelatedParty> {}

export const relatedPartiesAdapter = createEntityAdapter<RelatedParty>();

export const initialState: RelatedPartiesState = relatedPartiesAdapter.getInitialState(); // src/app/store/related-parties.actions.ts

export const addRelatedParty = createAction('[Related Parties] Add Related Party', props<{ relatedParty: RelatedParty }>());

export const updateRelatedPartyBenefits = createAction(
    '[Related Parties] Update Related Party Benefits',
    props<{ id: string; benefits: Benefit[] }>()
);

export const deleteRelatedParty = createAction('[Related Parties] Delete Related Party', props<{ id: string }>());
export const deleteAllRelatedParties = createAction('[Related Parties] Delete Related Parties');
