<div class="main-content">
    <div class="container-fluid">
        <div nz-row>
            <div nz-col class="effective-date" nzSpan="8">
                <span> Effective Date: 01 November 2022</span>
            </div>
            <div nz-col nzSpan="8" nzOffset="8">
                <img src="../../../assets/logos/Picture45.png" alt="">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header card-header-rose card-header-icon">
                        <div class="card-icon">
                            <mat-icon>person</mat-icon>
                        </div>
                        <h4 class="card-title">Collapsible Accordion</h4>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th class="text-right">Surname</th>
                                        <th class="text-right">Relation </th>

                                        <th class="text-right">Cover </th>
                                        <th class="text-right">Premium </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Andrew Mike</td>
                                        <td class="text-right">Booysen</td>
                                        <td class="text-right">Main Member</td>
                                        <td class="text-right"> R 50 000. 00</td>
                                        <td class="text-right"> R 150.00</td>
                                    </tr>
                                    <tr>
                                        <td>Andrew Mike</td>
                                        <td class="text-right">Booysen</td>
                                        <td class="text-right">Main Member</td>
                                        <td class="text-right"> R 50 000. 00</td>
                                        <td class="text-right"> R 150.00</td>
                                    </tr>
                                    <tr>
                                        <td>Andrew Mike</td>
                                        <td class="text-right">Booysen</td>
                                        <td class="text-right">Main Member</td>
                                        <td class="text-right"> R 50 000. 00</td>
                                        <td class="text-right"> R 150.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>