import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IForgotPasswordModel, IForgotPasswordResetModel } from '../models/forgot-password-model';
import { ILoginModel } from '../models/login.model';
import { IMobileModel } from '../models/mobile.model';
import { IOtpModel } from '../models/otp.model';
import { IRegisterModel } from '../models/register.model';
import { IResponseStatus } from '../models/response-status';
import { ITokenResponse } from '../models/token.model';
import { IProfileDetails, IUserDetails } from '../models/user-Details';

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    relatePath: string = 'auth/v1/';

    public constructor(private readonly _http: HttpClient) { }

    public async registrationRequest(model: IMobileModel): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(`${environment.serverApiUrl}` + this.relatePath + `registration/signup-request`, model)
                .toPromise()

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getEmailVerificationStatus(): Promise<any> {
        try {
            const response = await this._http
                .post<any>(`${environment.serverApiUrl}${this.relatePath}kyc/email-verification-status`, null)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }
    public async emailVerificationConfirm(model: any): Promise<any> {
        try {
            const response = await this._http
                .post<any>(`${environment.serverApiUrl}${this.relatePath}auth/email-verification-confirm`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async emailVerificationRequest(model: any): Promise<any> {
        try {
            const response = await this._http
                .post<any>(`${environment.serverApiUrl}${this.relatePath}auth/email-verification-request`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async registrationConfirm(model: IOtpModel): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(`${environment.serverApiUrl}` + this.relatePath + `registration/signup-confirm`, model)
                .toPromise();
            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async registrationComplete(model: IRegisterModel): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(`${environment.serverApiUrl}` + this.relatePath + `registration/signup-complete`, model)
                .toPromise();
            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async login(model: ILoginModel): Promise<ITokenResponse> {
        try {
            const response = await this._http
                .post<ITokenResponse>(`${environment.serverApiUrl}` + this.relatePath + `login`, model)
                .toPromise()
            return response;

        } catch (error) {
                throw new Error('A connection could not be established. Please contact an administrator.');
        }
    }

    public async forgotPasswordRequest(model: IForgotPasswordModel): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(`${environment.serverApiUrl}` + this.relatePath + `registration/reset-password`, model)
                .toPromise();
            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async forgotPasswordUpdate(model: IForgotPasswordResetModel): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(
                    `${environment.serverApiUrl}` + this.relatePath + `registration/reset-password-confirm`,
                    model
                )
                .toPromise();
            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }
}
