import { createAction, props } from '@ngrx/store';
import { IDriverDetails } from 'src/app/core/models/driver-details';
import { IMotoDetails } from 'src/app/core/models/motor-detail';

export interface MotorInsuranceParams {
    driverDetails: IDriverDetails[];
    motorDetails: IMotoDetails;
    day: IDay;
    motorAddons: {
        ambulanceServiceIncluded: false;
        ambulanceServiceRequested: false;
        bucketSeatIncluded: false;
        bucketSeatRequested: false;
        hailCoverIncluded: false;
        hailCoverRequested: false;
        hailCoverValue: 'N';
        localTaxiIncluded: false;
        localTaxiProvider: '';
        localTaxiRequested: false;
        modifiedWheelsIncluded: false;
        modifiedWheelsRequested: false;
        musicSystemIncluded: false;
        musicSystemRequested: false;
        radioCoverIncluded: false;
        radioCoverRequested: false;
        rentalCarIncluded: false;
        rentalCarOption: 'ON_ACCIDENT';
        rentalCarRequested: true;
        roadsideAssistIncluded: false;
        roadsideAssistRequested: false;
        voluntaryExcessAmount: 0;
        voluntaryExcessIncluded: false;
        voluntaryExcessRequested: false;
        windscreenCoverIncluded: false;
        windscreenCoverRequested: false;
    };

    night: NightDetails;
    benefits: [];
}

interface IDay {
    areaType: string;
    dayAndNightSame: string;
    parkingType: string;
    postalCode: string;
    suburbName: string;
    suburbSeqNo: string;
}

interface NightDetails {
    areaType: string;
    dayAndNightSame: string;
    parkingType: string;
    postalCode: string;
    suburbName: string;
    suburbSeqNo: string;
}

// Personal Detail Actions
export const setPersonalDetail = createAction('[Personal Detail] Set', props<{ personalDetail: object }>());
export const clearPersonalDetail = createAction('[Personal Detail] Clear');

// Related Party Actions
export const addRelatedParty = createAction('[Related Party] Add', props<{ party: object }>());
export const removeRelatedParty = createAction('[Related Party] Remove', props<{ party: object }>());

export const removeAllRelatedParty = createAction('[Related Part all] Remove', props<{ party: any }>());

// Vehicle Detail Actions
export const addVehicleDetail = createAction('[Vehicle Detail] Add', props<{ detail: MotorInsuranceParams }>());
export const removeVehicleDetail = createAction('[Vehicle Detail] Remove', props<{ id: any }>());

// Update driver vehicle Actions
export const updatedrivervehicle = createAction(
    '[Drivers] Update Driver Vehicle',
    props<{ id: number; driverDetails: IDriverDetails[] }>()
);

export const updatedBenefitsDrivers = createAction('[Drivers] Update Driver Benfits', props<{ id: number; motorDetails: any }>());
export const updateMotorAddonsDrivers = createAction('[Drivers] Update Driver MotorAddons', props<{ id: number; motorAddons: any }>());
export const updateMotorBenefits = createAction('[Drivers]  Motor Benefits', props<{ id: number; benefits: any }>());



// Benefit Detail Actions
export const addBenefitDetail = createAction('[Benefit Detail] Add', props<{ detail: any }>());
export const removeBenefitDetail = createAction('[Benefit Detail] Remove', props<{ detail: object }>());

// Benefits Object Actions
export const setBenefitsObject = createAction('[Benefits Object] Set', props<{ benefitsObject: object }>());
export const clearBenefitsObject = createAction('[Benefits Object] Clear');

// Remove all properties
export const clearAllState = createAction('[Motor Vehicle] Clear All State');

// Record of Advice
export const setRecordOfAdvice = createAction('[Record of Advice] Set', props<{ recordOfAdvice: boolean }>());

export const deleteAllDrivers = createAction('[Drivers] Delete Drivers');
export const deleteAllVehicles = createAction('[Drivers]  Motor Vehiclee');
