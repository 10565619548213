import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
    positiveConfirm: boolean = true;
    public constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            noText: string;
            confirmText: string;
            message: string;
            title: string;
            positiveConfirm: boolean;
            messageType:string;
        },
        public _router: Router,
        public dialogRef: MatDialogRef<MessageComponent>
    ) {
        this.positiveConfirm = data.positiveConfirm; // ? data.positiveConfirm : true;
    }

    public ngOnInit(): void {
        this.dialogRef.disableClose = true;
    }
}
