import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IAmbition } from '../models/ambition';
import { IResponseStatus } from '../models/response-status';
import { IUserAmbitionResponse } from '../models/user-ambitions';

@Injectable({
    providedIn: 'root',
})
export class AmbitionService {
    public constructor(private readonly _http: HttpClient) {}

    public async addUserAmbition(model: IAmbition): Promise<IResponseStatus> {
        var response: IResponseStatus;

        try {
            response = await this._http
              .post<IResponseStatus>(`${environment.serverApiUrl}auth/v1/ambitions/add-user-ambition`, model)
              .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async addUserAmbitions(model: Array<IAmbition>): Promise<IResponseStatus> {
        var response: IResponseStatus;

        try {
            response = await this._http
              .post<IResponseStatus>(`${environment.serverApiUrl}auth/v1/ambitions/add-user-ambitions`, model)
              .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async updateUserAmbition(model: IAmbition): Promise<IResponseStatus> {
        var response: IResponseStatus;

        try {
            response = await this._http
              .post<IResponseStatus>(`${environment.serverApiUrl}auth/v1/ambitions/update-user-ambition`, model)
              .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async updateUserAmbitions(model: Array<IAmbition>): Promise<IResponseStatus> {
        var response: IResponseStatus;

        try {
            response = await this._http
              .post<IResponseStatus>(`${environment.serverApiUrl}auth/v1/ambitions/update-user-ambitions`, model)
              .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getUserAmbitions(): Promise<IUserAmbitionResponse> {
        let response: IUserAmbitionResponse;

        try {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
            });

            const requestOptions = {
                headers: headers,
            };

            const response = await this._http
              .post<IUserAmbitionResponse>(
                `${environment.serverApiUrl}auth/v1/ambitions/get-user-ambitions`,
                requestOptions
              )
              .toPromise();

            return response;
        } catch (error) {
            if (error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw error;
        }
    }

    public async deleteUserAmbition(model: IAmbition): Promise<IResponseStatus> {
        var response: IResponseStatus;

        try {
            const response = await this._http
              .post<any>(`${environment.serverApiUrl}auth/v1/ambitions/delete-user-ambition`, model)
              .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof Error) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }
}
