import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public title = 'magiscapital-web';

  async ngOnInit() {
    localStorage.removeItem('quotesLovesOnes');
    localStorage.removeItem('funeralBenefitOptions');
    localStorage.removeItem('funeralQuoteDetail');
    localStorage.removeItem('vehicles');

  }

  constructor(
    private router: Router
  ) {
  }
}
