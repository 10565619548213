export enum UserTaskTypeEnum {
    BENEFICIARY_DETAIL_UPDATE = 'BENEFICIARY_DETAIL_UPDATE',
    FICA = 'FICA',
    FUNERAL_CLAIM = 'FUNERAL_CLAIM',
    FUNERAL_QUOTE = 'FUNERAL_QUOTE',
    FUNERAL_QUOTE_UPDATE = 'FUNERAL_QUOTE_UPDATE',
    KYC = 'KYC',
    MOTOR_CLAIM = 'MOTOR_CLAIM',
    MOTOR_QUOTE = 'MOTOR_QUOTE',
    MOTOR_QUOTE_UPDATE = 'MOTOR_QUOTE_UPDATE',
    VEHICLE_ASSESSMENT = 'VEHICLE_ASSESSMENT',
    AMBITIONS = "AMBITIONS"
}

export enum TaskSourceEnum
{
    SYSTEM = 'SYSTEM',
    USER = 'USER',
}
