import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpStatusCode } from 'src/app/core/models/http-status-code.model';
import { AccountService } from 'src/app/core/services/account.service';
import { EmailValidators } from 'src/app/core/validators/email-validators';
import { getCapturePopupWidth, getConfirmPopupWidthXL } from 'src/app/core/_helpers/responsive-helper';
import { Notifications } from '../notifications.component';
import { VerifyEmailDialogComponent } from '../verify-email-dialog/verify-email-dialog.component';
import { UserService } from 'src/app/core/services/user.service';
import * as userActions from '../../../app/ngrx/actions/user-detail.action';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-verify-email',
    templateUrl: './verify-email.component.html',
    styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
    emailForm: FormGroup;
    loading: boolean = false;
    isSendOtp: boolean = false;
    isVerified: boolean = false;

    @Output() setEmailVerificationStatus = new EventEmitter();

    emailIsVerified: boolean = false;

    public constructor(
        public _dialog: MatDialog,
        private readonly _accountService: AccountService,
        private formBuilder: FormBuilder,
        private readonly _userService: UserService,
        private readonly _notification: Notifications,
        private store: Store
    ) { }

    ngOnInit(): void {
        this.emailForm = this.formBuilder.group({
            email: [null, Validators.required],
        });

        this.getUserDetails();
    }

    isFieldValid(form: FormGroup, field: string) {
        return !form.get(field).valid && form.get(field).touched;
    }

    public async checkEmailVerificationStatus(): Promise<any> {
        return this._accountService.getEmailVerificationStatus();
    }

    private async isEmailVerified(): Promise<boolean> {
        this.emailIsVerified = false;
        try {
            const verificationResp = await this.checkEmailVerificationStatus();
            this.emailIsVerified = verificationResp.emailVerified;
        } catch (error) {
            //do nothing with error
        }

        return this.emailIsVerified;
    }

    public getUserDetails() {
        // this._userService.GetUserProfile().then(response => {
        //     this.emailForm.patchValue({
        //         email: response.email,
        //     });
        // });

        this.store
            .select((state: any) => state?.user)
            .subscribe(data => {
                const userDetail = data?.userDetails;
                this.emailForm.patchValue({
                    email: userDetail?.email,
                });
            });
    }

    public async openOTPScreen(): Promise<void> {

        this.isSendOtp = true;
        try {
            const isVerified = await this.isEmailVerified();
            if (isVerified) {
                this._notification.showNotification('Your email is already verified', 'success');
                return;
            }

            if (this.emailForm.valid) {
                this.loading = true;
                const response = await this._accountService.emailVerificationRequest(this.emailForm.value);

                this.loading = false;

                if (response.respCode === HttpStatusCode.ACCEPTED || response.respCode === HttpStatusCode.OK) {
                    this.isSendOtp = false;

                    const dialogRef = this._dialog.open(VerifyEmailDialogComponent, {
                        width: getConfirmPopupWidthXL(),
                        data: this.emailForm.value,
                        disableClose: true,
                    });

                    dialogRef.afterClosed().subscribe(result => {

                        this.isSendOtp = true;

                        this._userService.getUserDetails().then((userDetails) => {
                            this.store.dispatch(userActions.setUserDetails({ userDetails }));
                        });

                        if (result) {
                            this.isVerified = true;
                        }
                        this.setEmailVerificationStatus.emit(result);
                    });
                } else {
                    this._notification.showNotification(response.respMessage, 'danger');
                }
            } else {
                this.loading = false;
                this.isSendOtp = false;
                this.isVerified = false;
            }
        } catch (error) {
            this.loading = false;
            this.isVerified = false;
        }
    }
}
