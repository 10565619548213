import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';

const routes: Routes = [
    { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
    {
        path: '',
        component: AppComponent,
        children: [
            { path: '', loadChildren: async () => import('../app/account/account.module').then(m => m.AccountModule) },
            { path: 'home', loadChildren: async () => import('../app/home/home.module').then(m => m.HomeModule) },
        ],
    },

    {
        path: '',
        loadChildren: async () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        data:{breadcrumb:{alias:"Dashboard"}}
    },
    {
        path: '',
        loadChildren: async () => import('./profile/profile.module').then(m => m.ProfileModule),
    },
    {
        path: '',
        loadChildren: async () => import('./portfolio/portfolio.module').then(m => m.PorfolioModule),
    },

    {
        path: 'dashboard/funeral-insurance',
        loadChildren: async () =>
            import('./funeral/funeral-insurance/funeral-insurance.module').then(m => m.FuneralInsuranceModule),
    },
    {
        path: 'dashboard/funeral-insurance/members',
        loadChildren: async () => import('./funeral/funeral.module').then(m => m.FuneralModule),
    },
    {
        path: 'dashboard/vehicle-insurance',
        loadChildren: async () =>
            import('./vehicle/vehicle-insurance/vehicle-insurance.module').then(m => m.VehicleInsuranceModule),
    },
    {
      path: 'dashboard/vehicle-insurance/vehicles',
      loadChildren: async () => import('./vehicle/vehicle.module').then(m => m.VehicleModule),
    },

    {
        path: 'dashboard/ticket-support',
        loadChildren: async () => import('./ticket-support/ticket-support.module').then(m => m.TicketSupportModule),
      },

     {
         path: 'claims',
         loadChildren: async () => import('./claims/claims.module').then(m => m.ClaimsModule),
     },
     {
        path: 'glossary',
        loadChildren: async () => import('./glossary/glossary.module').then(m => m.GlossaryModule),
    },

    {
        path: 'ambition',
        loadChildren: async () => import('./ambition/ambition.module').then(m => m.AmbitionModule),
    },

    {
        path: 'advice',
        loadChildren: async () => import('./motor-advice/motor-advice.module').then(m => m.MotorAdviceModule),
    },


   
    {
        path: 'faq',
        loadChildren: async () => import('./faq/faq.module').then(m => m.FaqModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
