import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GenderEnum } from 'src/app/core/enums/gender.enum';
import { ILoveDataRetrieval } from 'src/app/core/models/lov-data.retrieval';
import { IReferenceData } from 'src/app/core/models/reference-data';
import { LovDataService } from 'src/app/core/services/lov-data.service';
import { LovedOnesService } from 'src/app/core/services/loved-ones.service';
import { RelatedPartyService } from 'src/app/core/services/related-party.service';
import { GenderValidator } from 'src/app/core/validators/gender-validator';
import { IdNumberValidator } from 'src/app/core/_helpers/idnumber.validator';
import { Notifications } from 'src/app/shared/notifications.component';
import { FieldErrorDisplayComponent } from '../components/field-error-display/field-error-display.component';
import { MobileNumberValidator } from 'src/app/core/validators/mobile-number-validator';
import { Store } from '@ngrx/store';
import { deleteRelatedParty } from 'src/app/ngrx/actions/funeral-quote.action';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/core/services/app.service';

@Component({
    selector: 'app-form-member',
    templateUrl: './form-member.component.html',
    styleUrls: ['./form-member.component.scss'],
})
export class FormMemberComponent implements OnInit {
    form: FormGroup;
    loading: boolean = false;
    public lovedOne = JSON.parse(localStorage.getItem('lovedOne'));
    public lovData: Array<ILoveDataRetrieval> = [];
    mobileView = false;
    mobileViewSub: Subscription;
    public dependantTypes: Array<string> = [];

    public controlState: any = 'Add';

    public isCancelClicked = false;
    public formIsValid: boolean;
    public maxDate = new Date();
    public minDate = new Date(1900, 1, 1);

    public date = new Date(this.maxDate.getFullYear(), 0, 1);

    public relations: Array<IReferenceData> = [];
    public titles: Array<IReferenceData> = [];
    public genders: Array<IReferenceData> = [];

    _IdNumberRegex = this._idNumberValidator.IdNumberRegex;
    isValidSAID = false;
    isIdNumberTouched = false;

    public constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<FormMemberComponent>,
        private readonly _lovedOnesService: LovedOnesService,
        private readonly _notification: Notifications,
        private readonly _lovDataService: LovDataService,
        private readonly error: FieldErrorDisplayComponent,
        private readonly _idNumberValidator: IdNumberValidator,
        private readonly _relatedPartyService: RelatedPartyService,
        private store: Store,
        private readonly _appService: AppService
    ) {
        this.mobileViewSub = this._appService.mobileView$.subscribe(result => {
            this.mobileView = result;
        });
    }

    // public onchange(event: any): void {
    //     this.form.patchValue({
    //         relation: event.value,
    //     });
    // }

    onBlur(field: any) {
        // if (field === 'nationalIdNumber') this.isIdNumberTouched = true;
        let val = this.form.get(field).value;
        this.isIdNumberTouched = field === 'nationalIdNumber' && val && val !== '';
    }

    onIdNumberCapture() {
        let value = this.form.value.nationalIdNumber;
        let result = this._idNumberValidator.validate(value);
        this.isValidSAID = result.valid;
        this.updateGenderControl(result.gender);
        this.updateDoBControl(result.dateOfBirth);
    }

    private updateGenderControl(value: any) {
        this.form.patchValue({
            gender: value,
        });
        this.form.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    }

    private updateDoBControl(value: any) {
        this.form.patchValue({
            dateOfBirth: value,
        });
        this.form.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    }

    public async ngOnInit(): Promise<void> {
        this.isCancelClicked = false;
        this.relations = await this.getReferenceData('relations');
        this.titles = await this.getReferenceData('titles');
        this.genders = await this.getReferenceData('genders');

        this.form = this.formBuilder.group({
            dependantId: '',
            title: [null],
            firstName: [null, Validators.required],
            lastName: [null, Validators.required],
            phoneNumber: [null, Validators.required],
            dateOfBirth: [null, Validators.required],
            email: [null],
            gender: [null, Validators.required],
            nationalIdNumber: null,
            passportNumber: null,
            dependantTypes: null,
            startDate: [null],
            expiryDate: [null],
            relation: [null, Validators.required],
        });

        if (this.lovedOne !== null) {
            this.controlState = 'Update';

            if (this.lovedOne?.dependantTypes) {
                const dependantTypes = this.lovedOne?.dependantTypes.indexOf('DRIVER');
                if (dependantTypes !== -1) {
                    this.dependantTypes = this.lovedOne?.dependantTypes;
                } else {
                    this.dependantTypes.push('LOVEDONE');
                }
            }

            this.form.patchValue({
                dependantId: this.lovedOne.dependantId,
                title: this.lovedOne.title,
                firstName: this.lovedOne.firstName,
                lastName: this.lovedOne.lastName,
                phoneNumber: this.lovedOne.phoneNumber,
                dateOfBirth: new Date(this.lovedOne.dateOfBirth),
                email: this.lovedOne.email,
                gender: this.lovedOne.gender,
                dependantTypes: this.dependantTypes,
                nationalIdNumber: this.lovedOne.nationalIdNumber,
                passportNumber: this.lovedOne.passportNumber,
                relation: this.lovedOne.relation,
                startDate: new Date(this.lovedOne.startDate),
                expiryDate: new Date(this.lovedOne.expiryDate),
            });

            this.form.get('relation').markAsTouched();
        }

        this.form.valueChanges.subscribe((selectedValue: any) => {
            this.validateForm();
        });
    }

    public async getReferenceData(type: string): Promise<Array<IReferenceData>> {
        return this._lovDataService.getReferenceData(type);
    }

    public close() {
        this.isCancelClicked = true;
        this.dialogRef.close();
    }

    fieldIsNotValid(form: FormGroup, field: string) {
        // var isFieldNotValid;
        // if (form.get(field).touched) {
        //     isFieldNotValid = !form.get(field).valid || form.get(field).value === undefined || form.get(field).value === null;
        // } else {
        //     isFieldNotValid = false;
        // }
        // return isFieldNotValid;

        const isTouched = form.get(field).touched;
        let isNotValid = !form.get(field).valid && (this.controlState === 'Add' ? isTouched : true);

        let val = form.get(field).value;
        if (field === 'phoneNumber' && val && isTouched) {
            isNotValid = !MobileNumberValidator.isValidSAMobileNumber(val);
        }

        return isNotValid;
    }

    fieldIsValid(field: string): boolean {
        var valid = this.form.get(field).touched;
        if (!valid) return false;
        valid = valid && this.form.get(field).valid;
        return valid;
    }

    isValidGender(form: FormGroup, field: string) {
        var returnVal;
        // const gender = form.get('gender').value;
        // const fieldValue = form.get(field).value;

        // if (field === 'relation') {
        //     const rGender = GenderValidator.getGenderOfRelationship(fieldValue);
        //     returnVal = (gender !== rGender && gender !== null && rGender !== null && gender !== GenderEnum.Others);
        // }
        // else if (field === 'title') {
        //     const tGender = GenderValidator.getGenderOfTitle(fieldValue);
        //     returnVal = (gender !== tGender && gender !== null && tGender !== null && gender !== GenderEnum.Others);
        // }

        return returnVal;
    }

    validateForm() {
        const mandatoryFields = [
            'firstName',
            'lastName',
            'phoneNumber',
            'dateOfBirth',
            // 'email',
            'gender',
            // 'nationalIdNumber',
            'relation',
        ];
        this.formIsValid = true;
        mandatoryFields?.forEach(field => {
            const value = this.form.get(field).value;
            this.formIsValid = this.formIsValid && value !== null && value !== undefined;
        });
    }

    public async doneFormMember(): Promise<void> {
        this.loading = true;
        this.validateForm();
        if (!this.isCancelClicked && (this.form.valid || this.formIsValid)) {
            let response;
            try {
                if (this.lovedOne !== null) {
                    response = await this._relatedPartyService.updateUserRelatedParty(this.form.value);

                    this.store.dispatch(deleteRelatedParty({ id: this.form.value.dependantId }));

                    if (response.respCode === HttpStatusCode.Ok || response.respCode === HttpStatusCode.Accepted) {
                        this._notification.showNotification('Loved one has been updated successfully.', 'success');
                        localStorage.removeItem('lovedOne');
                    } else {
                        this._notification.showNotification(
                            'Loved one could not be updated due to a ' + response.respMessage + ' ' + response.errorMessage,
                            'danger'
                        );
                    }
                    this.loading = false;
                    localStorage.removeItem('lovedOne');
                    this.dialogRef.close(true);
                } else {
                    const dependantTypes = this.dependantTypes.indexOf('LOVEDONE');
                    if (dependantTypes !== -1) {
                        this.form.patchValue({
                            dependantTypes: this.dependantTypes,
                        });
                    } else {
                        this.dependantTypes.push('LOVEDONE');
                        this.form.patchValue({
                            dependantTypes: this.dependantTypes,
                        });
                    }

                    response = await this._relatedPartyService.addUserRelatedParty(this.form.value);

                    if (response.respCode === HttpStatusCode.Ok || response.respCode === HttpStatusCode.Accepted) {
                        this._notification.showNotification('Loved one has been added successfully.', 'success');
                        localStorage.removeItem('lovedOne');
                    } else {
                        this._notification.showNotification(
                            'Loved one could not be added due to a ' + response.respMessage + ' ' + response.errorMessage,
                            'danger'
                        );
                    }
                    this.loading = false;
                    localStorage.removeItem('lovedOne');
                    this.dialogRef.close(true);
                }
            } catch (error) {
                this.loading = false;

                this.dialogRef.close(false);
            }
        } else {
            this.loading = false;
            // this._notification.showNotification("Related person's data not saved", 'info');
            this.loading = false;
            this.dialogRef.close(false);
            return;
        }
    }
}
