import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { BankActionType } from 'src/app/core/models/bank-detail.action.type';
import { IReferenceData } from 'src/app/core/models/reference-data';
import { IUserBanks } from 'src/app/core/models/user-banks';
import { LovDataService } from 'src/app/core/services/lov-data.service';
import { UserService } from 'src/app/core/services/user.service';
import { Notifications } from '../notifications.component';

@Component({
    selector: 'app-bank-form',
    templateUrl: './bank-form.component.html',
    styleUrls: ['./bank-form.component.scss'],
})
export class BankFormComponent implements OnInit {
    public userbanks: Array<IUserBanks> = [];
    form: FormGroup;
    loading: boolean = false;
    public banks: Array<IReferenceData> = [];
    canSave: boolean = false;
    userDetail:any;
    @Output() setPaymentDetail = new EventEmitter();

    @Input()
    public paymentDetailSelected: any;

    @Input()
    public banksInfo: any;

    isAddingState: boolean = true;
    debitOrderDaySelected: number;
    selectedBankDetail: any;

    public constructor(
        public _dialog: MatDialog,
        private readonly _userService: UserService,
        private readonly _notification: Notifications,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly _lovDataService: LovDataService,
        private store: Store<AppState>
    ) {
        this.store
          .select((state: any) => state?.user)
            .subscribe(data => {
         this.userDetail = data?.userDetails;
})
    }

    public days: Array<number> = [];

    public async ngOnInit(): Promise<void> {

        let accountHolderName = this.userDetail?.profileDetails.firstName +' '+this.userDetail?.profileDetails.lastName

        this.form = this.formBuilder.group({
            id: '',
            accountHolderName: [accountHolderName, Validators.required],
            bankName: [null, Validators.required],
            branchName: [null, Validators.required],
            branchCode: [null, Validators.required],
            // accountType: '',
            accountNumber: [null, Validators.required],
            debitOrderDay: [null, Validators.required],
            // ifscCode: '',
        });

        this.banks = await this.getReferenceData('banks');

        for (let index = 1; index <= 31; index++) {
            this.days.push(index);
        }

        this.userbanks = await this.getUserBanks();

        setTimeout(async () => {
            if (this.paymentDetailSelected) {
                this.debitOrderDaySelected = this.paymentDetailSelected.value.debitOrderDay;
                this.updateFormView(this.paymentDetailSelected.value.bankDetail);
            }
        }, 1000);

        if (this.data.formData !== undefined) {
            this.updateFormView(this.data.formData);
        }

        this.form.valueChanges.subscribe((selectedValue: any) => {
            this.isThisFormValid();
        });
    }

    isThisFormValid(): boolean {
        let isValid = true;
        const f = this.form.valid;

        const fields = ['accountHolderName', 'bankName', 'branchName', 'branchCode', 'accountNumber'];
        this.canSave = true;
        fields?.forEach(x => {
            const fieldValid = !this.isFieldValid(this.form, x);
            const value = this.form.get(x).value;
            isValid = isValid && fieldValid;
            this.canSave = this.canSave && value !== null && value !== undefined && value !== '';
          
        });

        return isValid;
    }

    public setDebitOrderDay() {

        if (this.canSave) {
            this.store.dispatch({
                type: BankActionType.ADD_BANK,
                payload: {
                    value: {
                        isSet: true,
                        debitOrderDay: this.debitOrderDaySelected,
                        bankDetail: this.selectedBankDetail,
                    },
                },
            });
        } else {
            this.store.dispatch({
                type: BankActionType.ADD_BANK,
                payload: {
                    value: {
                        isSet: false,
                        debitOrderDay: this.debitOrderDaySelected,
                        bankDetail: this.selectedBankDetail,
                    },
                },
            });
        }
    }

    public cancel(): void {
        this._dialog.closeAll();
    }

    public isSelected(model: any): boolean {
        return (
            model !== null &&
            model !== undefined &&
            this.selectedBankDetail !== null &&
            this.selectedBankDetail !== undefined &&
            model.accountHolderName === this.selectedBankDetail.accountHolderName &&
            model.bankName === this.selectedBankDetail.bankName &&
            model.accountNumber === this.selectedBankDetail.accountNumber
        );
    }

    public onDebitOrderDayChange(event: any) {
        this.debitOrderDaySelected = event.value;

        const model = {
            value: {
                isSet: true,
                debitOrderDay: this.debitOrderDaySelected,
                bankDetail: this.selectedBankDetail,
            },
        };

        this.store.dispatch({
            type: BankActionType.ADD_BANK,
            payload: model,
        });
    }

    public onBankInfoChange(model: any): void {
        this.isAddingState = false;
        this.updateFormView(model);
        if (this.canSave) {
            this.setPaymentDetail.emit({
                value: {
                    isSet: true,
                    debitOrderDay: this.debitOrderDaySelected,
                    bankDetail: this.selectedBankDetail,
                },
            });
        } else {
            this.setPaymentDetail.emit({
                value: {
                    isSet: false,
                    debitOrderDay: this.debitOrderDaySelected,
                    bankDetail: this.selectedBankDetail,
                },
            });
        }
    }

    public updateFormView(paymentData?: any) {
        this.selectedBankDetail = paymentData;
        if (paymentData !== undefined) {
            this.isAddingState = false;

            // paymentData.debitOrderDay = 0; //Number(this.debitOrderDaySelected);

            this.form.patchValue({
                id: paymentData.id,
                accountHolderName: paymentData.accountHolderName,
                bankName: paymentData.bankName,
                branchName: paymentData.branchName,
                branchCode: paymentData.branchCode,
                accountNumber: paymentData.accountNumber,
                debitOrderDay: Number(this.debitOrderDaySelected) === 0 ? null : Number(this.debitOrderDaySelected), //paymentData.debitOrderDay,
            });
        } else {
            this.form.reset();
            this.debitOrderDaySelected = null;
            this.isAddingState = true;
            this.setDebitOrderDay();
        }

        this.isValidToSave();
    }

    isValidToSave() {
        this.canSave = this.form.valid && this.selectedBankDetail !== undefined && isNaN(this.debitOrderDaySelected) === false;
    }

    public async saveBankDetails(): Promise<void> {

        try {
            if (this.canSave) {
                this.loading = true;
                this.debitOrderDaySelected = this.form.value.debitOrderDay;
                this.selectedBankDetail = this.form.value;

                var response;
                if (this.data.formData ==  undefined) {
                    response = await this._userService.addUserBanks(this.form.value);
                } else {
                    response = await this._userService.updateUserBanks(this.form.value);
                }

                if (response.respCode === HttpStatusCode.Accepted || response.respCode === HttpStatusCode.Ok) {
                    // this.userbanks = await this.getUserBanks();
                    this._dialog.closeAll();
                    this._notification.showNotification('Bank details successfully saved', 'success');

                    // localStorage.setItem('thisSelectedBankDetail', this.form.value);
                    // localStorage.setItem('debitOrderDaySelected', this.debitOrderDaySelected + '');
                    this.onBankInfoChange(this.form.value);
                } else {
                    this._notification.showNotification(response.respMessage, 'danger');
                    // localStorage.removeItem('thisSelectedBankDetail');
                }
                this.loading = false;
            }
        } catch (error) {
            this.loading = false;
        } finally {
            this.loading = false;
        }
    }

    isFieldValid(form: FormGroup, field: string) {
        try {
            if (form !== null || form.get(field) !== null) {
                var isFieldNotValid;
                if (form.get(field).touched) {
                    isFieldNotValid =
                        !form.get(field).valid || form.get(field).value === undefined || form.get(field).value === null;
                } else {
                    isFieldNotValid = false;
                }
                return isFieldNotValid;
            }
        } catch (error) {
        }

        return false;
    }

    public onchangeBankName(event: any): void {
        let bank = this.banks.find(x => {
            return x.name === event.value;
        });
        this.form.patchValue({
            branchCode: bank.value,
            branchName: 'Universal branch',
        });
    }

    public async getUserBanks(): Promise<Array<IUserBanks>> {
        return this._userService.getUserBanks();
    }

    accounttypes = [
        { value: 'Savings', viewValue: 'Savings' },
        { value: 'Cheque', viewValue: 'Cheque' },
    ];

    public async getReferenceData(type: string): Promise<Array<IReferenceData>> {
        return this._lovDataService.getReferenceData(type);
    }
}
