<div *ngIf="data.messageType === 'Onboarding'">
    <h3>{{ data.title }}</h3>
    <div mat-dialog-content>
        <p class="dialog-message" [innerHTML]="data.message"></p>
    </div>
    <div class="row" *ngIf="!positiveConfirm">
        <div class="col-md-6">
            <button (click)="dialogRef.close(false)" tabindex="0" mat-raised-button class="btn btn-primary btn-round">
                {{ data.noText }}
            </button>
        </div>
        <div class="col-md-6">
            <button (click)="dialogRef.close(true)" tabindex="-1" mat-raised-button class="btn btn-primary btn-round">
                {{ data.confirmText }}
            </button>
        </div>
    </div>

    <div class="row" *ngIf="positiveConfirm">
        <div class="col-md-6 col-6 hyperLink">
            <a href="javascript:void(0);" (click)="dialogRef.close(false)" class="fr" tabindex="-1">
                {{ data.noText }}
            </a>
        </div>
        <div class="col-md-6 col-6">
            <button (click)="dialogRef.close(true)" tabindex="0" mat-raised-button class="btn btn-primary btn-round mr-1">
                {{ data.confirmText }}
            </button>
        </div>
    </div>
</div>

<div class="container" *ngIf="data.messageType !== 'Onboarding'">
    <h3>{{ data.title }}</h3>
    <div mat-dialog-content>
        <p class="dialog-message" [innerHTML]="data.message"></p>
    </div>
    <div *ngIf="!positiveConfirm" class="row" style="text-align: center">
        <div class="col-md-6">
            <button (click)="dialogRef.close(false)" tabindex="0" mat-raised-button class="btn btn-message btn-primary btn-round">
                {{ data.noText }}
            </button>
        </div>
        <div class="col-md-6">
            <button (click)="dialogRef.close(true)" tabindex="-1" mat-raised-button class="btn btn-message btn-primary btn-round">
                {{ data.confirmText }}
            </button>
        </div>
    </div>

    <div style="text-align: center" class="row" *ngIf="positiveConfirm">
        <div class="col-md-6">
            <button (click)="dialogRef.close(true)" tabindex="0" mat-raised-button class="btn btn-primary btn-message btn-round">
                <span style="margin-left: -9px"> {{ data.confirmText }}</span>
            </button>
        </div>
        <div class="col-md-6">
            <button
                (click)="dialogRef.close(false)"
                tabindex="-1"
                mat-raised-button
                class="btn btn-width btn-primary btn-round"
            >
                {{ data.noText }}
            </button>
        </div>
    </div>
</div>
