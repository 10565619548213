<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-modal">
                    <div class="card-header card-header-rose card-header-icon">
                        <!-- <div class="card-icon">
                            <mat-icon>group_add</mat-icon>
                        </div> -->
                        <h4 class="card-title">{{ controlState }} Beneficiary</h4>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="form" *ngIf="form" (ngSubmit)="done()">
                            <h5 class="divider-text">Personal details</h5>
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field>
                                        <mat-select formControlName="relation" placeholder="Relationship" required="true">
                                            <mat-option *ngFor="let relation of relations" value="{{ relation.value }}">
                                                {{ relation.name }}</mat-option
                                            >
                                        </mat-select>
                                        <app-field-error-display
                                            [displayError]="isFieldValid(form, 'relation')"
                                            errorMsg="Relationship is required"
                                        >
                                        </app-field-error-display>
                                        <app-field-error-display
                                            [displayError]="isValidGender(form, 'relation')"
                                            errorMsg="The relation type selected does not match the selected gender"
                                        >
                                        </app-field-error-display>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field>
                                        <mat-select formControlName="title" class="example-full-width"
                                            placeholder="Title" name="model" tabindex="1">
                                            <mat-option *ngFor="let title of titles" [value]="title.value">
                                                {{ title.name }}
                                            </mat-option>
                                        </mat-select>
                                        <!-- <app-field-error-display [displayError]="isFieldValid(form, 'title')"
                                            errorMsg="title is required">
                                        </app-field-error-display> -->

                                        <app-field-error-display [displayError]="isValidGender(form, 'title')"
                                            errorMsg="The title selected does not match the selected gender">
                                        </app-field-error-display>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <input matInput formControlName="firstName" placeholder="First name" type="text"
                                            required="true" tabindex="2" />
                                        <app-field-error-display [displayError]="isFieldValid(form, 'firstName')"
                                            errorMsg="First name is required">
                                        </app-field-error-display>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <input matInput formControlName="lastName" placeholder="Surname" type="text"
                                            required="true" tabindex="3" />
                                        <app-field-error-display [displayError]="isFieldValid(form, 'lastName')"
                                            errorMsg="Surname is required">
                                        </app-field-error-display>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <input formControlName="nationalIdNumber" matInput
                                            placeholder="SA identity number"  type="text"
                                            (keyup)="onIdNumberCapture()" (blur)="onBlur('nationalIdNumber')"
                                            minlength="13" maxlength="13" tabindex="4" />
                                        <!-- <app-field-error-display [displayError]="isFieldValid(form, 'nationalIdNumber')"
                                            errorMsg="SA id number is required">
                                        </app-field-error-display> -->
                                        <app-field-error-display [control]="form.get('nationalIdNumber')"
                                            [displayError]="isFieldValid(form, 'nationalIdNumber')"
                                            [submitting]="saving" label="nationalIdNumber">
                                        </app-field-error-display>
                                    </mat-form-field>

                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <input matInput formControlName="passportNumber" placeholder="Passport number"
                                            type="text" tabindex="5" />
                                        <!-- <app-field-error-display [displayError]="isFieldValid(form, 'passportNumber')"
                                            errorMsg="passportNumber is required">
                                        </app-field-error-display> -->
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">

                                    <mat-form-field>
                                        <mat-select formControlName="gender" class="example-full-width"
                                            placeholder="Gender" name="model" tabindex="6" required="true">
                                            <mat-option *ngFor="let gender of genders" [value]="gender.value">
                                                {{ gender.name }}
                                            </mat-option>
                                        </mat-select>
                                        <app-field-error-display [displayError]="isFieldValid(form, 'gender')"
                                            errorMsg="gender is required">
                                        </app-field-error-display>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">

                                    <mat-form-field class="example-full-width">
                                        <input matInput [matDatepicker]="dateOfBirth" formControlName="dateOfBirth"
                                            placeholder="Date of birth" required="true" (click)="dateOfBirth.open()"
                                            tabindex="7" [value]="date" [min]="minDate" [max]="maxDate" />
                                        <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
                                        <mat-datepicker #dateOfBirth [startAt]="date"></mat-datepicker>
                                        <app-field-error-display [displayError]="isFieldValid(form, 'dateOfBirth')"
                                            errorMsg="Date of birth is required">
                                        </app-field-error-display>
                                    </mat-form-field>

                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <input matInput formControlName="phoneNumber"  required="true" placeholder="Mobile number"
                                             type="number" tabindex="8" maxlength="10" min="0" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                                        <app-field-error-display [displayError]="isFieldValid(form, 'phoneNumber')"
                                            errorMsg="SA Phone number is required (10 digits only)">
                                        </app-field-error-display>
                                    </mat-form-field>

                                </div>
                                <div class="col-md-4">

                                    <mat-form-field class="example-full-width">
                                        <input formControlName="email" matInput placeholder="Email address" type="text"
                                            tabindex="9" />
                                        <!-- <app-field-error-display [displayError]="isFieldValid(form, 'email')"
                                            errorMsg="Email Address is required">
                                        </app-field-error-display> -->
                                    </mat-form-field>

                                </div>
                            </div>
                            <br>
                            <br>
                            <h5 class="divider-text">Bank details</h5>
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field>
                                        <mat-select formControlName="bankName" class="example-full-width"
                                            (selectionChange)="onchangeBankName($event)" placeholder="Bank name"
                                            name="model" tabindex="10">
                                            <mat-option *ngFor="let bank of banks" [value]="bank.name">
                                                {{ bank.name }}
                                            </mat-option>
                                        </mat-select>
                                        <!-- <app-field-error-display [displayError]="isFieldValid(form, 'bankName')"
                                            errorMsg="Bank name is required">
                                        </app-field-error-display> -->
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field>
                                        <mat-select formControlName="accountType" class="example-full-width"
                                            placeholder="Account type" name="model" tabindex="13">
                                            <mat-option *ngFor="let accounttype of accounttypes"
                                                [value]="accounttype.value">
                                                {{ accounttype.viewValue }}
                                            </mat-option>
                                            <!-- <app-field-error-display [displayError]="isFieldValid(form, 'accountType')"
                                                errorMsg="Account type is required">
                                            </app-field-error-display> -->
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <input formControlName="accountHolderName" matInput
                                            placeholder="Account holder's name" type="text" tabindex="11" />
                                        <!-- <app-field-error-display
                                            [displayError]="isFieldValid(form, 'accountHolderName')"
                                            errorMsg="Account holder name is required">
                                        </app-field-error-display> -->
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <input formControlName="branchName" matInput placeholder="Branch name"
                                            type="text" tabindex="14" />
                                        <!-- <app-field-error-display [displayError]="isFieldValid(form, 'branchName')"
                                            errorMsg="Branch name is required">
                                        </app-field-error-display> -->
                                    </mat-form-field>
                                </div>

                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <input formControlName="accountNumber" matInput placeholder="Account number"
                                            type="number" tabindex="12" />
                                        <!-- <app-field-error-display [displayError]="isFieldValid(form, 'accountNumber')"
                                            errorMsg="Account number is required">
                                        </app-field-error-display> -->
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">

                                    <mat-form-field class="example-full-width">
                                        <input formControlName="branchCode" disabled="true" matInput
                                            placeholder="Branch code" type="text" tabindex="15" />
                                        <!-- <app-field-error-display [displayError]="isFieldValid(form, 'branchCode')"
                                            errorMsg="Branch code is required">
                                        </app-field-error-display> -->
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="card-footer" style="margin-right: -59px">
                                <div class="ml-auto">
                                    <button mat-raised-button type="submit" [disabled]="!form.valid" (click)="dialogRef.close(false)"
                                        class="btn btn-primary btn-round" tabindex="16">
                                        Done &nbsp;
                                        <mat-icon *ngIf="loading">
                                            <mat-spinner diameter="20"> </mat-spinner>
                                        </mat-icon>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>