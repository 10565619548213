import { Action } from '@ngrx/store';
import { VehicleActionType } from '../core/models/vehicle.action.type';

export function addVehicleReducer(state: any, action: any) {
    switch (action.type) {
        case VehicleActionType.ADD_VEHICLE:
            return action.payload;
        default:
            return state;
    }
}
