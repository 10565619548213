import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IDocument } from '../models/document';
import { IDocumentResponse } from '../models/documentResponse';
import { IUserDocument } from '../models/userDocument';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  public constructor(private readonly _http: HttpClient) { }


  public async updateDocument(document: IDocument): Promise<IDocumentResponse> {

    let userDetails = JSON.parse(localStorage.getItem('user-details'));
    var formdata = new FormData();
    formdata.append("file", document.file, document.file.name);
    formdata.append("fileName", document.fileName);
    formdata.append("docName", document.docName);
    formdata.append("docType", document.docType);
    formdata.append("userId", userDetails.userId);

    try {
      const response = await this._http
        .post<IDocumentResponse>(`${environment.serverApiUrl}documents/v1/document/doc-upload-request`, formdata)
        .toPromise();

      return response;
    } catch (error) {
      if (error && error instanceof ProgressEvent) {
        throw new Error('A connection could not be established. Please contact an administrator.');
      }
      throw Error();
    }
  }

  public async getUserProfilePicture(document: any): Promise<IUserDocument> {
    var response;
    try {
      if (document.docTypes[0] === 'PROFILE_PICTURE' && localStorage.getItem('PROFILE_PICTURE') !== null) {
        response = JSON.parse(localStorage.getItem('PROFILE_PICTURE'));
      }
      else {
        const headers = {
          'Content-Type': 'application/json'
        }
        const requestOptions = {
          headers: new HttpHeaders(headers),
        };

        response = await this._http
          .post<IUserDocument>(`${environment.serverApiUrl}documents/v1/document/get-user-documents`, document, requestOptions).toPromise();

        localStorage.setItem('PROFILE_PICTURE', JSON.stringify(response));
      }

      return response;
    } catch (error) {

      if (error && error instanceof ProgressEvent) {
        throw new Error('A connection could not be established. Please contact an administrator.');
      }
      throw Error();
    }
  }

  public async getDocument(document: any): Promise<IUserDocument> {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
  
      const requestOptions = {
        headers: headers
      };
  
      const response = await this._http
        .post<IUserDocument>(
          `${environment.serverApiUrl}documents/v1/document/get-user-documents`,
          document,
          requestOptions
        )
        .toPromise();
  
      return response;
    } catch (error) {
      if (error instanceof ProgressEvent) {
        throw new Error('A connection could not be established. Please contact an administrator.');
      }
      throw error;
    }
  }

  

  public async downloadDocument(token: string): Promise<any> {
    var response: any;

    try {
      var raw = JSON.stringify(token);

      if (localStorage.getItem(raw) !== null) {
        response = JSON.parse(localStorage.getItem(raw));
      }
      else {
        
        const headers = {
          'Content-Type': 'application/json'
        }

        const requestOptions = {
          headers: new HttpHeaders(headers),
        };

        response = await this._http
          .post<any>(`${environment.serverApiUrl}documents/v1/document/doc-download-request`, raw, requestOptions)
          .toPromise();

        localStorage.setItem(raw, JSON.stringify(response));
      }

      return response;
    } catch (error) {
      if (error && error instanceof Error) {
        throw new Error('A connection could not be established. Please contact an administrator.');
      }
      throw Error();
    }
  }

  public async deleteDocument(token: string): Promise<any> {
    var raw = JSON.stringify(token);

    try {

      const headers = {
        'Content-Type': 'application/json'
      }

      const requestOptions = {
        headers: new HttpHeaders(headers),
      };

      const response = await this._http
        .post<any>(`${environment.serverApiUrl}documents/v1/document/doc-delete-request`, raw, requestOptions)
        .toPromise();

      return response;
    } catch (error) {
      if (error && error instanceof Error) {
        throw new Error('A connection could not be established. Please contact an administrator.');
      }
      throw Error();
    }
  }
}


