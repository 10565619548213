import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DialogEvents } from 'src/app/core/enums/dialog-events.enum';
import { IReferenceData } from 'src/app/core/models/reference-data';
import { IUserBanks } from 'src/app/core/models/user-banks';
import { AppService } from 'src/app/core/services/app.service';
import { LovDataService } from 'src/app/core/services/lov-data.service';
import { UserService } from 'src/app/core/services/user.service';
import { getCapturePopupWidth, getCapturePopupWidthM } from 'src/app/core/_helpers/responsive-helper';
import { BankFormComponent } from '../bank-form/bank-form.component';
import { DialogModalComponent } from '../dialog-modal/dialog-modal.component';
import { Notifications } from '../notifications.component';

@Component({
    selector: 'app-payment-detail',
    templateUrl: './payment-detail.component.html',
    styleUrls: ['./payment-detail.component.scss'],
})
export class PaymentDetailComponent implements OnInit, OnChanges {
    form: FormGroup;
    loading: boolean = false;
    isAccountSecled: boolean = false;
    public banks: Array<IReferenceData> = [];
    canSave: boolean = false;
    @Input()
    public userbanks: Array<IUserBanks> = [];

    @Output() setPaymentDetail = new EventEmitter();

    @Input()
    public paymentDetailSelected: any;

    @Input()
    public banksInfo: any;

    isAddingState: boolean = true;
    debitOrderDaySelected: number;
    selectedBankDetail: any;

    mobileView = false;
    mobileViewSub: Subscription;

    public constructor(
        public _dialog: MatDialog,
        private readonly _userService: UserService,
        private readonly _notification: Notifications,
        private formBuilder: FormBuilder,
        private readonly _lovDataService: LovDataService,
        private readonly _appService: AppService
    ) {
      this.mobileViewSub = this._appService.mobileView$.subscribe(result => {
        this.mobileView = result;
      });
    }

    public days: Array<number> = [];

    ngOnChanges(event: any) {

        // if (this.userbanks !== undefined && this.userbanks !== null && this.userbanks.length !== 0) {
        //     this.isAccountSecled = true;
        // }
    }

    public async ngOnInit(): Promise<void> {
        this.form = this.formBuilder.group({
            id: '',
            accountHolderName: [null, Validators.required],
            bankName: [null, Validators.required],
            branchName: [null, Validators.required],
            branchCode: [null, Validators.required],
            // accountType: '',
            accountNumber: [null, Validators.required],
            debitOrderDay: [null, Validators.required],
            // ifscCode: '',
        });

        this.banks = await this.getReferenceData('banks');

        for (let index = 1; index <= 31; index++) {
            this.days.push(index);
        }

        this.userbanks = await this.getUserBanks();

        setTimeout(async () => {
            if (this.paymentDetailSelected) {
                this.debitOrderDaySelected = this.paymentDetailSelected.value.debitOrderDay;
                this.updateFormView(this.paymentDetailSelected.value.bankDetail);
            }
        }, 1000);

        this.form.valueChanges.subscribe((selectedValue: any) => {
            this.isThisFormValid();
        });
    }

    isThisFormValid(): boolean {
        let isValid = true;
        const f = this.form.valid;

        const fields = ['accountHolderName', 'bankName', 'branchName', 'branchCode', 'accountNumber', 'debitOrderDay'];
        this.canSave = true;
        fields?.forEach(x => {
            const fieldValid = !this.isFieldValid(this.form, x);
            const value = this.form.get(x).value;
            isValid = isValid && fieldValid;
            this.canSave = this.canSave && value !== null && value !== undefined && value !== '';
        });

        return isValid;
    }

    public setDebitOrderDay() {
        if (this.canSave) {
            this.setPaymentDetail.emit({
                value: {
                    isSet: true,
                    debitOrderDay: this.debitOrderDaySelected,
                    bankDetail: this.selectedBankDetail,
                },
            });
        } else {
            this.setPaymentDetail.emit({
                value: {
                    isSet: false,
                    debitOrderDay: this.debitOrderDaySelected,
                    bankDetail: this.selectedBankDetail,
                },
            });
        }
    }

    public isSelected(model: any): boolean {
        return (
            model !== null &&
            model !== undefined &&
            this.selectedBankDetail !== null &&
            this.selectedBankDetail !== undefined &&
            model.accountHolderName === this.selectedBankDetail.accountHolderName &&
            model.bankName === this.selectedBankDetail.bankName &&
            model.accountNumber === this.selectedBankDetail.accountNumber
        );
    }

    public onDebitOrderDayChange(event: any) {
        this.debitOrderDaySelected = event.value;
        this.setDebitOrderDay();
    }

    public onBankInfoChange(model: any): void {
        this.isAddingState = false;
        // this.isAccountSecled = true;
        this.updateFormView(model);
        if (this.canSave) {
            this.setPaymentDetail.emit({
                value: {
                    isSet: true,
                    debitOrderDay: this.debitOrderDaySelected,
                    bankDetail: this.selectedBankDetail,
                },
            });
        } else {
            this.setPaymentDetail.emit({
                value: {
                    isSet: false,
                    debitOrderDay: this.debitOrderDaySelected,
                    bankDetail: this.selectedBankDetail,
                },
            });
        }
    }


    public openOptModalVehicle(model: any): void {
        const dialogRef = this._dialog.open(DialogModalComponent, {
            width: getCapturePopupWidthM(),
            data: {
                component: BankFormComponent,
                icon: "payments",
                formData: model
            },
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result !== DialogEvents.CLOSE) {
                this.userbanks = await this.getUserBanks();
            }
        });
    }

    public openOptAddBankModal(): void {
        const dialogRef = this._dialog.open(DialogModalComponent, {
            width: getCapturePopupWidth(),
            data: {
                component: BankFormComponent,
                icon: "payments"
            },
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result !== DialogEvents.CLOSE) {
                this.userbanks = await this.getUserBanks();
                   this.onBankInfoChange(this.form.value);

                let newUserBank : any;
                this.userbanks?.forEach((item: { id: any; }) => {

                    //use the newUserBank.id to determine the new UserBank. not the best of logic
                    if (!newUserBank || isNaN(newUserBank.id) || Number(item.id) > Number(newUserBank.id)) {
                        newUserBank = item;
                    }
                });

                //this.selectedBankDetail = newUserBank;
                this.updateFormView(newUserBank);
            }
        });
    }

    public updateFormView(paymentData?: any) {
        this.selectedBankDetail = paymentData;
        this.isAccountSecled = paymentData !== undefined;

        if (paymentData !== undefined) {
            this.isAddingState = false;

            // paymentData.debitOrderDay = 0; //Number(this.debitOrderDaySelected);

            this.form.patchValue({
                id: paymentData.id,
                accountHolderName: paymentData.accountHolderName,
                bankName: paymentData.bankName,
                branchName: paymentData.branchName,
                branchCode: paymentData.branchCode,
                accountNumber: paymentData.accountNumber,
                debitOrderDay: Number(this.debitOrderDaySelected) === 0 ? null : Number(this.debitOrderDaySelected), //paymentData.debitOrderDay,
            });

            this.form.get('debitOrderDay').markAsTouched();
        } else {
            this.form.reset();
            this.debitOrderDaySelected = null;
            this.isAddingState = true;
            this.setDebitOrderDay();
        }

        this.isValidToSave();
    }

    isValidToSave() {
        this.canSave = this.form.valid && this.selectedBankDetail !== undefined && isNaN(this.debitOrderDaySelected) === false;
    }

    public async saveBankDetails(): Promise<void> {
        try {
            if (this.canSave) {
                this.loading = true;
                this.debitOrderDaySelected = this.form.value.debitOrderDay;
                this.selectedBankDetail = this.form.value;

                var response;
                if (this.isAddingState) {
                    response = await this._userService.addUserBanks(this.form.value);
                } else {
                    response = await this._userService.updateUserBanks(this.form.value);
                }

                if (response.respCode === HttpStatusCode.Accepted || response.respCode === HttpStatusCode.Ok) {
                    this.userbanks = await this.getUserBanks();
                    this._notification.showNotification('Bank details successfully saved', 'success');

                    // localStorage.setItem('thisSelectedBankDetail', this.form.value);
                    // localStorage.setItem('debitOrderDaySelected', this.debitOrderDaySelected + '');
                    this.onBankInfoChange(this.form.value);
                } else {
                    this._notification.showNotification(response.respMessage, 'danger');
                    // localStorage.removeItem('thisSelectedBankDetail');
                }
                this.loading = false;
            }
        } catch (error) {
            this.loading = false;
        } finally {
            this.loading = false;
        }
    }

    isFieldValid(form: FormGroup, field: string) {
        try {
            if (form !== null || form.get(field) !== null) {
                var isFieldNotValid;
                if (form.get(field).touched) {
                    isFieldNotValid =
                        !form.get(field).valid || form.get(field).value === undefined || form.get(field).value === null;
                } else {
                    isFieldNotValid = false;
                }
                return isFieldNotValid;
            }
        } catch (error) { }

        return false;
    }

    public onchangeBankName(event: any): void {
        let bank = this.banks.find(x => {
            return x.name === event.value;
        });
        this.form.patchValue({
            branchCode: bank.value,
            branchName: 'Universal branch',
        });
    }

    public async getUserBanks(): Promise<Array<IUserBanks>> {
        return this._userService.getUserBanks();
    }

    accounttypes = [
        { value: 'Savings', viewValue: 'Savings' },
        { value: 'Cheque', viewValue: 'Cheque' },
    ];

    public async getReferenceData(type: string): Promise<Array<IReferenceData>> {
        return this._lovDataService.getReferenceData(type);
    }
}
