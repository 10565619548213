import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDropdown } from 'src/app/core/models/drop-down';
import { IReferenceData } from 'src/app/core/models/reference-data';
import { LovDataService } from 'src/app/core/services/lov-data.service';
import { VehicleService } from 'src/app/core/services/vehicle.service';
import { getCapturePopupWidth } from 'src/app/core/_helpers/responsive-helper';

import { AddVehicleSteptwoComponent } from '../add-vehicle-steptwo/add-vehicle-steptwo.component';
import { AddVehicleComponent } from '../add-vehicle/add-vehicle.component';

@Component({
    selector: 'app-add-vehicle-step-one',
    templateUrl: './add-vehicle-step-one.component.html',
    styleUrls: ['./add-vehicle-step-one.component.scss'],
})
export class AddVehicleStepOneComponent implements OnInit {
    public loading: boolean = false;

    public form: FormGroup;
    public isCancelClicked = false;
    public formIsValid: boolean;
    public vehicleMakes: Array<IDropdown> = [];
    public vehicleModels: Array<IDropdown> = [];
    public vehicleDescriptions: Array<IDropdown> = [];
    public vehiclePaints:any = [];
    public vehicleColors: Array<IReferenceData> = [];
    public vehicleYears: any = [];
    public claimHistory: Array<IReferenceData>;
    public loading_vehicleMakes: boolean = false;
    public loading_vehicleModels: boolean = false;
    public loading_vehicleDescriptions: boolean = false;
    @Output() vehicleForm = new EventEmitter();

    public placeholderTextMake: any = '';
    public placeholderTextModel: any = '';
    public placeholderTextDescription: any = '';

    selectedYear: any;
    selectedMake: any;
    selectedModel: any;
    selectedDescription: any;

    public constructor(
        public _dialog: MatDialog,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly _vehicleService: VehicleService,
        private readonly _lovDataService: LovDataService,
        public dialogRef: MatDialogRef<AddVehicleComponent>
    ) {}

    public async ngOnInit(): Promise<void> {
        var startYear = new Date().getFullYear();
        for (let index = startYear; index >= 1970; index--) {
            this.vehicleYears.push(index);
        }

        this.initializeForm();
        if (this.data.data !== undefined) {
            const vehicleDescription = `${this.data.data.vehicleDescription}`;
            this.form.patchValue({
                id: this.data.data?.id,
                vehicleName: this.data.data.vehicleName,
                vehicleYear: this.data.data.vehicleYear,
                vehicleMake: this.data.data.vehicleMake,
                vehicleModel: this.data.data.vehicleModel,
                vehicleColour: this.data.data.vehicleColour,
                vehicleDescription: vehicleDescription,
                vehicleKey: this.data.data.vehicleKey,
                vehicleMakeDescription: this.data.data.vehicleMakeDescription,
                vehicleModelDescription: this.data.data.vehicleModelDescription,
                ncb: this.data.data.ncb,
                paintType: this.data.data.paintType,
                vehicleRegNo: this.data.data.vehicleRegNo,
                trackingDeviceInstalled: this.data.data.trackingDeviceInstalled,
                automaticImpactDetection: this.data.data.automaticImpactDetection,
                movementMonitored: this.data.data.movementMonitored,
                // modifiedEngine: this.data.data.modifiedEngine,

                vehicleFinanced: this.data.data.vehicleFinanced,
                coverTypeRequested: true,
                financedAmount: this.data.data.financedAmount,
                kmTravelled: this.data.data.kmTravelled,
                identities: this.data.data.identities,

                parking: this.data.data.parking,
                approvedSecSystemInstalled: this.data.data.approvedSecSystemInstalled,
                vehicleType: this.data.data.vehicleType,
                address: this.data.data.address,

                address1: this.data.data.address1,

                nightAddress: this.data.data.nightAddress,
                nightParking: this.data.data.nightParking,
                dayAddress: this.data.data.dayAddress,
                dayParking: this.data.data.dayParking,
                nightAreaType: this.data.data.nightAreaType,
                dayAreaType: this.data.data.dayAreaType,
                engineModified: this.data.data.engineModified,
                newOne: this.data.data.newOne,
            });
            this.selectedYear = this.form.value.vehicleYear;
            this.selectedMake = this.form.value.vehicleMake;
            this.GetMake();
            this.GetModel({ value: this.selectedMake });
            this.selectedModel = this.form.value.vehicleModel;
            this.GetDescription({ value: this.form.value.vehicleModel });
            this.isThisFormValid();
            const form = {
                isvalid: this.formIsValid,
                isUpdate: true,
                form: this.form.value,
            };
            this.vehicleForm.emit(form);
        }

        this.vehicleColors = await this.getReferenceData('VehicleColors');
        //this.claimHistory = await this.getReferenceData('NCBTypes');

        // this value need to be an enum for now. The sorting is not what is expected
        this.claimHistory = [
            { value: 'ZeroYear', name: '0' },
            { value: ' OneYear', name: '1' },
            { value: ' TwoYear', name: '2' },
            { value: ' ThreeYear', name: '3' },
            { value: ' FourYear', name: '4' },
            { value: ' FiveYear', name: '5' },
            { value: ' SixYear', name: '6' },
            { value: ' SevenYear', name: '7' },
            { value: ' Never', name: '8 and above' },
        ];

        this.vehiclePaints = await this.getReferenceData('PaintType');

        //this apparently is more predictable than onBlur event
        this.form.controls.vehicleYear.valueChanges.subscribe(v => {
            this.selectedYear = v;
            this.GetMake();
        });

        this.form.controls.vehicleMake.valueChanges.subscribe(v => {
            this.selectedMake = v;
            this.GetModel({ value: v });
        });

        this.form.controls.vehicleModel.valueChanges.subscribe(v => {
            this.selectedModel = v;
            this.GetDescription({ value: v });
        });

        this.form.valueChanges.subscribe((selectedValue: any) => {

            this.isThisFormValid();
            const form = {
                isvalid: this.formIsValid,
                form: this.form.value,
            };
            this.vehicleForm.emit(form);
        });
    }

    public async getReferenceData(type: string): Promise<Array<IReferenceData>> {
        return this._lovDataService.getReferenceData(type);
    }

    public OnchangeMake(event?: any) {
        this.form.patchValue({
            vehicleMakeDescription: this.vehicleMakes.filter(x => x.value === this.form.value.vehicleMake)[0].viewValue,
        });
    }

    public OnchangeModel(event: any): void {
        this.form.patchValue({
            vehicleModelDescription: this.vehicleModels.filter(x => x.value === this.form.value.vehicleModel)[0].viewValue,
        });
    }

    public close() {
        this.isCancelClicked = true;
        this.dialogRef.close();
    }

    public openNextModal(): void {
        this.loading = true;

        this.isThisFormValid();

        if (!this.isCancelClicked && (this.form.valid || this.formIsValid)) {
            this._dialog.closeAll();
            const dialogRef = this._dialog.open(AddVehicleSteptwoComponent, {
                width: getCapturePopupWidth(),
                data: this.form.value,
                disableClose: true,
            });
            dialogRef.afterClosed().subscribe(result => {});
        } else {
            this.loading = false;
        }
    }

    GetMake() {
        if (this.selectedYear != null) {
            this.placeholderTextMake = '[LOADING] ';
            this.loading_vehicleMakes = true;

            setTimeout(async () => {
                const yearObj: any = {
                    year: this.selectedYear,
                };

                this._vehicleService.getVehicleMake(yearObj).then(response => {
                    this.vehicleMakes = new Array<IDropdown>();

                    if (
                        response !== null &&
                        (response.respCode === HttpStatusCode.Accepted || response.respCode === HttpStatusCode.Ok) &&
                        response.vehicleMake
                    ) {
                        response.vehicleMake?.forEach((element: any) => {
                            this.vehicleMakes.push({ value: element.value, viewValue: element.make });
                        });
                    } //something happened
                    else {
                        this.vehicleMakes.push({ value: null, viewValue: 'Vehicle Makes not available for the selected year' });
                    }

                    this.loading_vehicleMakes = false;
                    this.placeholderTextMake = '';
                });
            }, 1000);
        } else {
            this.loading_vehicleMakes = false;
            this.placeholderTextMake = '';
        }
    }

    GetModel(e: any) {
        this.placeholderTextModel = '[LOADING] ';
        this.loading_vehicleModels = true;

        if (this.selectedMake) {
            setTimeout(async () => {
                const model: any = {
                    year: this.selectedYear,
                    make: this.selectedMake,
                };
                this._vehicleService.getVehicleModel(model).then(response => {
                    this.vehicleModels = new Array<IDropdown>();
                    if (
                        response !== null &&
                        (response.respCode === HttpStatusCode.Accepted || response.respCode === HttpStatusCode.Ok) &&
                        response.vehicleModels
                    ) {
                        response.vehicleModels?.forEach((element: any) => {
                            this.vehicleModels.push({ value: element.value, viewValue: element.desc });
                        });
                    } //something happened
                    else {
                        this.vehicleModels.push({
                            value: null,
                            viewValue: 'Vehicle model not available for the selected Year & Make',
                        });
                    }

                    this.loading_vehicleModels = false;
                    this.placeholderTextModel = '';
                });
            }, 1000);
        } else {
            this.loading_vehicleModels = false;
            this.placeholderTextModel = '';
        }
    }

    GetDescription(e: any) {
        this.placeholderTextDescription = '[LOADING] ';
        this.loading_vehicleDescriptions = true;

        if (this.selectedYear && this.selectedMake && this.selectedModel) {
            setTimeout(async () => {
                const description: any = {
                    year: this.selectedYear,
                    make: this.selectedMake,
                    model: this.selectedModel,
                };

                this._vehicleService.getVehicleDescription(description).then(response => {
                    this.vehicleDescriptions = new Array<IDropdown>();
                    if (
                        response !== null &&
                        (response.respCode === HttpStatusCode.Accepted || response.respCode === HttpStatusCode.Ok) &&
                        response.vehicleDescs
                    ) {
                        response.vehicleDescs?.forEach((element: any) => {
                            this.vehicleDescriptions.push({
                                value: `${element.value}`,
                                viewValue: element.desc,
                            });
                        });
                        this.form.patchValue({
                            descriptions: this.vehicleDescriptions
                        })
                    } else {
                        this.vehicleDescriptions.push({
                            value: null,
                            viewValue: 'Vehicle description not available for the selected vehicle details',
                        });
                    }
                    this.loading_vehicleDescriptions = false;
                    this.placeholderTextDescription = '';
                });
            }, 1000);
        } else {
            this.loading_vehicleDescriptions = false;
            this.placeholderTextDescription = '';
        }
    }

    isFieldNotValid(form: FormGroup, field: string) {
        try {
            if (form !== null || form.get(field) !== null) {
                var isFieldNotValid;
                if (form.get(field).touched) {
                    isFieldNotValid =
                        !form.get(field).valid || form.get(field).value === undefined || form.get(field).value === null;
                } else {
                    isFieldNotValid = false;
                }
                return isFieldNotValid;
            }
        } catch (error) {}

        return false;
    }

    isThisFormValid(): boolean {
        let isValid = true;

        const fields = [
            'vehicleRegNo',
            'vehicleYear',
            'vehicleMake',
            'vehicleModel',
            'vehicleColour',
            'vehicleKey',
            'paintType',
        ];
        this.formIsValid = true;
        fields?.forEach(x => {
            const fieldValid = !this.isFieldNotValid(this.form, x);
            const value = this.form.get(x).value;
            isValid = isValid && fieldValid;
            this.formIsValid = this.formIsValid && value !== null && value !== undefined && value !== '';
        });

        return isValid;
    }

    public initializeForm() {
        this.form = this.formBuilder.group({
            id: null,
            vehicleName: null,
            vehicleYear: [null, Validators.required],
            vehicleMake: [null, Validators.required],
            vehicleModel: [null, Validators.required],
            vehicleColour: [null, Validators.required],
            vehicleDescription: ['', Validators.required],
            paintType: [null],
            vehicleRegNo: [null, Validators.required],
            vehicleKey: ['', Validators.required],
            ncb: 'ZeroYear',
            vehicleMakeDescription: this.vehicleMakes,
            vehicleModelDescription: this.vehicleModels,
            trackingDeviceInstalled: null,
            automaticImpactDetection: null,
            movementMonitored: null,
            // modifiedEngine: null,

            vehicleFinanced: [null],
            coverTypeRequested: [null],
            financedAmount: [null],
            kmTravelled: [null],
            identities: [null],

            parking: [null],
            approvedSecSystemInstalled: [null],
            vehicleType: [null],
            address: [null],
            descriptions: [null],
            address1: [null],
            nightAddress: null,
            nightParking: null,
            dayAddress: null,
            dayAreaType: null,
            engineModified: null,
            newOne: null,
            dayParking: null,
            nightAreaType: null,
        });
    }
}
