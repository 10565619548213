import { AfterViewInit, Component, ComponentRef, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogEvents } from 'src/app/core/enums/dialog-events.enum';

@Component({
    selector: 'app-dialog-modal',
    templateUrl: './dialog-modal.component.html',
    styleUrls: ['./dialog-modal.component.scss'],
})
export class DialogModalComponent implements  OnInit {
    componentFactoryResolver: any;
    @ViewChild('container', { read: ViewContainerRef }) vcRef: ViewContainerRef;

    public constructor(public dialogRef: MatDialogRef<DialogModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    componentRef: ComponentRef<any>;

    // public ngAfterViewInit() {
    //     const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.data.component);
    //     this.componentRef = this.vcRef.createComponent(componentFactory);
    // }

    // public ngOnDestroy() {
    //     this.componentRef.destroy();
    // }

    public ngOnInit(): void {
        this.dialogRef.disableClose = true;
    }

    public close() {
        this.dialogRef.close(DialogEvents.CLOSE);
        localStorage.removeItem('lovedOne');
    }
}
