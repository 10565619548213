import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutsModule } from './layouts/layouts.module';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';

import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
 import { UserIdleModule } from 'angular-user-idle';
import { AlertModule } from './alert';
import { addVehicleReducer } from './reducers/vehicle.reduce';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { addMotoQuoteReducer } from './reducers/moto-quote.reduce';
import { addDriverReducer } from './reducers/driver.reduce';
import { addBankReducer } from './reducers/bank.reduce';
import { addFuneralQuoteteReducer } from './reducers/funeral-quote.reducer';
import { rootReducer } from './ngrx/reducers/motor-vehicle.reducer';
import { ticketReducer } from './ngrx/reducers/ticket-support.reducer';
import { relatedPartiesReducer } from './ngrx/reducers/funeral-quote.reducer';
import { userReducer } from './ngrx/reducers/user-detail.reducer';
import { HydrationEffects } from './ngrx/hydration/hydration.effect';
import { metaReducers, reducers } from './ngrx';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';




@NgModule({
    declarations: [AppComponent],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutsModule,
        HttpClientModule,
        StoreModule.forRoot(
            reducers, { metaReducers }
        ),
        EffectsModule.forRoot([HydrationEffects]),
        NgIdleKeepaliveModule.forRoot(),
        AlertModule,
    ],

    providers: [
        // { provide: NZ_I18N, useValue: en_US },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'en-GB',
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
