import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { records } from '../records';

@Component({
    selector: 'app-record-of-advice',
    templateUrl: './record-of-advice.component.html',
    styleUrls: ['./record-of-advice.component.scss'],
})
export class RecordOfAdviceComponent implements OnInit {
    @Input()
    public selectAllCheck: boolean = false;

    @Output() recordOfAdviceCompleted = new EventEmitter<boolean>();

    public menu = new FormGroup({
        selectAll: new FormControl(this.selectAllCheck),
        options: new FormArray([]),
    });
    public checkboxes: Array<boolean> = [];

    constructor(private fb: FormBuilder) {}

    public optionLabels = records;
    public partiallySelected = this.menuOptions.valueChanges.pipe(
        map(optionValues => optionValues.some((value: boolean[]) => value !== this.selectAll.value))
    );

    get selectAll() {
        return this.menu.get('selectAll') as FormControl;
    }

    get menuOptions() {
        return this.menu.get('options') as FormArray;
    }

    public OnSelectAllChange(event: any): void {
        this.recordOfAdviceCompleted.emit(event.checked);
    }

    public OnChangeCheck(event: any, index: number) {
        this.menuOptions.controls[index].setValue(event.checked);

        if (!event.checked) {
            this.selectAll.setValue(false);
        }

        const allChecked = this.menuOptions.value.every((value: boolean) => value === true);
        if (allChecked) {
            this.selectAll.setValue(true);
        }

        this.recordOfAdviceCompleted.emit(this.selectAll.value);
    }

    public ngOnInit() {
        setTimeout(async () => {
            for (let index = 0; index < records.length; index++) {
                const lessonForm = new FormControl(this.selectAllCheck);
                this.menuOptions.push(lessonForm);
                this.checkboxes.push(false);
            }

            this.selectAll.valueChanges.subscribe(selectAllValue => {
                this.menuOptions.setValue(this.menuOptions.value.map(() => selectAllValue));
            });
        }, 1000);
    }

    public optionsPartiallySelected() {
        const values = this.menuOptions.value as boolean[];
        return values.some(value => value !== this.selectAll.value);
    }
}
