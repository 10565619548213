
export function isYesOrNoBenefitValue(benefit:any)
{
    return (!benefit.value || benefit.value==='N' || benefit.value === 'No'
    || benefit.value ==='Y' || benefit.value ==='Yes' || benefit.value ==='');
}

export function  isCurrencyBenefitValue(benefit : any)
{
  return (
    //benefit.magisId === 'F000000110' ||
    benefit.magisId === 'F000000127' ||
    benefit.magisId === 'F000000128' ||
    benefit.magisId === 'F000000130');
}

export function isValidBenefitValue(benefit : any)
{
    return benefit?.value && benefit?.value !== '0' && benefit?.value !== '' && benefit?.value !=='N' && benefit?.value !== 'No';
}