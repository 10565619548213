import { Injectable } from '@angular/core';

declare const $: any;

@Injectable({
  providedIn: 'root',
})
export class Notifications {
  public previousMessage: any = null;

  constructor() {}

  showNotification(message: any, type: any) {
    // Close all open notifications

    // Check if the message is the same as the previous message
    if (message === this.previousMessage) {
      return;
    }

    // Store the current message as the previous message
    this.previousMessage = message;

    const self = this; // Store reference to 'this' to use inside onClose callback

    $.notify(
      {
        message: message,
      },
      {
        type: type,
        timer: 3000,
        placement: {
          from: 'top',
          align: 'center',
        },
        offset: {
          y: 20, // adjust the vertical position as needed
        },
        onClose: function () {
          self.previousMessage = null; // Clear the previous message
        },
        template:
          '<div data-notify="container" class="col-xs-11 col-sm-6 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<span data-notify="message" class="font-weight-bold">{2}</span>' +
          '</div>',
      }
    );
  }
}
