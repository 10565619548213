<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card ">
                    <div class="card-header ">
                        <h4 class="card-title-header">Glossary of Terms</h4>
                    </div>
                    <div class="card-body ">
                        <h5 class="text-primary font-weight-bold">1. Funeral benefit</h5>
                        Is the amount that the insurance company will pay to you when you submit a valid claim following
                        the death of a person covered by your funeral insurance policy. This is the primary amount as
                        set out in your insurance policy. (Please check limits)

                        <h5 class="text-primary font-weight-bold">2. Repatriation benefit</h5>
                        This covers the transportation of the dead body of an insured person to their burial place
                        within South Africa. The death should have happened outside the burial place of the deceased and
                        must be above a set distance. Most insurance companies will cover the transportation of the body
                        from the following countries to South Africa; Botswana, Eswatini, Lesotho, Mozambique (South of
                        the 22-degree parallel), Namibia, and Zimbabwe.

                        <h5 class="text-primary font-weight-bold">3. Value protection</h5>
                        This ensures that your funeral benefit increases very year. This is linked to an increase in
                        premiums. Insurance companies will normally determine the annual increase in premiums to give
                        you a particular increase in your funeral benefit. You do not always have to take up the
                        increase in the benefit and premium. If you do not, your insurance policy will remain in place.

                        <h5 class="text-primary font-weight-bold"> 4. Policy continuation benefit</h5>
                        This allows someone to take over your policy (continues paying premiums in your place) should
                        you die and there are other people still covered by your insurance policy. This ensures that
                        your policy continues to cover your loved ones even after you are gone.

                        <h5 class="text-primary font-weight-bold">5. Premium paid up benefit</h5>
                        The policy will continue in place, without paying premiums after the policy holder reaches a
                        particular age and has been paying premiums for at least a set number of years all the people
                        covered by the policy will continue to enjoy cover. No more lives and benefits can be added onto
                        the policy after this event.

                        <h5 class="text-primary font-weight-bold">6. No more premiums after death</h5>
                        This benefit ensures that there are no more premiums payable after the death of the policyholder
                        if there are still other people covered by the policy. For some insurers, this benefit applies
                        only if the policyholder dies before a particular age and might stop after a set period. No more
                        lives and benefits can be added onto the policy once this kicks in.

                        <h5 class="text-primary font-weight-bold">7. No more premiums after disability</h5>
                        This benefit ensures that there are no more premiums payable on the policy after the
                        policyholder becomes disabled before attaining a particular age. This will cover the
                        policyholder and all the lives covered. This benefit stops when the policyholder reaches a
                        prescribed age or when the policyholder dies. No more lives or benefits can be added to the
                        policy once this kicks in.
                        <h5 class="text-primary font-weight-bold">8. No more premiums after retirement age</h5>
                        This benefit ensures that there are no more premiums payable on the policy once the policyholder
                        reaches a set retirement age. All the people covered on the policy will continue to be covered
                        for as long as the policyholder is still alive. No more lives or benefits can be added to the
                        policy once this kicks in.

                        <h5 class="text-primary font-weight-bold">9. No more premiums for a limited period on retrenchment</h5>
                        This benefit ensures that the policy will continue to be in place (not cancelled) for a period
                        without the payment of premiums, typically no more than six months, following the retrenchment
                        of the policyholder or the policyholder falling pregnant. The benefits payable is significantly
                        reduced. The policyholder must prove that they have been retrenched or are pregnant. If they are
                        not employed after the set period and they do not pay the premiums the policy will be cancelled.
                        For most insurers you can only access this benefit for a limited number of retrenchment events.
                        This is essentially a premium holiday.

                        <h5 class="text-primary font-weight-bold">10. Grocery benefit</h5>
                        This benefit is meant to cover the increased costs of groceries necessary to support a funereal
                        event. It is payable in addition to the basic funeral benefit. This is a once off payment.
                        (Please check limits)

                        <h5 class="text-primary font-weight-bold">11. Monthly essentials benefit</h5>
                        This is payable in addition to the funeral benefit, following the death of an insured person
                        under the policy. It provides for a limited amount of income for a set period to help cover
                        certain expenses following the death of the person covered.

                        <h5 class="text-primary font-weight-bold">12. Memorial benefit</h5>
                        This benefit is payable in addition to the basic funeral benefit to support the memorial vents
                        following the death of a person covered on the policy. (Please check limits)

                        <h5 class="text-primary font-weight-bold">13. Cash back benefit</h5>
                        Some insurers pay back some of the premiums after a set period without a claim.

                        <h5 class="text-primary font-weight-bold">14. Limits</h5>
                        The amount of a funeral benefit cannot exceed a set amount. The current limit is one hundred
                        thousand rand (R100 000) for any life insured. Included in this amount will be benefits such as
                        the basic funeral benefit, grocery benefit, memorial benefit, cashback benefit, double accident
                        benefit amongst others. There are other restrictions linked to the age of children below a
                        certain age.

                        <h5 class="text-primary font-weight-bold">15.Funeral insurance relationships</h5>

                        <h4 class="text-primary font-weight-bold">Definitions:</h4>
                        <ul>
                            <li><b>You</b> – The policy owner or policy holder</li>

                            <li><b>Spouse</b> – someone you are legally married to or someone that is commonly referred to as
                                your spouse. Marriage can be either statutory or customary.</li>

                            <li><b>Child</b> – someone who is your or your spouse’s biological child or that has been adopted
                                by you. This includes people that you or your spouse are the legal guardians to. In all
                                cases they have to be under eighteen years of age or under twenty-one if they are full
                                time students. There are cases where this can be up to twenty one and twenty six years
                                respectively. In all instances they must not be married.</li>

                            <li><b>Parent</b> – means your or your spouse’s biological parent including step parents. This will
                                include people that are commonly known as your parents.</li>

                            <li><b>Other relatives</b> – this includes all your other relatives or those of your spouse.
                                Age Restrictions</li>

                            <li><b>You</b> – must be at least 18 years to take out a funeral policy. For most insurers you
                                cannot take cover after the age of 65. There are exceptions to the maximum age depending
                                on insurers.</li>

                            <li><b>Spouse</b> – must be at least 16 at the time cover is taken. For most insurers spouses
                                cannot be covered after the age of 80.</li>

                            <li><b>Child</b> – must not be older than 18 when cover is taken. There are some cases where they
                                will consider a child up to the age of 21. If the child is a full-time student they will
                                be treated as such up to the age of twenty one with other insurers going up to the age
                                of twenty six.</li>

                            <li><b>Parents</b> – for most insurers they must be at least twenty-six when cover is taken but not
                                older than 80.</li>

                            <li><b>Other relatives</b> – can be covered from any age up to 80 years for most insurers.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>