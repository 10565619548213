import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IBeneficiaries } from '../models/beneficiaries';

@Injectable({
    providedIn: 'root',
})
export class BeneficiaryService {
    public constructor(private readonly _http: HttpClient) {}

    public async updateBeneficiaries(model: IBeneficiaries): Promise<any> {
        try {
            const response = await this._http
                .post<void>(`${environment.serverApiUrl}policy/v1/assets/update-beneficiaries`, model)
                .toPromise();
            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getBeneficiaries(): Promise<Array<IBeneficiaries>> {
        try {
            const response = await this._http
                .post<Array<IBeneficiaries>>(`${environment.serverApiUrl}policy/v1/assets/get-beneficiaries`, null)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }
}
