<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-modal">
                    <!-- <div class="card-header card-header-rose card-header-icon">
                        <div class="card-icon">
            
                        </div>
                        <h4 class="card-title">Add Vehicle</h4>
                    </div> -->
                    <div class="card-body">
                        <form [formGroup]="form">
                            <div class="row">
                                <div class="col-md-6">
                                    <br />
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p>Is this vehicle financed?</p>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-radio-group tabindex="1" formControlName="vehicleFinanced"
                                                aria-label="Select an option"
                                                (change)="onVehicleFinancedSelected($event)">
                                                <mat-radio-button [checked]="isVehicleFinanced" [value]=true>Yes
                                                </mat-radio-button>
                                                <mat-radio-button [checked]="!isVehicleFinanced" [value]=false>No
                                                </mat-radio-button>
                                                <app-field-error-display
                                                    [displayError]="isFieldValid(form, 'vehicleFinanced')"
                                                    errorMsg="Vehicle Financed is required.">
                                                </app-field-error-display>
                                            </mat-radio-group>
                                        </div>
                                    </div>

                                    <br />
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p>Is this car brand new?</p>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-radio-group tabindex="3" formControlName="newOne"
                                                aria-label="Select an option">
                                                <mat-radio-button [checked]="isNew" [value]=true>Yes</mat-radio-button>
                                                <mat-radio-button [checked]="!isNew" [value]=false>No</mat-radio-button>
                                            </mat-radio-group>
                                            <app-field-error-display [displayError]="isFieldValid(form, 'newOne')"
                                                errorMsg="Cover Type Requested is required.">
                                            </app-field-error-display>
                                        </div>
                                    </div>

                                    <br />
                                </div>

                                <div class="col-md-6" *ngIf="isVehicleFinanced">
                                    <mat-form-field class="example-full-width">
                                        <input matInput tabindex="2" (blur)="onInput($event)"
                                            formControlName="financedAmount" 
                                            placeholder="Finance amount" 
                                            matTooltip="Finance amount" 
                                            type="text" />

                                        <app-field-error-display [displayError]="isFieldValid(form, 'financedAmount')"
                                            errorMsg="Amount is required.">
                                        </app-field-error-display>
                                    </mat-form-field>
                                    <br />

                                    <!-- <mat-form-field style="display: none; opacity: 0">
                                        <mat-select formControlName="identities" class="example-full-width"
                                            placeholder="Make*" name="model">
                                            <mat-option *ngFor="let identity of identities" [value]="identity.value">
                                                {{ identity.viewValue }}
                                            </mat-option>
                                        </mat-select>
                                        <app-field-error-display [displayError]="isFieldValid(form, 'identities')"
                                            errorMsg="Identities is required.">
                                        </app-field-error-display>
                                    </mat-form-field> -->
                                    <br />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field>
                                        <mat-select tabindex="4" class="example-full-width"
                                            formControlName="kmTravelled"
                                            placeholder="How many kilometers do you travel per annum?" 
                                            matTooltip="How many kilometers do you travel per annum?" 
                                            name="model">
                                            <mat-option *ngFor="let a of travel" [value]="a.value">
                                                {{ a.viewValue | titlecase}}
                                            </mat-option>
                                        </mat-select>
                                        <app-field-error-display [displayError]="isFieldValid(form, 'kmTravelled')"
                                            errorMsg="Km Travelled is required.">
                                        </app-field-error-display>
                                    </mat-form-field>
                                    <br />

                                    <mat-form-field>
                                        <mat-select tabindex="6" class="example-full-width" formControlName="dayParking"

                                            placeholder="Where is the vehicle parked during the day?" 
                                            matTooltip="Where is the vehicle parked during the day?" 
                                            name="model">
                                            <mat-option *ngFor="let a of parking" [value]="a.value">
                                                {{ a.viewValue | titlecase}}
                                            </mat-option>
                                        </mat-select>
                                        <app-field-error-display [displayError]="isFieldValid(form, 'dayParking')"
                                            errorMsg="Daytime parking is required.">
                                        </app-field-error-display>
                                    </mat-form-field>

                                    <br />
                                    <mat-form-field>
                                        <mat-select tabindex="9" class="example-full-width"
                                            formControlName="nightParking"
                                            placeholder="Where is the vehicle parked during the night?"
                                            matTooltip="Where is the vehicle parked during the night?"
                                            name="nightParking">
                                            <mat-option *ngFor="let a of parking" [value]="a.value">
                                                {{ a.viewValue | titlecase}}
                                            </mat-option>
                                        </mat-select>
                                        <app-field-error-display [displayError]="isFieldValid(form, 'nightParking')"
                                            errorMsg="Parking is required.">
                                        </app-field-error-display>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-8">
                                    <mat-form-field>
                                        <mat-select tabindex="5" formControlName="approvedSecSystemInstalled"
                                            class="example-full-width"
                                            placeholder="Does your car have an approved security system?"
                                            matTooltip="Does your car have an approved security system?"
                                            name="approvedSecSystemInstalled">
                                            <mat-option value="true">
                                                Yes
                                            </mat-option>
                                            <mat-option value="false">
                                                No
                                            </mat-option>
                                        </mat-select>
                                        <app-field-error-display
                                            [displayError]="isFieldValid(form, 'approvedSecSystemInstalled')"
                                            errorMsg="Alarm is required.">
                                        </app-field-error-display>
                                    </mat-form-field>
                                    <br />
                                    <div class="row">
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <mat-select tabindex="7" formControlName="dayAreaType"
                                                    class="example-full-width" 
                                                    placeholder="Day parking area type"
                                                    matTooltip="Day parking area type"
                                                    name="dayAreaType">
                                                    <mat-option *ngFor="let areaType of areaTypes"
                                                        [value]="areaType.value">
                                                        {{ areaType.viewValue | titlecase}}
                                                    </mat-option>
                                                </mat-select>
                                                <app-field-error-display
                                                    [displayError]="isFieldValid(form, 'dayParkingAreaType')"
                                                    errorMsg="Day parking area type is required.">
                                                </app-field-error-display>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-6">
                                            <mat-form-field tabindex="8" class="example-full-width">
                                                <input ngx-google-places-autocomplete autocomplete="off" name="data_input_field_3"
                                                    id="input-field-3" [options]="options" #placesRef="ngx-places"
                                                    (onAddressChange)="handleAddressChangedayAddress($event)"
                                                    formControlName="dayAddress" matInput 
                                                    placeholder="Day address"
                                                    matTooltip="Day address"
                                                    type="text" />
                                            </mat-form-field>
                                            <app-field-error-display [displayError]="isFieldValid(form, 'dayAddress')"
                                                errorMsg="Day parking address is required.">
                                            </app-field-error-display>
                                        </div>
                                    </div>

                                    <br />

                                    <div class="row">
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <mat-select tabindex="10" formControlName="nightAreaType"
                                                    class="example-full-width" 
                                                    placeholder="Night parking area type"
                                                    matTooltip="Night parking area type"
                                                    name="nightAreaType">
                                                    <mat-option *ngFor="let areaType of areaTypes"
                                                        [value]="areaType.value">
                                                        {{ areaType.viewValue | titlecase}}
                                                    </mat-option>
                                                </mat-select>
                                                <app-field-error-display
                                                    [displayError]="isFieldValid(form, 'nightAreaType')"
                                                    errorMsg="Night parking area type is required.">
                                                </app-field-error-display>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-6">
                                            <mat-form-field tabindex="11" class="example-full-width">
                                                <input ngx-google-places-autocomplete autocomplete="off" name="data_input_field_3"
                                                    id="input-field-3" [options]="options" #placesRef="ngx-places"
                                                    (onAddressChange)="handleAddressChangenightAddress($event)"
                                                    formControlName="nightAddress" matInput 
                                                    placeholder="Night address"
                                                    matTooltip="Night address"
                                                    type="text" />
                                                <app-field-error-display
                                                    [displayError]="isFieldValid(form, 'nightAddress')"
                                                    errorMsg="Night parking address is required.">
                                                </app-field-error-display>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer">
                         
                                <div class="ml-auto">
                                    <!-- <button mat-raised-button type="submit" [disabled]="!formIsValid" (click)="done()"
                                        class="btn btn-primary btn-round">Done
                                        &nbsp;
                                        <mat-icon *ngIf="loading">
                                            <mat-spinner diameter="20">
                                            </mat-spinner>
                                        </mat-icon>
                                    </button> -->
                              
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>