import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogEvents } from 'src/app/core/enums/dialog-events.enum';
import { IBeneficiaries } from 'src/app/core/models/beneficiaries';
import { IRelatedParties } from 'src/app/core/models/related-parties';
import { RelatedPartyService } from 'src/app/core/services/related-party.service';
import { getCapturePopupWidth, getCapturePopupWidthM } from 'src/app/core/_helpers/responsive-helper';
import { DialogModalComponent } from '../dialog-modal/dialog-modal.component';
import { FormBeneficiaryComponent } from '../form-beneficiary/form-beneficiary.component';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/core/services/app.service';

@Component({
    selector: 'app-beneficiary',
    templateUrl: './beneficiary.component.html',
    styleUrls: ['./beneficiary.component.scss'],
})
export class BeneficiaryComponent implements OnInit {
    mobileView = false;
    mobileViewSub: Subscription;
    isNotified: boolean = true;
    public relatedParties: any;
    @Input()
    public beneficiaries: IRelatedParties;
    @Output() beneficiary = new EventEmitter();

    beneificariesCount: number = 0;

    public selectedBeneficiaries: Array<IBeneficiaries> = [];
    public isAddBeneficiary: boolean = false;
    public isNotifyBeneficiary: boolean = false;

    @Input() beneficiariesSelected: Array<IRelatedParties> = [];
    @Input() shouldAddBeneficiary: boolean;
    @Input() shouldNotifyBeneficiary: boolean;

    @Output() setSelectedBeneficiaries = new EventEmitter();
    @Output() setShouldAddBeneficiary = new EventEmitter();
    @Output() setShouldNotifyBeneficiary = new EventEmitter();

    public constructor(
        public _dialog: MatDialog,
        private readonly _relatedPartyService: RelatedPartyService,
        private readonly _appService: AppService
    ) {
        this.mobileViewSub = this._appService.mobileView$.subscribe(result => {
            this.mobileView = result;
        });
    }

    public async onShouldAddBeneficiaryChange(event: any): Promise<void> {
        this.isAddBeneficiary = event.value === '1';
        this.setShouldAddBeneficiary.emit(this.isAddBeneficiary);
        this.beneficiaries = await this.getBeneficiaries();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['beneficiariesSelected']) {
            const currentBeneficiaries = changes['beneficiariesSelected'].currentValue;

            if (currentBeneficiaries) {
                this.isAddBeneficiary = true;
                this.isNotified = false;
            }

            const previousBeneficiaries = changes['beneficiariesSelected'].previousValue;

            // Compare the current and previous values
            if (JSON.stringify(currentBeneficiaries) !== JSON.stringify(previousBeneficiaries)) {
                // Perform actions based on the change
            }
        }
    }

    public onShouldNotifyBeneficiaryChange(event: any): void {
        this.isNotifyBeneficiary = event.value === '3';
        this.setShouldNotifyBeneficiary.emit(!this.isNotifyBeneficiary);
    }

    public onBeneficiarySelection(event: any, model: any) {
        if (event.checked) {
            this.selectedBeneficiaries.push(model);
        } else {
            var indexOfSelection = this.selectedBeneficiaries.indexOf(model);
            this.selectedBeneficiaries.splice(indexOfSelection, 1);
        }

        this.setSelectedBeneficiaries.emit(this.selectedBeneficiaries);
    }

    public openOptModalBeneficiary(model?: any): void {
        if (model !== undefined) {
            localStorage.setItem('beneficiaryData', JSON.stringify(model));
        } else {
            localStorage.removeItem('beneficiaryData');
        }

        const dialogRef = this._dialog.open(DialogModalComponent, {
            width: getCapturePopupWidthM(),
            data: {
                component: FormBeneficiaryComponent,
                icon: 'person_add',
            },
        });

        dialogRef.afterClosed().subscribe(async (result: any) => {
            setTimeout(async () => {
                if (result !== DialogEvents.CLOSE && result === false) {
                    this.beneficiaries = await this.getBeneficiaries();
                }
            }, 3000);
        });
    }

    public isRelatedPartySelected(model: any): boolean {
        const bool = this.beneficiariesSelected?.some((x: any) => x.dependantId === model.dependantId);
        return bool;
    }

    public async ngOnInit(): Promise<void> {
        this.beneficiaries = await this.getBeneficiaries();

        this.beneificariesCount = this.beneficiaries?.relatedParties?.length;
    }

    public async getBeneficiaries(): Promise<IRelatedParties> {
        return this._relatedPartyService.getUserRelatedParties();
    }
}
