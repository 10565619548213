<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-modal">
                    <!-- <div class="card-header card-header-rose card-header-icon">
                        <div class="card-icon">
                            <mat-icon>add</mat-icon>
                   
                        </div>
                        <h4 class="card-title">Add Vehicle</h4>
                    </div> -->

                    <form [formGroup]="form">
                        <div class="card-body">
                            <div>
                                <h5>Does your vehicle have any of the following?</h5>
                                <br />
                                <div class="row">
                                    <div class="col-md-6">
                                        <p>Tracking device</p>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-radio-group formControlName="trackingDeviceInstalled"
                                            aria-label="Select an option">
                                            <mat-radio-button [value]=true>Yes</mat-radio-button>
                                            <mat-radio-button [value]=false>No</mat-radio-button>
                                        </mat-radio-group>
                                        <app-field-error-display
                                            [displayError]="isFieldValid(form, 'trackingDeviceInstalled')"
                                            errorMsg="Tracking Device Installed is required.">
                                        </app-field-error-display>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <p>Impact detection</p>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-radio-group formControlName="automaticImpactDetection"
                                            aria-label="Select an option">
                                            <mat-radio-button [value]=true>Yes</mat-radio-button>
                                            <mat-radio-button [value]=false>No</mat-radio-button>
                                            <app-field-error-display
                                                [displayError]="isFieldValid(form, 'automaticImpactDetection')"
                                                errorMsg="Automatic Impact Detection is required.">
                                            </app-field-error-display>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p>Movement monitoring</p>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-radio-group formControlName="movementMonitored"
                                            aria-label="Select an option">
                                            <mat-radio-button [value]=true>Yes</mat-radio-button>
                                            <mat-radio-button [value]=false>No</mat-radio-button>
                                        </mat-radio-group>
                                        <app-field-error-display
                                            [displayError]="isFieldValid(form, 'movementMonitored')"
                                            errorMsg="Movement Monitored is required.">
                                        </app-field-error-display>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p>Modified engine</p>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-radio-group formControlName="engineModified" aria-label="Select an option">
                                            <mat-radio-button [value]=true>Yes</mat-radio-button>
                                            <mat-radio-button [value]=false>No</mat-radio-button>
                                            <app-field-error-display
                                                [displayError]="isFieldValid(form, 'engineModified')"
                                                errorMsg="Modified Engine is required.">
                                            </app-field-error-display>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <!-- <button (click)="back()" mat-raised-button class="btn btn-primary btn-round">
                                        Back
                                    </button>
                                    <div class="ml-auto">
                                        <button mat-raised-button type="submit" [disabled]="!formIsValid"
                                            (click)="openNextModal()" class="btn btn-primary btn-round">Next
                                            &nbsp;
                                            <mat-icon *ngIf="loading">
                                                <mat-spinner diameter="20">
                                                </mat-spinner>
                                            </mat-icon>
                                        </button>

                                        <button (click)="close()" mat-raised-button class="btn btn-primary btn-round">
                                            Cancel
                                        </button> -->
                                        <!-- <input
                                            type="button"
                                            (click)="openNextModal()"
                                            class="btn btn-next btn-fill btn-rose btn-wd btn-add"
                                            name="next"
                                            [value]="Next"
                                        /> -->
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>