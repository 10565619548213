<h1 class="card-title-header ml-auto d-flex justify-content-center">You are idle</h1>
<div>
    <p class="ml-auto d-flex justify-content-center"><strong>Due to the inactivity, you will be signout</strong></p>
        <p class="ml-auto d-flex justify-content-center"><strong> automatically in {{ body }} <span class="text-primary">seconds</span></strong></p>
    <div>
        <div class="container d-flex justify-content-center padding">
            <div class="row">
                <div class="col-md-9 col-sm-6">
                    <div class="progress blue">
                        <span class="progress-left">
                            <span class="progress-bar"></span>
                        </span>
                        <span class="progress-right">
                            <span class="progress-bar"></span>
                        </span>
                        <div class="progress-value">{{ body }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p class="ml-auto d-flex justify-content-center"><strong>Contiue or Sign out?</strong></p>
</div>
<div class="ml-auto d-flex justify-content-end">
    <button mat-raised-button class="btn btn-primary btn-add btn-round" (click)="signout()">SIGN OUT
    </button>
    <button mat-raised-button class="btn btn-primary btn-round" (click)="close()">CONTIUE
    </button>
</div>
