export class PhonePipe {
    transform(rawNum:string) {
      rawNum = "+27"+ rawNum;
  
      const countryCodeStr = rawNum.slice(0,3);
      const areaCodeStr = rawNum.slice(3,6);
      const midSectionStr = rawNum.slice(6,9);
      const lastSectionStr = rawNum.slice(9);
  
      return `${countryCodeStr} (${areaCodeStr})${midSectionStr}-${lastSectionStr}`;
    }
  }