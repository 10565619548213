import { Component, OnInit, Input, Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
@Component({
    selector: 'app-field-error-display',
    templateUrl: './field-error-display.component.html',
    styleUrls: ['./field-error-display.component.css'],
})
export class FieldErrorDisplayComponent {
    @Input() errorMsg: string;
    @Input() displayError: boolean;

    @Input()
    public control!: AbstractControl;
    @Input()
    public label!: string;
    @Input()
    public submitting?: boolean;

    message: string;

    isFieldValid(form: FormGroup, field: string) {
        return !form.get(field).valid && form.get(field).touched;
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls)?.forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    ValidateIdnumber(value: any): boolean {
        var re = /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-9])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/;
        if (!re.test(String(value).toLowerCase())) {
            return false;
        } else {
            return true;
        }
    }
}
