<div class="container-width">
    <div class="container-tile">
        <div nz-row>
            <div>
                <!-- <div>
                    <span>Quote effective date: {{ quoteEffectiveDate }}</span>
                </div> -->

                <div>
                    <span>Commencement date: this will be confirmed on policy schedule. {{ policyCommencementDate }}</span>
                </div>
            </div>
            <div
                nz-col
                nzSpan="8"
                nzOffset="8"
                *ngIf="insurerSelectedOnChild === 'metropolitan' || !insurerSelectedOnChild || !insurerSelectedOnChild"
            >
                <img src="../../../assets/logos/metropolitan-1.png" alt="" />
            </div>
            <div nz-col nzSpan="8" nzOffset="8" *ngIf="insurerSelectedOnChild === 'sanlam'">
                <img src="../../../assets/logos/sanlam-1.png" alt="" />
            </div>
            <div nz-col nzSpan="8" nzOffset="8" *ngIf="insurerSelectedOnChild === '1st For Women'">
                <img src="../../../assets/logos/1st For Women.png" alt="" />
            </div>
            <div nz-col nzSpan="8" nzOffset="8" *ngIf="insurerSelectedOnChild === 'Auto&General'">
                <img src="../../../assets/logos/Auto&General.png" alt="" />
            </div>
            <div nz-col nzSpan="8" nzOffset="8" *ngIf="insurerSelectedOnChild === 'hollard'">
                <img src="../../../assets/logos/hollard-1.png" alt="" />
            </div>
            <div nz-col nzSpan="8" nzOffset="8" *ngIf="insurerSelectedOnChild === 'Virseker'">
                <img src="../../../assets/logos/virseker-logo-1.png" alt="" />
            </div>
            <div nz-col nzSpan="8" nzOffset="8" *ngIf="insurerSelectedOnChild === 'Unity'">
                <img src="../../../assets/logos/Unity.png" alt="" />
            </div>
        </div>

        <!-- <div>
            <p>
                This quotation is valid for <b>30 days</b> from the quote date. Please check all the information contained in this
                schedule and advise us of any changes immediately.
            </p>
        </div> -->
    </div>

    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header card-header-rose card-header-icon">
                            <div class="card-icon">
                                <mat-icon>computer</mat-icon>
                            </div>
                            <h4 class="card-title">Quote Schedule</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-header"></div>
                                        <div class="card-body">
                                            <div id="accordion" role="tablist">
                                                <div class="card-collapse">
                                                    <div class="card-header" role="tab" id="headingOne">
                                                        <h5 class="mb-0">
                                                            <a
                                                                data-toggle="collapse"
                                                                href="#collapseOne"
                                                                (click)="main()"
                                                                aria-expanded="true"
                                                                aria-controls="collapseOne"
                                                                class="collapsed"
                                                            >
                                                                Policyholder details
                                                                <i class="material-icons">keyboard_arrow_down</i>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div
                                                        id="collapseOne"
                                                        class="collapse show"
                                                        role="tabpanel"
                                                        aria-labelledby="headingOne"
                                                        data-parent="#accordion"
                                                    >
                                                        <div *ngIf="!mobileView" class="card-body">
                                                            <div class="table-responsive">
                                                                <table class="table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="schedule-text-bold">Policyholder</td>
                                                                            <td>
                                                                                {{ userDetail?.profileDetails?.firstName }}
                                                                                {{ userDetail?.profileDetails?.lastName }}
                                                                            </td>
                                                                            <td class="schedule-text-bold">Policyholder type</td>
                                                                            <td>Natural person</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="schedule-text-bold">
                                                                                Identity/passport number
                                                                            </td>
                                                                            <td>
                                                                                {{ userIdentification }}
                                                                            </td>
                                                                            <td class="schedule-text-bold">Date of birth</td>
                                                                            <td>
                                                                                {{
                                                                                    userDetail?.profileDetails?.dateOfBirth
                                                                                        | date: 'dd MMM yyyy'
                                                                                }}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="schedule-text-bold">
                                                                                Residential address
                                                                            </td>
                                                                            <td>{{ residentialAddress }}</td>
                                                                            <td class="schedule-text-bold">Postal address</td>
                                                                            <td>{{ postalAddress }}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3" class="schedule-text-bold">
                                                                                Have special terms and conditions been imposed or
                                                                                has cover ever been refused or cancelled for you
                                                                                or anybody that will have cover on this Plan?
                                                                            </td>
                                                                            <td>{{ specialTaCImposedQ }}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        <!-- app.component.html -->

                                                        <!-- app.component.html -->

                                                        <div class="card-body" *ngIf="mobileView">
                                                            <div class="accordion" id="userDetailsAccordion">
                                                                <!-- User Details Section -->
                                                                <div class="accordion-item">
                                                                    <div class="accordion-header" id="user-details-heading">
                                                                        <div class="accordion-title">
                                                                            <button
                                                                                class="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#user-details-collapse"
                                                                                aria-expanded="true"
                                                                                aria-controls="user-details-collapse"
                                                                            >
                                                                                User Details
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        id="user-details-collapse"
                                                                        class="collapse show"
                                                                        aria-labelledby="user-details-heading"
                                                                        data-bs-parent="#userDetailsAccordion"
                                                                    >
                                                                        <div class="card-body">
                                                                            <div class="table-responsive">
                                                                                <p>
                                                                                    <strong>Policyholder:</strong>
                                                                                    {{ userDetail?.profileDetails?.firstName }}
                                                                                    {{ userDetail?.profileDetails?.lastName }}
                                                                                </p>
                                                                                <p>
                                                                                    <strong>Policyholder type:</strong> Natural
                                                                                    person
                                                                                </p>
                                                                                <p>
                                                                                    <strong>Identity/passport number:</strong>
                                                                                    {{ userIdentification }}
                                                                                </p>
                                                                                <p>
                                                                                    <strong>Date of birth:</strong>
                                                                                    {{
                                                                                        userDetail?.profileDetails?.dateOfBirth
                                                                                            | date: 'dd MMM yyyy'
                                                                                    }}
                                                                                </p>
                                                                                <p>
                                                                                    <strong>Residential address:</strong>
                                                                                    {{ residentialAddress }}
                                                                                </p>
                                                                                <p>
                                                                                    <strong>Postal address:</strong>
                                                                                    {{ postalAddress }}
                                                                                </p>
                                                                                <p>
                                                                                    <strong
                                                                                        >Have special terms and conditions been
                                                                                        imposed or has cover ever been refused or
                                                                                        cancelled for you or anybody that will
                                                                                        have cover on this Plan?</strong
                                                                                    >
                                                                                    {{ specialTaCImposedQ }}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-collapse">
                                                    <div class="card-header" role="tab" id="headingTwo">
                                                        <h5 class="mb-0">
                                                            <a
                                                                class="collapsed"
                                                                data-toggle="collapse"
                                                                href="#collapseTwo"
                                                                aria-expanded="false"
                                                                aria-controls="collapseTwo"
                                                            >
                                                                Financial advisor and insurer details
                                                                <i class="material-icons">keyboard_arrow_down</i>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div
                                                        id="collapseTwo"
                                                        class="collapse"
                                                        role="tabpanel"
                                                        aria-labelledby="headingTwo"
                                                        data-parent="#accordion"
                                                    >
                                                        <div class="card-body">
                                                            <table class="table" *ngIf="selectedQuoteOption">
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="schedule-text-bold fifteen-percent">Broker Code.</td>
                                                                        <td class="thirtyfive-percent">{{selectedQuoteOption?.brokerCode }}</td>
                                                                        <td class="schedule-text-bold fifteen-percent">
                                                                            Insurance provider & plan
                                                                        </td>
                                                                        <td class="thirtyfive-percent">
                                                                            {{ selectedQuoteOption?.insuranceProvider }} & {{selectedQuoteOption?.planName}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-collapse">
                                                    <div class="card-header" role="tab" id="headingThree">
                                                        <h5 class="mb-0">
                                                            <a
                                                                class="collapsed"
                                                                data-toggle="collapse"
                                                                href="#collapseThree"
                                                                aria-expanded="false"
                                                                aria-controls="collapseThree"
                                                            >
                                                                Cover summary
                                                                <i class="material-icons">keyboard_arrow_down</i>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div
                                                        id="collapseThree"
                                                        class="collapse"
                                                        role="tabpanel"
                                                        aria-labelledby="headingThree"
                                                        data-parent="#accordion"
                                                    >
                                                        <div *ngIf="!mobileView" class="card-body">
                                                            <div *ngFor="let member of selectedQuoteOption?.breakUpQuote">
                                                                <div *ngIf="!mobileView">
                                                                    <!-- Display member details in a table -->
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="thirtyfive-percent">
                                                                                    {{ member.firstName }}
                                                                                    {{ member.surname }} ({{
                                                                                        member.partyType | formatMainMember
                                                                                    }})
                                                                                </td>
                                                                                <td class="schedule-text-bold">
                                                                                    Identity/passport number
                                                                                </td>
                                                                                <td>{{ member.identityNumber }}</td>
                                                                                <td class="schedule-text-bold">Date of birth</td>
                                                                                <td>
                                                                                    {{ member.dateOfBirth | date: 'dd MMM yyyy' }}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>

                                                                    <!-- Display member benefits array from quotes.breakUpQuote in the same table -->
                                                                    <table class="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th class="schedule-text-bold">Benefit Name</th>
                                                                                <!-- <th class="schedule-text-bold">Covered</th> -->
                                                                                <th class="schedule-text-bold">Coverage</th>
                                                                                <th class="schedule-text-bold">Reason</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <!-- Loop through each benefit for the current member -->
                                                                            <tr *ngFor="let benefit of member.benefits">
                                                                                <td>{{ benefit.name }}</td>
                                                                                <!-- <td>{{ benefit.covered ? 'Yes' : 'No' }}</td> -->

                                                                                <ng-container
                                                                                    *ngIf="
                                                                                        benefit.name === 'Cover Amount';
                                                                                        else notCovered
                                                                                    "
                                                                                >
                                                                                    <td>
                                                                                        {{
                                                                                            benefit.value | zarCurrency'
                                                                                        }}
                                                                                    </td>
                                                                                </ng-container>

                                                                                <ng-template #notCovered>
                                                                                    <th *ngIf="benefit.covered == false">
                                                                                        <mat-icon style="color: red"
                                                                                            >close</mat-icon
                                                                                        >
                                                                                    </th>
                                                                                    <th *ngIf="benefit.covered === true">
                                                                                        <mat-icon
                                                                                            style="margin-top: -7px"
                                                                                            class="custom-icon"
                                                                                            >done</mat-icon
                                                                                        >
                                                                                    </th>
                                                                                </ng-template>

                                                                                <td>{{ benefit.reason }}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <td
                                                                        style="color: #8d55ca; font-weight: 800"
                                                                        class="thirtyfive-percent"
                                                                    >
                                                                        Premium :
                                                                        {{
                                                                            member.premium | zarCurrency
                                                                        }}
                                                                    </td>

                                                                    <!-- Add a "Done" button on the right side -->

                                                                    <br />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div *ngIf="mobileView">
                                                            <div class="card-body">
                                                                <div style="text-align: center">
                                                                    <div class="accordion" id="memberAccordion">
                                                                        <div
                                                                            *ngFor="
                                                                                let member of selectedQuoteOption?.breakUpQuote;
                                                                                let i = index
                                                                            "
                                                                            class="accordion-item"
                                                                        >
                                                                            <div
                                                                                class="accordion-header"
                                                                                id="member-heading-{{ i }}"
                                                                            >
                                                                                <div class="accordion-title">
                                                                                    <div
                                                                                        class="accordion-button collapsed"
                                                                                        type="button"
                                                                                        data-bs-toggle="collapse"
                                                                                        [attr.data-bs-target]="
                                                                                            '#member-collapse-' + i
                                                                                        "
                                                                                        aria-expanded="true"
                                                                                        aria-controls="member-collapse-{{ i }}"
                                                                                    >
                                                                                        <div class="mobile-card-name-text">
                                                                                            <p class="name">
                                                                                                {{ member.firstName }}
                                                                                                {{ member.surname }}
                                                                                            </p>
                                                                                            <p class="schedule-text-bold">
                                                                                                {{
                                                                                                    member.partyType
                                                                                                        | formatMainMember
                                                                                                }}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                id="member-collapse-{{ i }}"
                                                                                class="collapse"
                                                                                aria-labelledby="member-heading-{{ i }}"
                                                                                [attr.data-bs-parent]="'#memberAccordion'"
                                                                            >
                                                                                <div class="card-body">
                                                                                    <!-- Identity Accordion -->
                                                                                    <div
                                                                                        class="accordion"
                                                                                        [attr.id]="'identityAccordion-' + i"
                                                                                    >
                                                                                        <div class="accordion-item">
                                                                                            <div
                                                                                                class="accordion-header"
                                                                                                id="identity-heading-{{ i }}"
                                                                                            >
                                                                                                <div class="accordion-title">
                                                                                                    <button
                                                                                                        class="accordion-button collapsed"
                                                                                                        type="button"
                                                                                                        data-bs-toggle="collapse"
                                                                                                        style="
                                                                                                            color: #8d55ca;
                                                                                                            font-weight: 500;
                                                                                                        "
                                                                                                        [attr.data-bs-target]="
                                                                                                            '#identity-collapse-' +
                                                                                                            i
                                                                                                        "
                                                                                                        aria-expanded="false"
                                                                                                        aria-controls="identity-collapse-{{
                                                                                                            i
                                                                                                        }}"
                                                                                                    >
                                                                                                        <div
                                                                                                            class="mobile-card-name-text"
                                                                                                        >
                                                                                                            <p class="name">
                                                                                                                <strong
                                                                                                                    >Identity/passport
                                                                                                                    number:</strong
                                                                                                                >
                                                                                                                {{
                                                                                                                    member.identityNumber
                                                                                                                }}
                                                                                                            </p>
                                                                                                            <p
                                                                                                                class="schedule-text-bold"
                                                                                                            >
                                                                                                                <strong
                                                                                                                    >Date of
                                                                                                                    birth:</strong
                                                                                                                >
                                                                                                                {{
                                                                                                                    member.dateOfBirth
                                                                                                                        | date
                                                                                                                            : 'dd MMM yyyy'
                                                                                                                }}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div
                                                                                                id="identity-collapse-{{ i }}"
                                                                                                class="collapse"
                                                                                                aria-labelledby="identity-heading-{{
                                                                                                    i
                                                                                                }}"
                                                                                                [attr.data-bs-parent]="
                                                                                                    '#identityAccordion-' + i
                                                                                                "
                                                                                            >
                                                                                                <div>
                                                                                                    <p class="schedule-text-bold">
                                                                                                        <strong
                                                                                                            >Identity/passport
                                                                                                            number:</strong
                                                                                                        >
                                                                                                        {{
                                                                                                            member.identityNumber
                                                                                                        }}
                                                                                                    </p>
                                                                                                    <p class="schedule-text-bold">
                                                                                                        <strong
                                                                                                            >Date of
                                                                                                            birth:</strong
                                                                                                        >
                                                                                                        {{
                                                                                                            member.dateOfBirth
                                                                                                                | date
                                                                                                                    : 'dd MMM yyyy'
                                                                                                        }}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <!-- Benefits Accordions -->
                                                                                    <div
                                                                                        class="accordion"
                                                                                        [attr.id]="'benefitAccordion-' + i"
                                                                                    >
                                                                                        <div
                                                                                            *ngFor="
                                                                                                let benefit of member.benefits;
                                                                                                let j = index
                                                                                            "
                                                                                            class="accordion-item"
                                                                                        >
                                                                                            <div
                                                                                                class="accordion-header"
                                                                                                id="benefit-heading-{{ i }}-{{
                                                                                                    j
                                                                                                }}"
                                                                                            >
                                                                                                <div class="accordion-title">
                                                                                                    <button
                                                                                                        class="accordion-button collapsed"
                                                                                                        type="button"
                                                                                                        style="
                                                                                                            color: #8d55ca;
                                                                                                            font-weight: 500;
                                                                                                        "
                                                                                                        data-bs-toggle="collapse"
                                                                                                        [attr.data-bs-target]="
                                                                                                            '#benefit-collapse-' +
                                                                                                            i +
                                                                                                            '-' +
                                                                                                            j
                                                                                                        "
                                                                                                        aria-expanded="false"
                                                                                                        aria-controls="benefit-collapse-{{
                                                                                                            i
                                                                                                        }}-{{ j }}"
                                                                                                    >
                                                                                                        {{ benefit.name }}
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div
                                                                                                id="benefit-collapse-{{ i }}-{{
                                                                                                    j
                                                                                                }}"
                                                                                                class="collapse"
                                                                                                aria-labelledby="benefit-heading-{{
                                                                                                    i
                                                                                                }}-{{ j }}"
                                                                                                [attr.data-bs-parent]="
                                                                                                    '#benefitAccordion-' + i
                                                                                                "
                                                                                            >
                                                                                                <div class="benefit-card">
                                                                                                    <p class="schedule-text-bold">
                                                                                                        <strong
                                                                                                            >Benefit Name:</strong
                                                                                                        >
                                                                                                        {{ benefit.name }}
                                                                                                    </p>
                                                                                                    <ng-container
                                                                                                        *ngIf="
                                                                                                            benefit.magisId ===
                                                                                                                'F000000001';
                                                                                                            else notCovered
                                                                                                        "
                                                                                                    >
                                                                                                        <p
                                                                                                            class="schedule-text-bold"
                                                                                                        >
                                                                                                            <strong
                                                                                                                >Coverage:</strong
                                                                                                            >
                                                                                                            {{
                                                                                                                benefit.value | zarCurrency
                                                                                                                   
                                                                                                            }}
                                                                                                        </p>
                                                                                                    </ng-container>
                                                                                                    <ng-template #notCovered>
                                                                                                        <p
                                                                                                            class="schedule-text-bold"
                                                                                                            *ngIf="
                                                                                                                benefit.covered ==
                                                                                                                false
                                                                                                            "
                                                                                                        >
                                                                                                            <mat-icon
                                                                                                                style="color: red"
                                                                                                                >close</mat-icon
                                                                                                            >
                                                                                                        </p>
                                                                                                        <p
                                                                                                            class="schedule-text-bold"
                                                                                                            *ngIf="
                                                                                                                benefit.covered ===
                                                                                                                true
                                                                                                            "
                                                                                                        >
                                                                                                            <strong
                                                                                                                >Coverage:</strong
                                                                                                            >
                                                                                                            <mat-icon
                                                                                                                style="
                                                                                                                    margin-top: -7px;
                                                                                                                "
                                                                                                                class="custom-icon"
                                                                                                                >done</mat-icon
                                                                                                            >
                                                                                                        </p>
                                                                                                    </ng-template>
                                                                                                    <p
                                                                                                        *ngIf="benefit?.reason"
                                                                                                        class="schedule-text-bold"
                                                                                                    >
                                                                                                        Reason:
                                                                                                        {{ benefit?.reason }}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div style="color: #8d55ca; font-weight: 800">
                                                                                        <strong>Premium:</strong>
                                                                                        {{
                                                                                            member.premium | zarCurrency
                                                                                                 
                                                                                        }}
                                                                                        <span
                                                                                            *ngIf="
                                                                                                member.reason &&
                                                                                                member.reason !== 'null' &&
                                                                                                member.reason !== ''
                                                                                            "
                                                                                        >
                                                                                            | <strong> Reason: </strong
                                                                                            >{{ member.reason }}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <!-- <button class="btn btn-fill btn-round float-right" (click)="onDoneClick()">Done</button> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-collapse">
                                                    <div class="card-header" role="tab" id="headingFour">
                                                        <h5 class="mb-0">
                                                            <a
                                                                class="collapsed"
                                                                data-toggle="collapse"
                                                                (click)="main()"
                                                                href="#collapseFour"
                                                                aria-expanded="false"
                                                                aria-controls="collapseFour"
                                                            >
                                                                Member benefit details
                                                                <i class="material-icons">keyboard_arrow_down</i>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div
                                                        id="collapseFour"
                                                        class="collapse"
                                                        role="tabpanel"
                                                        aria-labelledby="headingFour"
                                                        data-parent="#accordion"
                                                    >
                                                        <div *ngIf="!mobileView" class="card-body">
                                                            <table class="table">
                                                                <h6>Main Member</h6>
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="schedule-text-bold fifteen-percent">
                                                                            First name
                                                                        </td>
                                                                        <td class="thirtyfive-percent">
                                                                            {{ mainMember?.firstName }}
                                                                        </td>
                                                                        <td class="schedule-text-bold fifteen-percent">
                                                                            Surname
                                                                        </td>
                                                                        <td class="thirtyfive-percent">
                                                                            {{ mainMember?.lastName }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="schedule-text-bold">
                                                                            Identity/passport number
                                                                        </td>
                                                                        <td>
                                                                            {{ mainMember?.idNumber }}
                                                                        </td>
                                                                        <td class="schedule-text-bold">Date of birth</td>
                                                                        <td>
                                                                            {{
                                                                                mainMember?.dateOfBirth
                                                                                    | date
                                                                                        : 'dd MMM
                                                                            yyyy'
                                                                            }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>

                                                                <br />

                                                                <h6 *ngIf="children && children.length > 0">Children</h6>
                                                                <tbody *ngFor="let aMember of children">
                                                                    <tr>
                                                                        <td class="schedule-text-bold">First name</td>
                                                                        <td>
                                                                            {{ aMember?.firstName }}
                                                                        </td>
                                                                        <td class="schedule-text-bold">Surname</td>
                                                                        <td>{{ aMember?.lastName }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="schedule-text-bold">
                                                                            Identity/passport number
                                                                        </td>
                                                                        <td>
                                                                            {{ aMember?.idNumber }}
                                                                        </td>
                                                                        <td class="schedule-text-bold">Date of birth</td>
                                                                        <td>
                                                                            {{
                                                                                aMember?.dateOfBirth
                                                                                    | date
                                                                                        : 'dd MMM
                                                                            yyyy'
                                                                            }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>

                                                                <br />

                                                                <h6 *ngIf="spouses && spouses.length > 0">Spouse</h6>
                                                                <tbody *ngFor="let aMember of spouses">
                                                                    <tr>
                                                                        <td class="schedule-text-bold">First name</td>
                                                                        <td>
                                                                            {{ aMember?.firstName }}
                                                                        </td>
                                                                        <td class="schedule-text-bold">Surname</td>
                                                                        <td>{{ aMember?.lastName }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="schedule-text-bold">
                                                                            Identity/passport number
                                                                        </td>
                                                                        <td>
                                                                            {{ aMember?.idNumber }}
                                                                        </td>
                                                                        <td class="schedule-text-bold">Date of birth</td>
                                                                        <td>
                                                                            {{
                                                                                aMember?.dateOfBirth
                                                                                    | date
                                                                                        : 'dd MMM
                                                                            yyyy'
                                                                            }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>

                                                                <br />

                                                                <h6 *ngIf="parents && parents.length > 0">Parents</h6>
                                                                <tbody *ngFor="let aMember of parents">
                                                                    <tr>
                                                                        <td class="schedule-text-bold">First name</td>
                                                                        <td>
                                                                            {{ aMember?.firstName }}
                                                                        </td>
                                                                        <td class="schedule-text-bold">Surname</td>
                                                                        <td>{{ aMember?.lastName }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="schedule-text-bold">
                                                                            Identity/passport number
                                                                        </td>
                                                                        <td>
                                                                            {{ aMember?.idNumber }}
                                                                        </td>
                                                                        <td class="schedule-text-bold">Date of birth</td>
                                                                        <td>
                                                                            {{
                                                                                aMember?.dateOfBirth
                                                                                    | date
                                                                                        : 'dd MMM
                                                                            yyyy'
                                                                            }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>

                                                                <br />

                                                                <h6 *ngIf="others && others.length > 0">Others</h6>
                                                                <tbody *ngFor="let aMember of others">
                                                                    <tr>
                                                                        <td class="schedule-text-bold">First name</td>
                                                                        <td>
                                                                            {{ aMember?.firstName }}
                                                                        </td>
                                                                        <td class="schedule-text-bold">Surname</td>
                                                                        <td>{{ aMember?.lastName }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="schedule-text-bold">
                                                                            Identity/passport number
                                                                        </td>
                                                                        <td>
                                                                            {{ aMember?.idNumber }}
                                                                        </td>
                                                                        <td class="schedule-text-bold">Date of birth</td>
                                                                        <td>
                                                                            {{
                                                                                aMember?.dateOfBirth
                                                                                    | date
                                                                                        : 'dd MMM
                                                                            yyyy'
                                                                            }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                            <br />
                                                        </div>

                                                        <!-- app.component.html -->

                                                        <div *ngIf="mobileView" class="card-body">
                                                            <div class="accordion" id="membersAccordion">
                                                                <!-- Main Member -->
                                                                <div class="accordion-item">
                                                                    <div class="accordion-header" id="main-member-heading">
                                                                        <div class="accordion-title">
                                                                            <div
                                                                                class="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#main-member-collapse"
                                                                                aria-expanded="true"
                                                                                aria-controls="main-member-collapse"
                                                                            >
                                                                                <div class="mobile-card-name-text">
                                                                                    <p class="name">Main Member</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        id="main-member-collapse"
                                                                        class="collapse show"
                                                                        aria-labelledby="main-member-heading"
                                                                        data-bs-parent="#membersAccordion"
                                                                    >
                                                                        <div class="card-body">
                                                                            <h6>Main Member</h6>
                                                                            <div *ngIf="mainMember">
                                                                                <p class="fifteen-percent">
                                                                                    <strong> First name:</strong>
                                                                                    {{ mainMember?.firstName }}
                                                                                </p>
                                                                                <p class="thirtyfive-percent">
                                                                                    <strong> Surname:</strong>
                                                                                    {{ mainMember?.lastName }}
                                                                                </p>
                                                                                <p>
                                                                                    <strong> Identity/passport number:</strong>
                                                                                    {{ mainMember?.idNumber }}
                                                                                </p>
                                                                                <p>
                                                                                    <strong> Date of birth:</strong>
                                                                                    {{
                                                                                        mainMember?.dateOfBirth
                                                                                            | date: 'dd MMM yyyy'
                                                                                    }}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- Children -->
                                                                <div
                                                                    class="accordion-item"
                                                                    *ngIf="children && children.length > 0"
                                                                >
                                                                    <div class="accordion-header" id="children-heading">
                                                                        <div class="accordion-title">
                                                                            <button
                                                                                class="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#children-collapse"
                                                                                aria-expanded="true"
                                                                                aria-controls="children-collapse"
                                                                            >
                                                                                <div class="mobile-card-name-text">
                                                                                    <p class="name">Children</p>
                                                                                </div>
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        id="children-collapse"
                                                                        class="collapse"
                                                                        aria-labelledby="children-heading"
                                                                        data-bs-parent="#membersAccordion"
                                                                    >
                                                                        <div class="card-body">
                                                                            <h6>Children</h6>
                                                                            <div *ngFor="let aMember of children">
                                                                                <p class="schedule-text-bold">
                                                                                    First name: {{ aMember?.firstName }}
                                                                                </p>
                                                                                <p class="schedule-text-bold">
                                                                                    Surname: {{ aMember?.lastName }}
                                                                                </p>
                                                                                <p class="schedule-text-bold">
                                                                                    Identity/passport number:
                                                                                    {{ aMember?.idNumber }}
                                                                                </p>
                                                                                <p class="schedule-text-bold">
                                                                                    Date of birth:
                                                                                    {{
                                                                                        aMember?.dateOfBirth | date: 'dd MMM yyyy'
                                                                                    }}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- Spouses -->
                                                                <div class="accordion-item" *ngIf="spouses && spouses.length > 0">
                                                                    <div class="accordion-header" id="spouses-heading">
                                                                        <div class="accordion-title">
                                                                            <button
                                                                                class="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#spouses-collapse"
                                                                                aria-expanded="true"
                                                                                aria-controls="spouses-collapse"
                                                                            >
                                                                                <div class="mobile-card-name-text">
                                                                                    <p class="name">Spouses</p>
                                                                                </div>
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        id="spouses-collapse"
                                                                        class="collapse"
                                                                        aria-labelledby="spouses-heading"
                                                                        data-bs-parent="#membersAccordion"
                                                                    >
                                                                        <div class="card-body">
                                                                            <h6>Spouses</h6>
                                                                            <div *ngFor="let aMember of spouses">
                                                                                <p>First name: {{ aMember?.firstName }}</p>
                                                                                <p>Surname: {{ aMember?.lastName }}</p>
                                                                                <p>
                                                                                    Identity/passport number:
                                                                                    {{ aMember?.idNumber }}
                                                                                </p>
                                                                                <p>
                                                                                    Date of birth:
                                                                                    {{
                                                                                        aMember?.dateOfBirth | date: 'dd MMM yyyy'
                                                                                    }}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- Parents -->
                                                                <div class="accordion-item" *ngIf="parents && parents.length > 0">
                                                                    <div class="accordion-header" id="parents-heading">
                                                                        <div class="accordion-title">
                                                                            <div
                                                                                class="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#parents-collapse"
                                                                                aria-expanded="true"
                                                                                aria-controls="parents-collapse"
                                                                            >
                                                                                <div class="mobile-card-name-text">
                                                                                    <p class="name">Parents</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        id="parents-collapse"
                                                                        class="collapse"
                                                                        aria-labelledby="parents-heading"
                                                                        data-bs-parent="#membersAccordion"
                                                                    >
                                                                        <div class="card-body">
                                                                            <h6>Parents</h6>
                                                                            <div *ngFor="let aMember of parents">
                                                                                <p>First name: {{ aMember?.firstName }}</p>
                                                                                <p>Surname: {{ aMember?.lastName }}</p>
                                                                                <p>
                                                                                    Identity/passport number:
                                                                                    {{ aMember?.idNumber }}
                                                                                </p>
                                                                                <p>
                                                                                    Date of birth:
                                                                                    {{
                                                                                        aMember?.dateOfBirth | date: 'dd MMM yyyy'
                                                                                    }}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- Others -->
                                                                <div class="accordion-item" *ngIf="others && others.length > 0">
                                                                    <div class="accordion-header" id="others-heading">
                                                                        <div class="accordion-title">
                                                                            <div
                                                                                class="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#others-collapse"
                                                                                aria-expanded="true"
                                                                                aria-controls="others-collapse"
                                                                            >
                                                                                <div class="mobile-card-name-text">
                                                                                    <p class="name">Others</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        id="others-collapse"
                                                                        class="collapse"
                                                                        aria-labelledby="others-heading"
                                                                        data-bs-parent="#membersAccordion"
                                                                    >
                                                                        <div class="card-body">
                                                                            <h6>Others</h6>
                                                                            <div *ngFor="let aMember of others">
                                                                                <p>First name: {{ aMember?.firstName }}</p>
                                                                                <p>Surname: {{ aMember?.lastName }}</p>
                                                                                <p>
                                                                                    Identity/passport number:
                                                                                    {{ aMember?.idNumber }}
                                                                                </p>
                                                                                <p>
                                                                                    Date of birth:
                                                                                    {{
                                                                                        aMember?.dateOfBirth | date: 'dd MMM yyyy'
                                                                                    }}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-collapse">
                                                    <div class="card-header" role="tab" id="headingFive">
                                                        <h5 class="mb-0">
                                                            <a
                                                                class="collapsed"
                                                                data-toggle="collapse"
                                                                href="#collapseFive"
                                                                aria-expanded="false"
                                                                aria-controls="collapseFive"
                                                            >
                                                                Premiums and payment details
                                                                <i class="material-icons">keyboard_arrow_down</i>
                                                            </a>
                                                        </h5>
                                                    </div>

                                                    <div
                                                        id="collapseFive"
                                                        class="collapse"
                                                        role="tabpanel"
                                                        aria-labelledby="headingFive"
                                                        data-parent="#accordion"
                                                    >
                                                        <div *ngIf="!mobileView" class="card-body">
                                                            <table class="table" *ngIf="selectedQuoteOption">
                                                                <tbody>
                                                                    <!-- <thead> -->
                                                                    <tr>
                                                                        <td class="schedule-text-bold">Total premium</td>
                                                                        <!--text-right schedule-text-->

                                                                        <td>
                                                                            {{
                                                                                selectedQuoteOption?.finalQuote?.premium | zarCurrency
                                                                                   
                                                                            }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Debit Order Day</td>
                                                                        <td>{{ paymentDetailSelected?.value?.debitOrderDay }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Account Holder Name</td>
                                                                        <td>
                                                                            {{
                                                                                paymentDetailSelected?.value?.bankDetail
                                                                                    ?.accountHolderName
                                                                            }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Bank Name</td>
                                                                        <td>
                                                                            {{
                                                                                paymentDetailSelected?.value?.bankDetail?.bankName
                                                                            }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Branch Name</td>
                                                                        <td>
                                                                            {{
                                                                                paymentDetailSelected?.value?.bankDetail
                                                                                    ?.branchName
                                                                            }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Branch Code</td>
                                                                        <td>
                                                                            {{
                                                                                paymentDetailSelected?.value?.bankDetail
                                                                                    ?.branchCode
                                                                            }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Account Number</td>
                                                                        <td>
                                                                            {{
                                                                                paymentDetailSelected?.value?.bankDetail
                                                                                    ?.accountNumber
                                                                            }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Status</td>
                                                                        <td>
                                                                            {{ paymentDetailSelected?.value?.bankDetail?.status }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <!-- app.component.html -->

                                                        <div *ngIf="mobileView" class="card-body">
                                                            <div class="accordion" id="paymentDetailsAccordion">
                                                                <!-- Total Premium -->
                                                                <div class="accordion-item">
                                                                    <div class="accordion-header" id="total-premium-heading">
                                                                        <div class="accordion-title">
                                                                            <button
                                                                                class="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#total-premium-collapse"
                                                                                aria-expanded="true"
                                                                                aria-controls="total-premium-collapse"
                                                                            >
                                                                                <div class="mobile-card-name-text">
                                                                                    <p class="name">Total Premium</p>
                                                                                </div>
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        id="total-premium-collapse"
                                                                        class="collapse show"
                                                                        aria-labelledby="total-premium-heading"
                                                                        data-bs-parent="#paymentDetailsAccordion"
                                                                    >
                                                                        <div class="card-body">
                                                                            <p class="schedule-text-bold">Total premium:</p>
                                                                            <p>
                                                                                {{
                                                                                    selectedQuoteOption?.finalQuote?.premium | zarCurrency
                                                                                }}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- Debit Order Details -->
                                                                <div class="accordion-item">
                                                                    <div
                                                                        class="accordion-header"
                                                                        id="debit-order-details-heading"
                                                                    >
                                                                        <div class="accordion-title">
                                                                            <div
                                                                                class="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#debit-order-details-collapse"
                                                                                aria-expanded="true"
                                                                                aria-controls="debit-order-details-collapse"
                                                                            >
                                                                                <div class="mobile-card-name-text">
                                                                                    <p class="name">
                                                                                        {{
                                                                                            paymentDetailSelected?.value
                                                                                                ?.bankDetail?.bankName
                                                                                        }}
                                                                                    </p>
                                                                                    <p class="schedule-text-bold">
                                                                                        <strong> Account Number:</strong>
                                                                                        {{
                                                                                            paymentDetailSelected?.value
                                                                                                ?.bankDetail?.accountNumber
                                                                                        }}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        id="debit-order-details-collapse"
                                                                        class="collapse"
                                                                        aria-labelledby="debit-order-details-heading"
                                                                        data-bs-parent="#paymentDetailsAccordion"
                                                                    >
                                                                        <div class="card-body">
                                                                            <p>
                                                                                <strong> Debit Order Day:</strong>
                                                                                {{ paymentDetailSelected?.value?.debitOrderDay }}
                                                                            </p>
                                                                            <p>
                                                                                <strong> Account Holder Name:</strong>
                                                                                {{
                                                                                    paymentDetailSelected?.value?.bankDetail
                                                                                        ?.accountHolderName
                                                                                }}
                                                                            </p>
                                                                            <p>
                                                                                <strong> Bank Name:</strong>
                                                                                {{
                                                                                    paymentDetailSelected?.value?.bankDetail
                                                                                        ?.bankName
                                                                                }}
                                                                            </p>
                                                                            <p>
                                                                                <strong> Branch Name:</strong>
                                                                                {{
                                                                                    paymentDetailSelected?.value?.bankDetail
                                                                                        ?.branchName
                                                                                }}
                                                                            </p>
                                                                            <p>
                                                                                <strong> Branch Code:</strong>
                                                                                {{
                                                                                    paymentDetailSelected?.value?.bankDetail
                                                                                        ?.branchCode
                                                                                }}
                                                                            </p>
                                                                            <p>
                                                                                <strong> Account Number:</strong>
                                                                                {{
                                                                                    paymentDetailSelected?.value?.bankDetail
                                                                                        ?.accountNumber
                                                                                }}
                                                                            </p>
                                                                            <p>
                                                                                <strong> Status:</strong>
                                                                                {{
                                                                                    paymentDetailSelected?.value?.bankDetail
                                                                                        ?.status
                                                                                }}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="card-collapse">
                                                    <div class="card-header" role="tab" id="heading1000">
                                                        <h5 class="mb-0">
                                                            <a
                                                                class="collapsed"
                                                                data-toggle="collapse"
                                                                href="#collapse100"
                                                                aria-expanded="false"
                                                                aria-controls="collapse100"
                                                            >
                                                                Beneficiaries
                                                                <i class="material-icons">keyboard_arrow_down</i>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div
                                                        id="collapse100"
                                                        class="collapse"
                                                        role="tabpanel"
                                                        aria-labelledby="heading1000"
                                                        data-parent="#accordion"
                                                    >
                                                        <div *ngIf="!mobileView" class="card-body">
                                                            <table class="table">
                                                                <ng-container *ngFor="let beneficiary of beneficiarylSelected">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="schedule-text-bold">First name</td>
                                                                            <td>{{ beneficiary?.firstName }}</td>
                                                                            <td class="schedule-text-bold">Surname</td>
                                                                            <td>{{ beneficiary?.lastName }}</td>
                                                                            <td class="schedule-text-bold">
                                                                                ID Number/Passport Number
                                                                            </td>
                                                                            <td>
                                                                                {{
                                                                                    beneficiary?.passportNumber
                                                                                        ? beneficiary?.passportNumber
                                                                                        : beneficiary?.nationalIdNumber
                                                                                }}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="schedule-text-bold">Date of birth</td>
                                                                            <td>
                                                                                {{
                                                                                    beneficiary?.dateOfBirth | date: 'dd MMM yyyy'
                                                                                }}
                                                                            </td>
                                                                            <td class="schedule-text-bold">Gender</td>
                                                                            <td>{{ beneficiary?.gender }}</td>
                                                                            <td class="schedule-text-bold">
                                                                                Email/Contact number
                                                                            </td>
                                                                            <td>
                                                                                {{
                                                                                    beneficiary?.email || beneficiary?.phoneNumber
                                                                                }}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </ng-container>
                                                            </table>

                                                            <br />
                                                        </div>

                                                        <!-- app.component.html -->

                                                        <div class="card-body" *ngIf="mobileView">
                                                            <ng-container
                                                                *ngFor="let beneficiary of beneficiarylSelected; let i = index"
                                                            >
                                                                <div class="accordion" id="beneficiaryDetailsAccordion{{ i }}">
                                                                    <!-- Beneficiary Details -->
                                                                    <div class="accordion-item">
                                                                        <h2
                                                                            class="accordion-header"
                                                                            id="beneficiary-details-heading{{ i }}"
                                                                        >
                                                                            <div
                                                                                class="accordion-button collapsed"
                                                                                type="button"
                                                                                [attr.aria-expanded]="isAccordionOpen[i]"
                                                                                (click)="toggleAccordion(i)"
                                                                            >
                                                                                <div class="mobile-card-name-text">
                                                                                    <p class="name">
                                                                                        {{ beneficiary?.firstName }}
                                                                                        {{ beneficiary?.lastName }}
                                                                                    </p>
                                                                                    <p class="schedule-text-bold">
                                                                                        <strong>Date of birth:</strong>
                                                                                        {{
                                                                                            beneficiary?.dateOfBirth
                                                                                                | date: 'dd MMM yyyy'
                                                                                        }}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </h2>

                                                                        <div
                                                                            id="beneficiary-details-collapse{{ i }}"
                                                                            class="accordion-collapse collapse"
                                                                            [ngClass]="{ show: isAccordionOpen[i] }"
                                                                            aria-labelledby="beneficiary-details-heading{{ i }}"
                                                                            data-bs-parent="#beneficiaryDetailsAccordion{{ i }}"
                                                                        >
                                                                            <div class="accordion-body">
                                                                                <p>
                                                                                    <strong> First name:</strong>
                                                                                    {{ beneficiary?.firstName }}
                                                                                </p>
                                                                                <p>Surname: {{ beneficiary?.lastName }}</p>
                                                                                <p>
                                                                                    <strong> ID Number/Passport Number:</strong>
                                                                                    {{
                                                                                        beneficiary?.passportNumber
                                                                                            ? beneficiary?.passportNumber
                                                                                            : beneficiary?.nationalIdNumber
                                                                                    }}
                                                                                </p>
                                                                                <p>
                                                                                    <strong> Date of birth:</strong>
                                                                                    {{
                                                                                        beneficiary?.dateOfBirth
                                                                                            | date: 'dd MMM yyyy'
                                                                                    }}
                                                                                </p>
                                                                                <p>
                                                                                    <strong> Gender:</strong>
                                                                                    {{ beneficiary?.gender }}
                                                                                </p>
                                                                                <p>
                                                                                    <strong> Email/Contact number:</strong>
                                                                                    {{
                                                                                        beneficiary?.email ||
                                                                                            beneficiary?.phoneNumber
                                                                                    }}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="card-collapse">
                                                    <div class="card-header" role="tab" id="heading100">
                                                        <h5 class="mb-0">
                                                            <a
                                                                class="collapsed"
                                                                data-toggle="collapse"
                                                                href="#collapse100"
                                                                aria-expanded="false"
                                                                aria-controls="collapse100"
                                                            >
                                                                Conditions
                                                                <i class="material-icons">keyboard_arrow_down</i>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div
                                                        id="collapse100"
                                                        class="collapse"
                                                        role="tabpanel"
                                                        aria-labelledby="heading100"
                                                        data-parent="#accordion"
                                                    >
                                                        <div *ngIf="!mobileView" class="card-body">
                                                            <table class="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th class="schedule-text-bold">Term and condtions</th>
                                                                        <th class="schedule-text-bold">Waiting period</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    <tr>
                                                                        <td class="schedule-text-bold">
                                                                            {{
                                                                                selectedQuoteOption?.finalQuote
                                                                                    ?.termsAndConditions
                                                                            }}
                                                                        </td>
                                                                        <td>
                                                                            <!-- {{ selectedQuoteOption?.finalQuote?.waitingPeriod }} -->
                                                                            {{ selectedQuoteOption?.finalQuote?.waitingPeriod }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <!-- app.component.html -->

                                                        <div class="card-body" *ngIf="mobileView">
                                                            <div class="accordion" id="termsAndConditionsAccordion">
                                                                <!-- Terms and Conditions Section -->
                                                                <div class="accordion-item">
                                                                    <div
                                                                        class="accordion-header"
                                                                        id="terms-and-conditions-heading"
                                                                    >
                                                                        <div class="accordion-title">
                                                                            <div
                                                                                class="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#terms-and-conditions-collapse"
                                                                                aria-expanded="true"
                                                                                aria-controls="terms-and-conditions-collapse"
                                                                            >
                                                                                <div class="mobile-card-name-text">
                                                                                    <p class="name">Terms and Conditions</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        id="terms-and-conditions-collapse"
                                                                        class="collapse"
                                                                        aria-labelledby="terms-and-conditions-heading"
                                                                        data-bs-parent="#termsAndConditionsAccordion"
                                                                    >
                                                                        <div class="card-body">
                                                                            <p class="schedule-text-bold">
                                                                                {{
                                                                                    selectedQuoteOption?.finalQuote
                                                                                        ?.termsAndConditions
                                                                                }}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- Waiting Period Section -->
                                                                <div class="accordion-item">
                                                                    <div class="accordion-header" id="waiting-period-heading">
                                                                        <div class="accordion-title">
                                                                            <div
                                                                                class="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#waiting-period-collapse"
                                                                                aria-expanded="true"
                                                                                aria-controls="waiting-period-collapse"
                                                                            >
                                                                                <div class="mobile-card-name-text">
                                                                                    <p class="name">Waiting Period</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        id="waiting-period-collapse"
                                                                        class="collapse"
                                                                        aria-labelledby="waiting-period-heading"
                                                                        data-bs-parent="#termsAndConditionsAccordion"
                                                                    >
                                                                        <div class="card-body">
                                                                            <!-- {{ selectedQuoteOption?.finalQuote?.waitingPeriod }} -->
                                                                            {{ selectedQuoteOption?.finalQuote.waitingPeriod }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
