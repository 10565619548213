import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-vehicle-questions',
  templateUrl: './vehicle-questions.component.html',
  styleUrls: ['./vehicle-questions.component.scss']
})
export class VehicleQuestionsComponent implements OnInit {
  public constructor(public dialogRef: MatDialogRef<VehicleQuestionsComponent>) {}

  public canContinue : boolean = false;
  public budgetQ : any = null;
  public quoteQ : any = null;


  public ngOnInit(): void {
    this.canContinue = false;
    this.budgetQ = null;
    this.quoteQ = null;
  }


  public questionAnswered(event : any, source : any)
  {
      if (source.includes('Budget'))
        this.budgetQ = event.value === 'yes';

      if (source.includes('Quote'))
        this.quoteQ = event.value === 'yes';

      this.canContinue = this.budgetQ !== null && this.quoteQ !== null;
  }
}
