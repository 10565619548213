import { UserActivityTypeEnum } from "../enums/user-activity-type.enum";
import { IUserActivity, IUserDashboard } from "../models/dashboard";

export class UserActivityMapper {
    
    public static Map(input: any): IUserActivity {
        let response: IUserActivity;

        response =
        {
            activityId: 0,
            channel: input.channel ? input.channel : 'WEB',
            date: new Date(input.created),
            description: input.activityDescription,
            name: input.endPoint,
            type: input.activityType,
            typeDisplay : this.GetTypeDisplay(input.activityType),
            icon: this.GetIcon(input.activityType),
        };

        return response;
    }

    
    private static GetTypeDisplay(activityType: any): any {
        let response = activityType.replaceAll('_',' ');

        return response;
    }

   
    public static GetIcon(taskType: any): any {
        let response = 'activity_zone';

        switch (taskType) {

            case UserActivityTypeEnum.SIGN_IN:
                response = 'login';
                break;
            case UserActivityTypeEnum.SIGN_UP:
                response = 'how_to_reg';
                break;
            case UserActivityTypeEnum.PROFILE_DETAILS_UPDATE:
                response = 'manage_accounts';
                break;
            case UserActivityTypeEnum.FUNERAL_QUOTE_REQUEST:
                response = 'monitor_heart';
                break;
            case UserActivityTypeEnum.FUNERAL_POLICY_CREATED:
                response = 'monitor_heart';
                break;

            case UserActivityTypeEnum.BENEFICIARY_DETAIL_UPDATE:
                response = 'group_add';
                break;
            case UserActivityTypeEnum.FICA:
                response = 'inventory';
                break;
            case UserActivityTypeEnum.KYC:
                response = 'contact_page';
                break;
            case UserActivityTypeEnum.FUNERAL_CLAIM:
                response = 'volunteer_activism';
                break;
            case UserActivityTypeEnum.FUNERAL_QUOTE:
                response = 'monitor_heart';
                break;
            case UserActivityTypeEnum.FUNERAL_QUOTE_UPDATE:
                response = 'monitor_heart';
                break;
            case UserActivityTypeEnum.MOTOR_CLAIM:
                response = 'directions_car';
                break;
            case UserActivityTypeEnum.MOTOR_QUOTE:
                response = 'directions_car';
                break;
            case UserActivityTypeEnum.MOTOR_QUOTE_UPDATE:
                response = 'directions_car';
                break;
            case UserActivityTypeEnum.VEHICLE_ASSESSMENT:
                response = 'calendar_month';
                break;

            default:
                response = 'activity_zone';

        }


        return response;
    }

    public static GetUserActivities(input: any): IUserActivity[] | PromiseLike<IUserActivity[]> {
        let response = new Array<IUserActivity>();

        input.insights?.forEach((element: any) => {
            response.push(this.Map(element));
        });

        return response;
    }
}