export enum HttpStatusCode {
    UNREACHABLE = 0,
    ACCEPTED = 202,
    BADGATEWAY = 502,
    BADREQUEST = 400,
    CONFLICT = 409,
    CONTINUE = 100,
    CREATED = 201,
    EXPECTATIONFAILED = 417,
    FAILEDDEPENDENCY = 424,
    FORBIDDEN = 403,
    GATEWAYTIMEOUT = 504,
    GONE = 410,
    HTTPVERSIONNOTSUPPORTED = 505,
    IMATEAPOT = 418,
    INSUFFICIENTSPACEONRESOURCE = 419,
    INSUFFICIENTSTORAGE = 507,
    INTERNALSERVERERROR = 500,
    LENGTHREQUIRED = 411,
    LOCKED = 423,
    METHODFAILURE = 420,
    METHODNOTALLOWED = 405,
    MOVEDPERMANENTLY = 301,
    MOVEDTEMPORARILY = 302,
    MULTISTATUS = 207,
    MULTIPLECHOICES = 300,
    NETWORKAUTHENTICATIONREQUIRED = 511,
    NOCONTENT = 204,
    NONAUTHORITATIVEINFORMATION = 203,
    NOTACCEPTABLE = 406,
    NOTFOUND = 404,
    NOTIMPLEMENTED = 501,
    NOTMODIFIED = 304,
    OK = 200,
    PARTIALCONTENT = 206,
    PAYMENTREQUIRED = 402,
    PERMANENTREDIRECT = 308,
    PRECONDITIONFAILED = 412,
    PRECONDITIONREQUIRED = 428,
    PROCESSING = 102,
    PROXYAUTHENTICATIONREQUIRED = 407,
    REQUESTHEADERFIELDSTOOLARGE = 431,
    REQUESTTIMEOUT = 408,
    REQUESTTOOLONG = 413,
    REQUESTURITOOLONG = 414,
    REQUESTEDRANGENOTSATISFIABLE = 416,
    RESETCONTENT = 205,
    SEEOTHER = 303,
    SERVICEUNAVAILABLE = 503,
    SWITCHINGPROTOCOLS = 101,
    TEMPORARYREDIRECT = 307,
    TOOMANYREQUESTS = 429,
    UNAUTHORIZED = 401,
    UNPROCESSABLEENTITY = 422,
    UNSUPPORTEDMEDIATYPE = 415,
    USEPROXY = 305,
}
