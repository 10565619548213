import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { VehicleActionType } from 'src/app/core/models/vehicle.action.type';
import { IVehicles } from 'src/app/core/models/vehicles-retrieval';
import { DriverService } from 'src/app/core/services/driver.service';
import { getCapturePopupWidth } from 'src/app/core/_helpers/responsive-helper';
import { AddVehicleStepthreeComponent } from '../add-vehicle-stepthree/add-vehicle-stepthree.component';
import { AddVehicleComponent } from '../add-vehicle/add-vehicle.component';

@Component({
    selector: 'app-add-vehicle-steptwo',
    templateUrl: './add-vehicle-steptwo.component.html',
    styleUrls: ['./add-vehicle-steptwo.component.scss'],
})
export class AddVehicleSteptwoComponent implements OnInit {
    public form: FormGroup;
    public isCancelClicked = false;
    public formIsValid: boolean;
    public vehicles: Array<IVehicles> = [];
    public loading: boolean = false;
    @Output() vehicleFormSetpTwo = new EventEmitter();

    public constructor(
        public _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private readonly _driverService: DriverService,
        private store: Store<AppState>,
        public dialogRef: MatDialogRef<AddVehicleSteptwoComponent>
    ) {}

    public async ngOnInit(): Promise<void> {
        this.formInitialization();
        
            if (this.data.data !== undefined) {
                this.form.patchValue({
                    id:this.data.data?.id,
                    vehicleColour: this.data.data.vehicleColour,
                    vehicleDescription: this.data.data.vehicleDescription,
                    vehicleKey: this.data.data.vehicleKey,
                    vehicleMake: this.data.data.vehicleMake,
                    vehicleMakeDescription: this.data.data.vehicleMakeDescription,
                    vehicleModelDescription: this.data.data.vehicleModelDescription,
                    paintType: this.data.data.paintType,
                    vehicleModel: this.data.data.vehicleModel,
                    vehicleRegNo: this.data.data.vehicleRegNo,
                    vehicleYear: this.data.data.vehicleYear,
                    ncb: this.data.data.ncb,
                    newOne: false,
                    trackingDeviceInstalled: this.data.data.trackingDeviceInstalled,
                    automaticImpactDetection: this.data.data.automaticImpactDetection,
                    movementMonitored: this.data.data.movementMonitored,
                    engineModified: this.data.data.engineModified,
                    vehicleName: this.data.data.vehicleName,
        
                    vehicleFinanced: true,
                    coverTypeRequested: this.data.data.coverTypeRequested,
                    financedAmount: this.data.data.financedAmount,
                    kmTravelled: this.data.data.kmTravelled,
                    identities: this.data.data.identities,
        
                    parking: this.data.data.parking,
                    approvedSecSystemInstalled: this.data.data.approvedSecSystemInstalled,
                    vehicleType: this.data.data.vehicleType,
                    address: this.data.data.address,
        
                    address1: this.data.data.address1,
        
                    nightAddress: this.data.data.nightAddress,
                    nightParking: this.data.data.nightParking,
                    dayAddress: this.data.data.dayAddress,
                    dayAreaType: this.data.data.dayAreaType,
        
                    dayParking: this.data.data.dayParking,
                    nightAreaType: this.data.data.nightAreaType,
                });
                this.isThisFormValid();
        
                const form = {
                    isvalid: this.formIsValid,
                    form: this.form.value
                }
                this.vehicleFormSetpTwo.emit(form);
            }
     

        this.form.valueChanges.subscribe((selectedValue: any) => {
            
            this.isThisFormValid();
            const form = {
                isvalid: this.formIsValid,
                form: this.form.value
            }
            this.vehicleFormSetpTwo.emit(form);
        });
    }

    // identities = [
    //     { value: 'SAIDENTITYNUMBER', viewValue: 'South african identity number' },
    //     { value: 'PASSPORT', viewValue: 'Passport number' },
    // ];

    public close() {
        this.isCancelClicked = true;
        this.dialogRef.close();
    }

    public openNextModal(): void {
        this.isThisFormValid();
        if (!this.isCancelClicked && (this.form.valid || this.formIsValid)) {
            this._dialog.closeAll();
            const dialogRef = this._dialog.open(AddVehicleStepthreeComponent, {
                width: getCapturePopupWidth(),
                data: this.form.value,
                disableClose: true,
            });
            dialogRef.afterClosed().subscribe(async result => {
                this.vehicles = await this.getVehicles();
                this.vehicles[this.vehicles.length - 1].selected = true;
                this.store.dispatch({
                    type: VehicleActionType.ADD_VEHICLE,
                    payload: this.vehicles,
                });
            });
        } else {
            this.loading = false;
        }
    }

    public back(): void {
        this._dialog.closeAll();
        const dialogRef = this._dialog.open(AddVehicleComponent, {
            width: getCapturePopupWidth(),
            data: this.form.value,
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(async result => {

        });
    }

    isFieldValid(form: FormGroup, field: string) {
        let control = form.get(field);
        return control !== null && control !== undefined ? !form.get(field).valid && form.get(field).touched : false;
    }

    public async getVehicles(): Promise<Array<IVehicles>> {
        const response = await this._driverService.getVehicles();

        if (response === undefined || response === null) return [];

        return response;
    }

    isFieldNotValid(form: FormGroup, field: string) {
        try {
            if (form !== null || form.get(field) !== null) {
                var isFieldNotValid;
                if (form.get(field).touched) {
                    isFieldNotValid =
                        !form.get(field).valid || form.get(field).value === undefined || form.get(field).value === null;
                } else {
                    isFieldNotValid = false;
                }
                return isFieldNotValid;
            }
        } catch (error) {
        }

        return false;
    }

    isThisFormValid(): boolean {
        let isValid = true;
        const fields = ['trackingDeviceInstalled', 'automaticImpactDetection', 'movementMonitored', 'engineModified'];
        this.formIsValid = true;
        fields?.forEach(x => {
            const fieldValid = !this.isFieldNotValid(this.form, x);
            const value = this.form.get(x).value;
            isValid = isValid && fieldValid;
            this.formIsValid = this.formIsValid && value !== null && value !== undefined && value !== '';
        });

        return isValid;
    }

    public formInitialization() {
        this.form = this.formBuilder.group({
            id:null,
            trackingDeviceInstalled: [null, Validators.required],
            automaticImpactDetection: [null, Validators.required],
            movementMonitored: [null, Validators.required],
            engineModified: [null, Validators.required],
            paintType: [null],
            vehicleColour: [null],
            vehicleDescription: [null],
            vehicleName: null,
            vehicleMake: [null],
            vehicleModel: [null],
            vehicleKey: [null],
            newOne: [false],
            vehicleRegNo: [null],
            vehicleYear: [null],
            vehicleMakeDescription: [null],
            vehicleModelDescription: [null],
            ncb: [null],
            vehicleFinanced: [true],
            coverTypeRequested: [null],
            financedAmount: [null],
            kmTravelled: [null],
            identities: [null],

            parking: [null],
            approvedSecSystemInstalled: [null],
            vehicleType: [null],
            address: [null],

            address1: [null],

            nightAddress: null,
            nightParking: null,
            dayAddress: null,
            dayAreaType: null,
            dayParking: null,
            nightAreaType: null,
        });
    }
}
