import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpStatusCode } from 'src/app/core/models/http-status-code.model';
import { AccountService } from 'src/app/core/services/account.service';
import { MaskedEmail } from 'src/app/core/_helpers/masked-email-helper';
import { Notifications } from '../notifications.component';

@Component({
    selector: 'app-verify-email-dialog',
    templateUrl: './verify-email-dialog.component.html',
    styleUrls: ['./verify-email-dialog.component.scss'],
})
export class VerifyEmailDialogComponent implements OnInit {
    otpForm: FormGroup;
    loading: boolean = false;
    otpDigits: number[] = Array(4).fill(null); // Array to create 4 separate digits
    @ViewChildren('otpInput') otpInputs: QueryList<ElementRef>;
    otpInputElements: ElementRef[] = [];
    constructor(
        private readonly _accountService: AccountService,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly _notification: Notifications,
        public dialogRef: MatDialogRef<VerifyEmailDialogComponent>
    ) {}

    maskedEmail = new MaskedEmail(this.data.email).maskedEmail;

    ngOnInit(): void {
        this.otpForm = this._formBuilder.group({
            digit0: [''],
            digit1: [''],
            digit2: [''],
            digit3: [''],
        });
        // this.otpForm = this._formBuilder.group({
        //     otp: [null, Validators.required],
        // });
    }

    isFieldValid(form: FormGroup, field: string) {
        return !form.get(field).valid && form.get(field).touched;
    }

    focusNextInput(index: number) {
        if (index < 3) {
            const nextInput = this.otpInputElements[index + 1];
            if (nextInput) {
                nextInput.nativeElement.focus();
            }
        }
    }

    public onInput(index: number,event: { key: string; preventDefault: () => void }) {
        const input = this.otpForm.get('digit' + index);
        const inputValue = input.value;
        if (inputValue.length > 1) {
            input.setValue(inputValue.charAt(0));
        }
  
    }

    public ngAfterViewInit() {
        this.otpInputElements = this.otpInputs.toArray();
    }
    check(index: number, field: string, event: { key: string; preventDefault: () => void }): void {
        if (event.key === 'Backspace' || event.key === 'Delete') {
            event.preventDefault();
            if (event.key === 'Backspace') {
                this.focusPreviousInput(index);
            } else {
                // Handle Delete key logic if needed
            }
        } else if (event.key === 'Tab') {
            event.preventDefault();
            this.focusNextInput(index);
        } else if (isNaN(parseInt(event.key, 10))) {
            event.preventDefault();
        }else if (isNaN(parseInt(event.key, 10))) {
            event.preventDefault();
        }
    }

    focusPreviousInput(index: number): void {
        let previousIndex = index - 1;

        if (previousIndex === -1) {
            previousIndex = 0;
        }

        if (previousIndex >= 0) {
            const previousInput = this.getInputField(previousIndex);

            if (previousInput) {
                let inputControl = null;

                if (index === 3) {
                    inputControl = this.otpForm.get('digit3');
                } else if (index === 2) {
                    inputControl = this.otpForm.get('digit2');
                } else if (index === 1) {
                    inputControl = this.otpForm.get('digit1');
                } else if (index === 0) {
                    inputControl = this.otpForm.get('digit0');
                }

                // Focus on the previous input
                previousInput.nativeElement.focus();

                if (inputControl) {
                    // Clear the content of the previous input
                    inputControl.setValue('');
                }
            }
        }
    }

    // Return the input field at the specified index
    private getInputField(index: number): ElementRef | null {
        return this.otpInputs.toArray()[index] || null;
    }

    public async resend(): Promise<void> {
        try {
            const response = await this._accountService.emailVerificationRequest(this.data);
            if (response.respCode === HttpStatusCode.INTERNALSERVERERROR) {
                this._notification.showNotification(response.respMessage, 'danger');
                this.loading = false;
                return;
            } else if (response.respCode === HttpStatusCode.ACCEPTED || response.respCode === HttpStatusCode.OK) {
                this._notification.showNotification(`A new OTP has been sent to ${this.maskedEmail}`, 'success');
                this.otpForm.patchValue({
                    digit0: [''],
                    digit1: [''],
                    digit2: [''],
                    digit3: [''],
                })
                this.loading = false;
            } else {
                this._notification.showNotification(response.respMessage, 'danger');
                this.loading = false;
            }
        } catch (error) {
            this.loading = false;
        }
    }

    // get maskedEmail(): string {
    //     const maskedChars = 4;
    //     const maskedPart = '****'.slice(0, maskedChars);
    //     const unmaskedPart = this.data.email.substring(3);

    //     return maskedPart + unmaskedPart;
    //   }CONFLICT

    public async verify(): Promise<void> {
        try {
            if (this.otpForm.valid) {
                this.loading = true;
                const otpNumber = `${this.otpForm.value.digit0}${this.otpForm.value.digit1}${this.otpForm.value.digit2}${this.otpForm.value.digit3}`;
                const response = await this._accountService.emailVerificationConfirm({ otp: otpNumber });
                if (response.respCode === HttpStatusCode.INTERNALSERVERERROR) {
                    this._notification.showNotification(response.respMessage, 'danger');
                    this.loading = false;
                    this.dialogRef.close(false);
                    return;
                } else if (response.respCode === HttpStatusCode.ACCEPTED || response.respCode === HttpStatusCode.OK) {
                    this._notification.showNotification('Email is verified', 'success');
                    this.dialogRef.close(true);
                    this.loading = false;
                } else if (response.respCode === HttpStatusCode.CONFLICT) {
                    this._notification.showNotification(response.respMessage, 'danger');
                    this.loading = false;
                } else {
                    this._notification.showNotification(response.respMessage, 'danger');
                    this.loading = false;

                    this.dialogRef.close(false);
                }
            } else {
                this.loading = false;
            }
        } catch (error) {
            this.loading = false;
        }
    }
}
