
<div *ngIf="otpForm" class="container">
    <button mat-icon-button aria-label="close dialog"
    class="close-icon"
    mat-dialog-close>
    <mat-icon>close</mat-icon>
</button>
    <h4 class="text-center text-primary font-weight-bold">Verify Email Address</h4>
    <div>
        <p class="p-1">Please enter the OTP sent to the email: {{ maskedEmail }}</p>
    </div>
    <form [formGroup]="otpForm" (submit)="verify()">
        <div class="row">
            <!-- Separate input fields for each digit with focus change handling -->
            <div class="col">
                <input
                    *ngFor="let digit of otpDigits; let i = index"
                    matInput
                    (keydown)="check(i, field, $event)"
                    maxlength="1"
                    [formControlName]="'digit' + i"
                    (input)="focusNextInput(i, $event)"
                    #otpInput
                />
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <button mat-raised-button type="submit" class="btn btn-primary btn-round">
                Verify OTP&nbsp;
                <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
            </button>
        </div>
    </form>
    <p class="text-sm text-center mt-1">
        <a class="text-secondary text-gradient font-weight-bold" (click)="resend()">Resend OTP?</a>
    </p>
</div>
