import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IRelatedParties } from '../models/related-parties';
import { IRelatedParty } from '../models/related-party';
import { IResponseStatus } from '../models/response-status';

@Injectable({
    providedIn: 'root',
})
export class RelatedPartyService {
    public constructor(private readonly _http: HttpClient) {}

    public async addUserRelatedParty(model: IRelatedParty): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(`${environment.serverApiUrl}policy/v1/assets/add-user-related-party`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async updateUserRelatedPartyRequest(model: IRelatedParty): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(`${environment.serverApiUrl}policy/v1/assets/update-related-party-request`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }



    public async updateUserRelatedParty(model: IRelatedParty): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(`${environment.serverApiUrl}policy/v1/assets/update-user-related-party`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getUserRelatedParties(): Promise<IRelatedParties> {
        try {
            const response = await this._http
                .post<IRelatedParties>(`${environment.serverApiUrl}policy/v1/assets/get-user-related-parties`, null)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }
}
