<div class="card col-sm-12">
    <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
            <i class="material-icons">account_balance</i>
        </div>
        <h4 class="card-title">Bank Details</h4>
    </div>
    <div class="card-body" style="margin-bottom: -10px">
        <div class="table-responsive" *ngIf="!mobileView">
            <table class="table">
                <thead>
                    <tr>
                        <th class="text-center">Select</th>
                        <th>Account Holder</th>
                        <th>Account Number</th>
                        <th>Bank Name</th>
                        <th class="text-right">Branch Name</th>
                        <th class="text-right">Branch Code</th>
                        <th class="text-right">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of userbanks">
                        <td class="text-center">
                            <mat-radio-button [checked]="isSelected(item)" (change)="onBankInfoChange(item)" [value]="item.id">
                            </mat-radio-button>
                        </td>
                        <td>{{ item.accountHolderName }}</td>
                        <td>{{ item.accountNumber }}</td>
                        <td>{{ item.bankName }}</td>
                        <td class="text-right">{{ item.branchName }}</td>
                        <td class="text-right">{{ item.branchCode }}</td>
                        <td class="td-actions text-right">
                            <i class="material-icons person-icon">remove_red_eye</i>
                            <i class="material-icons edit-icon" (click)="openOptModalVehicle(item)">edit</i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="btn-container">
                <button (click)="openOptAddBankModal()" class="btn-add">Add bank</button>
            </div>
        </div>

        <div *ngIf="mobileView">
            <div *ngFor="let item of userbanks; let i = index" class="accordion">
                <div class="accordion-item">
                    <div class="accordion-header" id="payment-detail-heading-{{ i }}">
                        <div class="accordion-title">
                            <div
                                class="accordion-button collapsed"
                                data-toggle="collapse"
                                [attr.data-target]="'#payment-detail-collapse-' + i"
                                aria-expanded="true"
                                aria-controls="payment-detail-collapse-{i}}"
                            >
                                <div class="mobile-container">
                                    <mat-radio-button
                                        style="margin-right: 20px"
                                        [checked]="isSelected(item)"
                                        (change)="onBankInfoChange(item)"
                                        [value]="item.id"
                                    ></mat-radio-button>
                                    <div class="mobile-card-name-text mobile-card">
                                        <p class="name">
                                            {{ item.bankName }}
                                        </p>
                                        <p class="mobile-card-text-line-2">
                                            <strong>Account Number:</strong> {{ item.accountNumber }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        id="payment-detail-collapse-{{ i }}"
                        class="collapse"
                        aria-labelledby="payment-detail-heading-{{ i }}"
                        data-parent=".accordion"
                    >
                        <div class="card-body row">
                            <div class="col-md-11">
                                <p><strong>Account Holder Name:</strong> {{ item.accountHolderName }}</p>

                                <p><strong>Branch Name:</strong> {{ item.branchName }}</p>
                                <p><strong>Branch Code:</strong> {{ item.branchCode }}</p>
                            </div>
                            <div class="text-right col-md-1">
                                <button mat-icon-button mat-button type="button" (click)="openOptModalVehicle(item)">
                                    <mat-icon class="green-button" aria-label="Edit">edit</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="btn-container">
                <button mat-raised-button class="btn btn-primary btn-round btn-add" (click)="openOptAddBankModal()">
                    Add bank
                </button>
            </div>
        </div>
    </div>
</div>

<div class="card col-sm-12" *ngIf="isAccountSecled">
    <div class="card-header card-header-rose card-header-icon" style="margin-top: 10px">
        <div class="card-icon">
            <i class="material-icons">payments</i>
        </div>
        <h4 class="card-title">Selected Account</h4>
    </div>

    <form [formGroup]="form" style="margin-bottom: 5px">
        <div class="card-body">
            <div class="row" style="margin-left: 25px">
                <div class="col-md-4">
                    <mat-form-field class="example-full-width">
                        <input
                            tabindex="1"
                            [value]="banksInfo?.accountHolderName"
                            matInput
                            formControlName="accountHolderName"
                            placeholder="Account Holder's Name"
                            type="text"
                        />
                        <app-field-error-display
                            [displayError]="isFieldValid(form, 'accountHolderName')"
                            errorMsg="Account name is required."
                        >
                        </app-field-error-display>
                    </mat-form-field>

                    <br />
                    <mat-form-field class="example-full-width">
                        <input
                            tabindex="4"
                            [value]="banksInfo?.bankName"
                            matInput
                            formControlName="branchName"
                            placeholder="Branch Name"
                            type="text"
                        />
                        <app-field-error-display
                            [displayError]="isFieldValid(form, 'branchName')"
                            errorMsg="Branch name is required."
                        >
                        </app-field-error-display>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="example-full-width">
                        <input
                            tabindex="2"
                            [value]="banksInfo?.accountNumber"
                            matInput
                            formControlName="accountNumber"
                            placeholder="Account Number"
                            type="number"
                            min="0"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        />
                        <app-field-error-display
                            [displayError]="isFieldValid(form, 'accountNumber')"
                            errorMsg="Account number is required."
                        >
                        </app-field-error-display>
                    </mat-form-field>

                    <br />
                    <mat-form-field class="example-full-width">
                        <input
                            readonly
                            tabindex="5"
                            [value]="banksInfo?.branchCode"
                            matInput
                            disabled="true"
                            formControlName="branchCode"
                            placeholder="Branch Code"
                            type="text"
                        />
                        <app-field-error-display
                            [displayError]="isFieldValid(form, 'branchCode')"
                            errorMsg="Branch code is required."
                        >
                        </app-field-error-display>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field>
                        <mat-select
                            (selectionChange)="onchangeBankName($event)"
                            tabindex="3"
                            [value]="banksInfo?.bankName"
                            class="example-full-width"
                            formControlName="bankName"
                            placeholder="Bank name"
                            readonly
                        >
                            <mat-option *ngFor="let bank of banks" [value]="bank.name">
                                {{ bank.name }}
                            </mat-option>
                        </mat-select>
                        <app-field-error-display
                            [displayError]="isFieldValid(form, 'bankName')"
                            errorMsg="Bank name is required."
                        >
                        </app-field-error-display>
                    </mat-form-field>
                    <br />

                    <mat-form-field>
                        <mat-select
                            (selectionChange)="onDebitOrderDayChange($event)"
                            tabindex="6"
                            formControlName="debitOrderDay"
                            class="example-full-width"
                            placeholder="Debit order day"
                            required="true"
                        >
                            <mat-option *ngFor="let day of days" [value]="day">
                                {{ day }}
                            </mat-option>
                        </mat-select>
                        <app-field-error-display
                            [displayError]="isFieldValid(form, 'debitOrderDay')"
                            errorMsg="Debit order day is required!"
                        >
                        </app-field-error-display>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <!-- <div class="card-footer btn-group" style="margin-right: 40px;">
            <button mat-raised-button class="btn add-new" type="submit" [disabled]="!canSave"
                (click)="saveBankDetails()">Save
                &nbsp;
                <mat-icon *ngIf="loading">
                    <mat-spinner diameter="20">
                    </mat-spinner>
                </mat-icon>
            </button>
        </div> -->
    </form>
</div>
