
import { FuneralQuoteActionType } from '../core/models/funeral-quote.action.type';

export function addFuneralQuoteteReducer(state: any, action: any) {
    switch (action.type) {
        case FuneralQuoteActionType.ADD_FUNERAL_QUOTE:
            return action.payload;
        default:
            return state;
    }
}
