<div class="main-content main-content-margin-0" *ngIf="emailForm">
    <h4 class="solution-text">Email Verification</h4>

    <form [formGroup]="emailForm">
        <div class="row" style="align-self: center">
            <div class="col-md-6">
                <br />
                <span> Almost done, let's verify your email address </span>
                <br />
                <span style="color: #8d55ca; font-weight: 700" *ngIf="isVerified"> Email is verified </span>
            </div>

            <div class="col-md-6 group">
                <mat-form-field class="example-full-width">
                    <input readonly formControlName="email" matInput  placeholder="Email address" type="text" />
                    <app-field-error-display
                        [displayError]="isFieldValid(emailForm, 'email')"
                        errorMsg="Email Address  is required"
                    >
                    </app-field-error-display>
                </mat-form-field>

                <button
                    mat-raised-button
                    type="submit"
                    [disabled]="!emailForm.valid || isVerified"
                    [ngClass]="{ 'disabled-button': !emailForm.valid || isVerified }"
                    (click)="openOTPScreen()"
                    class="btn-email btn-round"
                >
                    Send OTP &nbsp;
                    <mat-icon *ngIf="loading">
                        <mat-spinner diameter="20"> </mat-spinner>
                    </mat-icon>
                </button>

                <!-- <input type="button" class="btn btn-fill btn-rose btn-wd" (click)="openOTPScreen()" value="Send OTP" /> -->
            </div>
        </div>
    </form>
</div>
