import { Action } from '@ngrx/store';
import { DriveActionType } from '../core/models/driver.action.type';

export function addDriverReducer(state: any, action: any) {
    switch (action.type) {
        case DriveActionType.ADD_DRIVER:
            return action.payload;
        default:
            return state;
    }
}
