import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ILoveDataRetrieval } from '../models/lov-data.retrieval';
import { IReferenceData } from '../models/reference-data';

@Injectable({
    providedIn: 'root',
})
export class LovDataService {
    public constructor(private readonly _http: HttpClient) { }

    // public async GetLovData(): Promise<Array<ILoveDataRetrieval>> {
    //     try {
    //         const model = {
    //             referenceType: 'relations',
    //         };
    //         const reponse = await this._http
    //             .post<Array<ILoveDataRetrieval>>(`${environment.serverApiUrl}policy/v1/assets/get-reference-data`, model)
    //             .toPromise();
    //         return reponse;
    //     } catch (error) {
    //         if (error && error instanceof ProgressEvent) {
    //             throw new Error('A connection could not be established. Please contact an administrator.');
    //         }
    //         throw Error();
    //     }
    // }

    public async getReferenceData(referenceType: any): Promise<Array<IReferenceData>> {
        try {

            var response;
            let item = localStorage.getItem(referenceType);
            if (!item) {

                const model = {
                    referenceType: referenceType,
                };

                response = await this._http
                    .post<Array<IReferenceData>>(`${environment.serverApiUrl}policy/v1/assets/get-reference-data`, model)
                    .toPromise();

                localStorage.setItem(referenceType, JSON.stringify(response));
            }
            else {
                response = JSON.parse(item);
            }

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }
}
